import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {CustomizedXAxisTick, CustomizedYAxisTick, CustomizedYAxisTickRight, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";


function App({data}) {
    const values1 = data['rows']

    const 구역수_공급세대수 = values1.map(item => [item[2], item[4]]);
    const 도시 = 구역수_공급세대수[2]
    const 재정비 = 구역수_공급세대수[7]
    const 소규모 = 구역수_공급세대수[11]

    const 합계들 = [['도시', ...도시], ['재정비', ...재정비], ['소규모', ...소규모]]

    const graph_data = 합계들.map(item => ({
        name: item[0],
        구역수: item[1],
        공급세대: parseInt(item[2] / 1000)
    }));

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>
        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={5 / 3}>
                <ComposedChart
                    data={graph_data}
                    margin={{
                        bottom: 40,
                        left: -20,
                        right: -20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey="name" angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={0}/>
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="#FD4857"/>
                    <YAxis yAxisId="right" orientation="right" tick={<CustomizedYAxisTickRight screenWidth={screenWidth}/>} stroke="#004D92"/>
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey="구역수" yAxisId='left' barSize={20} fill="#FD4857"/>
                    <Line yAxisId='right' type="linear" strokeWidth={2} dataKey="공급세대" stroke="#004D92"/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
