import React, {useEffect, useState} from "react";
import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {
    calculateXAxisInterval,
    colors,
    CustomizedXAxisTick,
    CustomizedYAxisTick,
    CustomLegend,
    formatTooltipValue
} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";
import {filterDataByCondition2} from "../../../utils/TableUtils";

function App({data, searchCondition}) {
    const [filteredData, setFilteredData] = useState([]);

    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        setFilteredData([])
        if (searchCondition.상세검색상세목록 && searchCondition.상세검색상세목록.length > 0) {

            let choosenHeaders = ['매매_아파트_KB_시도권역별_강북', '매매_아파트_KB_시도권역별_서울시']; // 샘플
            choosenHeaders = searchCondition.상세검색상세목록
            setHeaders(choosenHeaders)

            const headerIndexes = []
            choosenHeaders.forEach((choosenheader) => {
                data.headers.forEach((header, index) => {
                    if (header.title === choosenheader) {
                        headerIndexes.push(index)
                    }
                })
            })

            console.log('선택된 헤더 번호', headerIndexes); // Output: [3, 2]

            const fixedrows = data.rows.map((row, rowIndex) => {
                const year = row[0];
                const month = row[1];
                const headers = {};

                headerIndexes.forEach((headerIndex, colIndex) => {
                    const headerTitle = choosenHeaders[colIndex];

                    if(row[headerIndex] !== 0) {
                        headers[headerTitle] = row[headerIndex];
                    }
                });

                return {year, month, ...headers};
            });

            if (searchCondition.시작월) {
                const filteredWithMonthRange = filterDataByCondition2(fixedrows, searchCondition)

                const withapt = filteredWithMonthRange.map(
                    line => {
                        const apts = []

                        searchCondition.상세검색상세목록.forEach(apt_name => {
                            const 아파트 = searchCondition.아파트캐시.find(v => v.label === apt_name)
                            if (아파트) {
                                const 중위값목록 = 아파트.value
                                const 일치 = 중위값목록.find(중위값 => parseInt(중위값.year) === parseInt(line.year) && parseInt(중위값.month) === parseInt(line.month))
                                if (일치) {
                                    apts.push({[apt_name]: 일치['med']})
                                }
                            }
                        })

                        let returnobj = {...line}
                        apts.forEach((apt) => {
                            returnobj = {...returnobj, ...apt}
                        });

                        return returnobj
                    }
                )

                setFilteredData(withapt)
            }

        }

    }, [data, searchCondition]);

    const [minMaxState, setMinMaxState] = useState({minValue: Number.MAX_VALUE, maxValue: Number.MIN_VALUE});

    useEffect(() => {

        let maxValue = Number.MIN_VALUE;
        let minValue = Number.MAX_VALUE;

        filteredData.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key !== 'year' && key !== 'month') {
                    if (parseInt(item[key]) > maxValue) maxValue = parseInt(item[key])
                    if (parseInt(item[key]) < minValue) minValue = parseInt(item[key])
                }
            });
        });

        setMinMaxState({minValue, maxValue});

    }, [filteredData, searchCondition])

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        {
            filteredData.length > 0 ? (
                <div className='chart-container mx-auto'>
                    <ResponsiveContainer width='100%' aspect={4 / 4}>
                        <ComposedChart
                            data={filteredData}
                            margin={{
                                bottom: 40, left: -10, right: 10
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5"/>
                            <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45}
                                   tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end"
                                   interval={calculateXAxisInterval(filteredData)}/>
                            <YAxis yAxisId="left" orientation="left"
                                   tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="gray"
                                   domain={[parseInt((minMaxState.minValue - 100) / 10) * 10, parseInt((minMaxState.maxValue + 100) / 10) * 10]}
                                   label={{
                                       value: '(만원/㎡)',
                                       angle: 0,
                                       position: 'insideTopLeft',
                                       dy: -30,
                                       dx: 20,
                                       fontSize: 12
                                   }}/>
                            <Tooltip formatter={formatTooltipValue}/>
                            <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                            {
                                headers.map((title, index) => {
                                    const color = colors[index % colors.length];
                                    return (
                                        <Line yAxisId='left' type="monotone" strokeWidth={2} dataKey={title} stroke={color} dot={true} unit=' (만원/㎡)'/>
                                    );
                                })
                            }

                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div className='border border-1 p-5 font-bold'>
                    상세 조건에 데이터를 추가 하면 그래프가 표시됩니다.
                </div>
            )
        }
    </>);
}

export default App;
