import React, {  useEffect, useState } from 'react';
import { Alert, Popover , notification, Image, Col, Row , Layout, Menu, Select, Table,Radio, Space, DatePicker, ConfigProvider    } from 'antd';
import { cloneDeep} from "lodash";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ko_KR.js';
import 'dayjs/locale/ko' 
import { Bar, Column } from '@ant-design/plots';
import axios from "axios";
import {seoulGu, bubjungDong} from '../utils/guDong.js'
const { RangePicker } = DatePicker;

// components
// views
dayjs.locale('ko');
export default function ForeCast_Dong(props) {
    const [list, setList] = useState({
        '아파트': [],
        '연립다세대' : [],
        '단독다가구' : [],
      });
      const [tableList, setTableList] = useState({
        '아파트': [],
        '연립다세대' : [],
        '단독다가구' : [],
      });
      const [size, setSize] = useState('A');
      const [getbubjeoung, setGetBubjeoung] = useState([]);
      const [filteredInfo, setFilteredInfo] = useState({
        gu: null,
        dong: null,
        selectType: 'A',
        houseType: '전세'
      });
      const [showNoti, contextHolder] = notification.useNotification();
      const [pickerAlert, setPickerAlert] = useState(false);
      const [columns, setColumns] = useState([]);
      useEffect(() => {
        requireData(filteredInfo);
      },[filteredInfo])
    
      const content = (
        <div>
          <p>건축연한이 파악되지 않는 임차 예측물량</p>
        </div>
      );
      const requireData = (data) => {
        if (!data.gu || !data.dong || !data.houseType || !data.selectType || !data.startPredictionTime || !data.endPredictionTime) {
          return;
        }
        const headers = {
          'Access-Control-Allow-Origin': '*',
        }
        const url = `https://api.seoulhousinginfo.com/forecast/serach-data?gu=${data.gu}&dong=${data.dong}&houseType=${data.houseType}&selectType=${data.selectType}&startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}`
        axios.get(url,{},{headers})
        .then(res => {
          getMonthlyTable(res.data.Items);
        })
        .catch(error => {
            console.log(error);
        })
        return 
      }
      //데이터를 테이블에 맞게 변환
      const getMonthlyTable = (res) => {
        //아파트
        const obj = {};
        const objTable = {};
        obj['아파트'] = [];
        obj['연립다세대'] = [];
        obj['단독다가구'] = [];
        objTable['아파트'] = [];
        objTable['연립다세대'] = [];
        objTable['단독다가구'] = [];
        res.sort((a, b) => a['code'] - b['code']);
    
        //기본 양식
        if (size === 'Y') {
          const k = [
            {
              'code': '전체',
              '소계': 0,
              key: '전체',
            },
            {
              'code': '10년미만',
              '소계': 0,
              key: '10년미만',
            },
            {
              'code': '10-20년',
              '소계': 0,
              key: '10-20년',
            },
            {
              'code': '20-30년',
              '소계': 0,
              key: '20-30년',
            },
            {
              'code': '30년이상',
              '소계': 0,
              key: '30년이상',
            },
            {
              'code': '확인불가',
              '소계': 0,
              key: '확인불가',
            },
        ]
          objTable['아파트'] = cloneDeep(k)
          objTable['연립다세대'] = cloneDeep(k)
          objTable['단독다가구'] = cloneDeep(k)
        } else {
          const k = [
            {
              'code': '전체',
              '소계': 0,
              key: '전체',
            },
            {
              'code': '40㎡미만',
              '소계': 0,
              key: '40㎡미만',
            },
            {
              'code': '40-85㎡',
              '소계': 0,
              key: '40-85㎡',
            },
            {
              'code': '85㎡이상',
              '소계': 0,
              key: '85㎡이상',
            },
        ]
          objTable['아파트'] = cloneDeep(k)
          objTable['연립다세대'] = cloneDeep(k)
          objTable['단독다가구'] = cloneDeep(k)
        }
    
        //데이터 값이 안들어올때를 대비해서 모든값을 "-"으로 치환
        const monthLength = dayjs(filteredInfo.endPredictionTime).diff(filteredInfo.startPredictionTime, 'month')
        for (let k = 0; k < objTable['아파트'].length; k++){
          for(let g = 0; g < monthLength + 1; g++) {
            const month = dayjs(filteredInfo.startPredictionTime).add(g, 'month').format('MM월');
            objTable['아파트'][k][month] = '-'
            objTable['연립다세대'][k][month] = '-'
            objTable['단독다가구'][k][month] = '-'
          }
        }
    
        for (let i = 0; i < res?.length; i++) {
          const chart = {
            'code': convertType(res[i]['code']),
            '거래수': Number(res[i]['거래수']) == 0 ? '-' : Number(res[i]['거래수']),
            '예측시기': dayjs(res[i]['예측시기']).format('MM월'),
            '주택유형': res[i]['주택유형']
          }
    
          if(res[i]['주택유형'] === '아파트'){
            //1. 표
            const calculate = dayjs(res[i]['예측시기']).format('MM월');
            let found = objTable['아파트'].findIndex(e => e.key === convertType(res[i]['code']));
            if (found === -1) {
              const t = {
                'code': convertType(res[i]['code']),
                '소계': '-',
                [calculate] : '-',
                key: convertType(res[i]['code']),
              }
              objTable['아파트'].push(t)
            } else {
              objTable['아파트'][found][calculate] = res[i]['거래수'] == 0 ? '-' : res[i]['거래수'];
              objTable['아파트'][found]['소계'] = Number(objTable['아파트'][found]['소계']) + Number(res[i]['거래수']);
            }
            // 전체 계산을 위한 코드
            objTable['아파트'][0][calculate] = Number(objTable['아파트'][0][calculate]) ? Number(objTable['아파트'][0][calculate]) + Number(res[i]['거래수']) : Number(res[i]['거래수']);
            objTable['아파트'][0]['소계'] = Number(objTable['아파트'][0]['소계']) + Number(res[i]['거래수']);
    
            //2. 차트
            obj['아파트'].push(chart)
          
          } else if (res[i]['주택유형'] === '연립다세대'){
            //1. 표
            const calculate = dayjs(res[i]['예측시기']).format('MM월');
            let found = objTable['연립다세대'].findIndex(e => e.key === convertType(res[i]['code']));
            if (found === -1) {
              const t = {
                'code': convertType(res[i]['code']),
                '소계': '-',
                [calculate] : '-',
                key: convertType(res[i]['code']),
              }
              objTable['연립다세대'].push(t)
            } else {
              objTable['연립다세대'][found][calculate] = res[i]['거래수'] == 0 ? '-' : res[i]['거래수'];
              objTable['연립다세대'][found]['소계'] = Number(objTable['연립다세대'][found]['소계']) + Number(res[i]['거래수']);
            }
            objTable['연립다세대'][0][calculate] = Number(objTable['연립다세대'][0][calculate]) ? Number(objTable['연립다세대'][0][calculate]) + Number(res[i]['거래수']) : Number(res[i]['거래수']);
            objTable['연립다세대'][0]['소계'] = Number(objTable['연립다세대'][0]['소계']) + Number(res[i]['거래수']);
    
            //2. 차트
            obj['연립다세대'].push(chart)
    
          } else if (res[i]['주택유형'] === '단독다가구'){
            //1. 표
            const calculate = dayjs(res[i]['예측시기']).format('MM월');
            let found = objTable['단독다가구'].findIndex(e => e.key === convertType(res[i]['code']));
            if (found === -1) {
              const t = {
                'code': convertType(res[i]['code']),
                '소계': '-',
                [calculate] : '-',
                key: convertType(res[i]['code']),
              }
              objTable['단독다가구'].push(t)
            } else {
              objTable['단독다가구'][found][calculate] = res[i]['거래수'] == 0 ? '-' : res[i]['거래수'];
              objTable['단독다가구'][found]['소계'] = Number(objTable['단독다가구'][found]['소계']) + Number(res[i]['거래수']);
            }
            objTable['단독다가구'][0][calculate] = Number(objTable['단독다가구'][0][calculate]) ? Number(objTable['단독다가구'][0][calculate]) + Number(res[i]['거래수']) : Number(res[i]['거래수']);
            objTable['단독다가구'][0]['소계'] = Number(objTable['단독다가구'][0]['소계']) + Number(res[i]['거래수']);
            
            //2. 차트
            obj['단독다가구'].push(chart)
          }
        }
    
        // 소계가 0 일때 변환 작업
        for(let j=0; j < objTable['아파트'].length; j++){
          if(objTable['아파트'][j]['소계'] == 0){
            objTable['아파트'][j]['소계'] = '-'
          }
        }
    
        for(let j=0; j < objTable['연립다세대'].length; j++){
          if(objTable['연립다세대'][j]['소계'] == 0){
            objTable['연립다세대'][j]['소계'] = '-'
          }
        }
    
        for(let j=0; j < objTable['단독다가구'].length; j++){
          if(objTable['단독다가구'][j]['소계'] == 0){
            objTable['단독다가구'][j]['소계'] = '-'
          }
        }
        setTableList(cloneDeep(objTable))
        setList(cloneDeep(obj))
      }
    
      // 면적 연한 변환
      const convertType = (data) => {
        let type = null;
        if (size === 'A') {
          if (data === '0040')
            type = '40㎡미만'
          if (data === '8500')
            type = '85㎡이상'
          if (data === '4085')
            type = '40-85㎡'
        } else {
          if (data === '0010')
            type = '10년미만'
          if (data === '1020')
            type = '10-20년'
          if (data === '2030')
            type = '20-30년'
          if (data === '3000')
            type = '30년이상'
          if (data === '9999')
          type = '확인불가'
        }
        return type;
      }
      //6개월 초과선택시 알림
      const openNotificationWithIcon = (type, des) => {
        showNoti[type]({
          message: type === 'success' ? '업로드에 성공하였습니다.' : '선택에 실패하였습니다.',
          description:
          type === 'success' ? des + '업로드 완료' : des,
          placement: 'top',
        });
      };
      const changeOption = (data, dataIndex) => {
        const res = cloneDeep(filteredInfo);
        if (data !== '0' && data !== '') {
          if (dataIndex === 'gu') {
              res.gu = data;
              res.dong = null;
              setGetBubjeoung(bubjungDong[data])
          } else if (dataIndex === 'dong') {
              res.dong = data;
              if (res.startPredictionTime === undefined ||res.startPredictionTime === null){
                res.startPredictionTime = dayjs().format('YYYYMM');
                res.endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
                getTableColumn(res.startPredictionTime,res.endPredictionTime)
              }
          } else if (dataIndex === 'houseType') {
              res.houseType = data;
          } else if (dataIndex === 'selectType') {
              setSize(data.target.value)
              res.selectType = data.target.value;
          } else if (dataIndex === 'time') {
              res.startPredictionTime = data[0];
              res.endPredictionTime = data[1];
          }
        }
        setFilteredInfo(cloneDeep(res))
      };
    
      const siderStyle = {
        textAlign: 'center',
        lineHeight: '120px',
        color: '#fff', 
        height: '100%',
        position: 'sticky',top: 0,
        backgroundColor: '#d3d3d3',
      };
      const config = (res) => {
        return {
          data: list[res],
          isStack: true,
          xField: '거래수',
          yField: '예측시기',
          seriesField: 'code',
          label: {
            position: 'middle',
            content: (item) => {
              if (item['거래수'] === 0) {  // '거래수' 필드가 0인지 확인
                  return '-';
              }
              return !isNaN(item['거래수']) ? item['거래수'].toLocaleString() : item['거래수'];
            },
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
        }
      };
    
      const onPickerAlertClose = () => {
        setPickerAlert(false)
      }
    
      // 각 월마다 column 생성 
      const getTableColumn = (start, end) => {
        const endMonth = dayjs(end).diff(dayjs(start), 'month')
        const column = [    
          {
            title: '구분',
            dataIndex: 'code',
            key: 'code',
            render: (text) => 
            {
                return text === '확인불가' ? 
                <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                 :
                  <Space style={{fontSize: 14}}>{text}</Space>
                
            },
          },
        ]
        for (let i = 0; i < endMonth + 1; i++) {
          const month = dayjs(start).add(i,'month').format('MM월');
          column.push(
            {
              title: month,
              dataIndex: month.toString(),
              key: month.toString(),
              onCell: (text, record) => {
                const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                return {
                  ['style']: {background: checkCase},
                  className: 'example-class-in-td',
                };
              },
              render: (text) => {
                return <Space style={{fontSize: 14}}>{text}</Space>
              },                },
          )
          if (i === endMonth){
            column.push(
              {
                title: '소계',
                dataIndex: '소계',
                key: '소계',
                onCell: (text, record) => {
                  // const checkCase = text['name'].length > 50 ? 'red' : 'green;
                  return {
                    ['style']: {background: '#FFEAE6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                  return <Space style={{fontSize: 14}}>{text}</Space>
                  },                  },
              )
          }
        }
        setColumns(column)
      }
    return (
        <>
            {contextHolder}
            <Row style={{alignItems:'center', flex: 1, paddingTop: 10, paddingBottom: 10, marginBottom: 10, paddingLeft: 10, position: 'sticky',top: 0, backgroundColor:'#ffccc7', zIndex:2}}>
                <Col>
                    자치구:  
                    <Select
                    style={{minWidth: 100, marginLeft: 5, marginRight: 5}}
                    onChange={(value) => changeOption(value, 'gu')}
                    options={seoulGu}
                    defaultValue={'선택'}
                    />
                </Col>
                <Col>
                    법정동: 
                    <Select
                    disabled={filteredInfo.gu === null ? true : false}
                    style={{minWidth: 100, marginLeft: 5, marginRight: 5}}
                    onChange={(value) => changeOption(value, 'dong')}
                    defaultValue={'선택'}
                    options={getbubjeoung.sort((a, b) => a.value.localeCompare(b.value))}
                    />
                </Col>
                <Col>
                    거래유형: 
                    <Select
                    defaultValue="전세"
                    style={{minWidth: 100, marginLeft: 5, marginRight: 5}}
                    onChange={(value) => changeOption(value, 'houseType')}
                    options={[
                        { value: '전세', label: '전세' },
                        { value: '월세', label: '월세' },
                    ]}
                    />
                </Col>
                <Col>
                    기간: 
                    <RangePicker
                    defaultValue={[dayjs(),]}
                    disabledDate={(current) => dayjs(current).isBefore(dayjs('20230701'))}
                    style={{ marginLeft: 5, marginRight: 5, textAlign:'center',justifyContent:'center', alignItems:'center'}} locale={locale} picker="month" onChange={(value) =>{ 
                    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                    dayjs.extend(isSameOrAfter)

                    const check = dayjs(value[1]).subtract(5,'month');
                    const check2 = dayjs(dayjs(value[0])).isSameOrAfter(check, 'month')
                    const dateList = [dayjs(value[0]).format('YYYYMM'),dayjs(value[1]).format('YYYYMM')]
                    if(check2 === true){
                        getTableColumn(dayjs(value[0]),dayjs(value[1]));
                        changeOption(dateList, 'time')
                        } else{
                        openNotificationWithIcon('error', '최대 6개월까지 선택 가능합니다.')
                        } 
                    }
                    }
                    />
                </Col>
                <Col>
                    <Radio.Group value={size} onChange={(value) => changeOption(value, 'selectType')}>
                    <Radio value="A">면적</Radio>
                    <Radio value="Y">건축연한</Radio>
                    </Radio.Group>
                </Col>

                </Row>
                {list['아파트'].length > 0 ? <Layout style={{justifyContent:'flex-start', backgroundColor:'#FFFFFF'}}>
                <Space style={{marginLeft: 10,fontSize: 20, fontWeight: 700, marginBottom: 10}}>
                    아파트
                </Space>
                <Row>
                    <Col>
                        <Table columns={columns} dataSource={[...tableList['아파트']]} pagination={false}   />
                    </Col>
                    <Col xs={24} lg={12}>
                        <Bar style={{marginLeft: 30, minWidth: 550}}{...config('아파트')} />
                    </Col>
                </Row>
            </Layout> : <Layout style={{flex: 1,minHeight: 200, backgroundColor: '#FFFFFF',  }} />}
            {list['연립다세대'].length > 0 ? <Layout style={{justifyContent:'flex-start', backgroundColor:'#FFFFFF'}}>
                <Space style={{marginLeft: 10,marginRight: 10,  fontSize: 20, fontWeight: 700, marginBottom: 10}}>
                연립·다세대
                </Space>
                <Row>
                    <Col >
                        <Table columns={columns} dataSource={[...tableList['연립다세대']]}  pagination={false}   />
                    </Col>
                    <Col xs={24} lg={12}>
                        <Bar style={{marginLeft: 10}}  {...config('연립다세대')}/>
                    </Col>
                </Row>
                </Layout> : <Layout style={{flex: 1,minHeight: 200, backgroundColor: '#FFFFFF',  }}/>}
                {list['단독다가구'].length > 0 ? <Layout style={{justifyContent:'flex-start', backgroundColor:'#FFFFFF'}}>
                    <Space style={{marginLeft: 10,marginRight: 10, fontSize: 20, fontWeight: 700, marginBottom: 10}}>
                    단독·다가구
                    </Space>
                <Row>
                    <Col >
                        <Table columns={columns} dataSource={[...tableList['단독다가구']]}  pagination={false}   />
                    </Col>
                    <Col xs={24} lg={12}  > <Bar style={{marginLeft: 10}}  {...config('단독다가구')}/></Col>
                </Row>

            </Layout> : 
            <Layout style={{flex: 1,minHeight: 200, backgroundColor: '#FFFFFF',  }} />
          }
        </>
    );
}
