import React, {useState} from "react";
import {Depth3Title} from "../Depth3View";
import S21X from "../work1/data1/S21X";
import T2152 from "../work1/data1/T2152";
import G2152 from "../work1/data1/G2152";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const [searchCondition, setSearchCondition] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';
    

    const allData = useSyncData(work, metaKey, dataKey);

    return (<>

        {allData && (<>
            <Depth3Title title='소비자 물가상승률'/>
            <G2152 data={JSON.parse(allData.data['2152'])} searchCondition={searchCondition}/>
            <S21X data={JSON.parse(allData.data['2152'])['rows']} onChangeSearchOption={setSearchCondition}/>
            <T2152 code='2152' data={JSON.parse(allData.data['2152'])} searchCondition={searchCondition}/>
        </>)}
    </>);
}