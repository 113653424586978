import React, {useEffect, useState} from "react";
import 'react-base-table/styles.css'
import axios from "axios";
import {useRecoilValue} from "recoil";
import {userState} from "../../utils/recoilstate";
import {CenterLoadSpinner} from "../../utils/UIHelper";

// eslint-disable-next-line react-hooks/rules-of-hooks

function unixTimestampToDateTime(unixTimestamp) {
    // Create a Date object from the Unix timestamp
    const date = new Date(unixTimestamp * 1000);

    // Extract the date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
}

const filesStatic = []
export default function App() {

    const [filter, setFilter] = useState('');
    const [filesOrg, setFilesOrg] = useState(filesStatic);
    const [files, setFiles] = useState(filesStatic);
    const userInStorage = useRecoilValue(userState);
    const [showSpinner, setShowSpinner] = useState(false);
    const [resetFile, setResetFile] = useState(Date.now());

    useEffect(() => {

        if (filesOrg) {

            const filtered = filesOrg.filter(file => {
                const lowerCaseFilter = filter.toLowerCase();

                return file.work.toLowerCase().includes(lowerCaseFilter) ||
                    file.status.toLowerCase().includes(lowerCaseFilter) ||
                    file.org_filename.toLowerCase().includes(lowerCaseFilter) ||
                    file.uploader.toLowerCase().includes(lowerCaseFilter) ||
                    file.memo.toLowerCase().includes(lowerCaseFilter)
                    ;
            });

            setFiles(filtered)
        }

    }, [filesOrg])

    const handleClearFilter = () => {
        setFilter('');
    }

    const workname = '주택시장동향'

    function getInitialState() {
        return {

            objectKey: `upload/${workname}.xlsx`,
            uploader: "",
            work: workname,
            memo: "",
            selectedFile: ""
        };
    }

    const [uploadContent, setUploadContent] = useState(getInitialState());

    function refreshFileList(default_delay = 1000) {

        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/${workname}`;

        setTimeout(() => {
            axios
                .get(apiUrl)
                .then((response) => {
                    setFilesOrg(response.data['Items']);
                })
                .catch((error) => {
                    console.log(error); // handle any errors
                })
        }, default_delay);
    }

    function deleteFileRequest(upload_ts) {

        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/${upload_ts}`;
        setShowSpinner(true);

        axios.delete(apiUrl)
            .then(response => {

                setFilesOrg(response.data['Items'])
            })
            .catch(error => {
                console.log(error); // handle any errors
            }).finally(() => {
                setShowSpinner(false);
                refreshFileList(1000);
            });
    }

    useEffect(() => {

        refreshFileList(0)

    }, [])

    useEffect(() => {

        if (userInStorage && userInStorage.payload) {
            const uploader = userInStorage.payload.username
            setUploadContent(prevState => ({
                ...prevState,
                uploader: uploader
            }))
        }

    }, [userInStorage])

    function handleFileInput(e) {
        const file = e.target.files[0];

        setUploadContent(prevState => ({
            ...prevState,
            selectedFile: file
        }))
    }

    function haandleInput(e) {
        const key = e.target.name
        const val = e.target.value

        // set name = value
        setUploadContent(prevState => ({
            ...prevState,
            [key]: val,
        }))
    }

    function uploadNow(url, fields) {

        const formData = new FormData();
        Object.entries(fields).forEach(([key, val]) => {
            formData.append(key, val);
        });

        formData.append("file", uploadContent.selectedFile);

        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
            , validateStatus: function (status) {
                return status === 199 || status === 204;
            }
        }
        setShowSpinner(true);
        axios
            .post(url, formData, axiosConfig)
            .then((res) => {
                console.log("File Upload success");

            })
            .catch(
                (err) => {
                    console.log('got error?')
                }).finally(() => {
            }).finally(() => {

                setUploadContent((p) => ({...p, selectedFile: "", memo: ""}));
                setResetFile(Date.now())
                setShowSpinner(false);
                refreshFileList(2000);
            });
    }

    function readyS3PresignedUrlAndUpload(params) {
        // s3 에 업로드 하기 위한 경로를 확보한다.

        setShowSpinner(true);
        axios.get('https://api.seoulhousinginfo.com/upload/presigned?' + params)
            .then(res => {

                uploadNow(res.data.url, res.data.fields)
                console.log(res.data.fields)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setShowSpinner(false);
            })
    }

    function submitForm() {

        if (!uploadContent.selectedFile && uploadContent.work && uploadContent.memo)
            return

        const meta_info = {
            'object-key': uploadContent.objectKey,
            'original-filename': uploadContent.selectedFile.name,
            'uploader-name': uploadContent.uploader,
            'work-name': uploadContent.work,
            'memo': uploadContent.memo,
            'timeout': 600
        }

        const params = new URLSearchParams(meta_info).toString();

        readyS3PresignedUrlAndUpload(params)
    }

    function deleteFile(upload_ts) {
        deleteFileRequest(upload_ts)
    }

    function file_ready(upload_ts) {

        console.log('파일을 대기 상태로 변환 시도', upload_ts)
        // Show rotating UI
        setShowSpinner(true);

        axios.post(`https://api.seoulhousinginfo.com/admin/workfile/${upload_ts}/ready`)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
            refreshFileList();
            setShowSpinner(false);
        });
    }

    function file_preview(upload_ts) {

        console.log('파일을 미리보기 상태로 변환 시도', upload_ts)

        setShowSpinner(true);
        axios.post(`https://api.seoulhousinginfo.com/admin/workfile/${upload_ts}/preview`)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                refreshFileList();
                setShowSpinner(false);
            });

    }

    function download_full(upload_ts) {
        // Make API request to get the download link
        // https://api.seoulhousinginfo.com/admin/workfile/download/link/total/추진업무현황/1687256829
        fetch(`https://api.seoulhousinginfo.com/admin/workfile/download/link/total/${workname}/${upload_ts}`)
            .then(response => response.json())
            .then(data => {
                const downloadLink = data.link;
                // Initiate file download
                window.open(downloadLink);
            })
            .catch(error => {
                console.error('Error retrieving download link:', error);
            });
    }

    function file_publish(upload_ts) {

        console.log('파일을 전시 상태로 변환 시도', upload_ts)
        setShowSpinner(true);
        axios.post(`https://api.seoulhousinginfo.com/admin/workfile/${upload_ts}/publish`)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                refreshFileList();
                setShowSpinner(false);
            });

    }

    return (<>

        <h1 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">파일 관리</h1>

        <div className="px-2 m:px-6">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">작은 화면에서는 우측으로 스크롤 하세요. 파일을 미리 보기 하고 전시하세요</p>
        </div>
        <div className="px-2 sm:px-6 lg:px-8">
            <div className="mt-2 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                        <div className='mb-3'>
                            <span>
                                <input className="p-2 px-2 py-1 border-b border-b-1 border-gray-900 bg-blue-50"
                                       type="text"
                                       value={filter} onChange={e => setFilter(e.target.value)} placeholder="검색"/>
                                {filter && (
                                    <button className="ml-2 text-red-600 font-bold" onClick={handleClearFilter}>
                                        X
                                    </button>
                                )}
                            </span>
                            <span className='bg-gray-100 p-2 ml-3'>
                                <button onClick={refreshFileList}>목록 새로고침</button>
                            </span>
                        </div>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        상태
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div>파일 이름</div>
                                        <div className='text-xs'>(클릭 다운로드)</div>
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        업로드 시점
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        올린사람
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        조작
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        메모
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        fileid
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {files.map((file) => (<tr key={file.upload_ts}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="text-gray-900">
                                            {file.status === '미리보기' &&
                                                <span
                                                    className="inline-flex rounded-full bg-green-100 px-3 py-2 text-xs font-semibold leading-5 text-green-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '업로드' &&
                                                <span
                                                    className="inline-flex rounded-full bg-gray-100 px-3 py-2 text-xs font-semibold leading-5 text-gray-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '대기' &&
                                                <span
                                                    className="inline-flex rounded-full bg-yellow-100 px-3 py-2 text-xs font-semibold leading-5 text-yellow-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '전시' &&
                                                <span
                                                    className="inline-flex rounded-full bg-blue-100 px-3 py-2 text-xs font-semibold leading-5 text-blue-800">
                                                {file.status}
                                            </span>
                                            }
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="flex items-center">
                                            <div className="text-gray-900">
                                                <button type="button" onClick={() => download_full(file.upload_ts)}>
                                                {file.org_filename}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{unixTimestampToDateTime(file.upload_ts)}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div>{file.uploader}</div>
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        {file.status === '대기' &&
                                            <button type="button" onClick={() => file_preview(file.upload_ts)}>
                                            <span
                                                className="inline-flex rounded-full bg-green-100 px-3 py-2 text-xs font-semibold leading-5 text-green-800">
                                                미리보기
                                            </span>
                                            </button>
                                        }
                                        {file.status === '미리보기' &&
                                            <button type="button" onClick={() => file_publish(file.upload_ts)}>
                                            <span
                                                className="inline-flex rounded-full bg-blue-100 px-3 py-2 text-xs font-semibold leading-5 text-blue-800">
                                                전시
                                            </span>
                                            </button>
                                        }
                                        {file.status === '업로드' &&
                                            <>
                                                <button type="button" onClick={() => file_ready(file.upload_ts)}>
                                            <span
                                                className="inline-flex rounded-full bg-yellow-100 px-3 py-2 text-xs font-semibold leading-5 text-yellow-800">
                                                대기
                                            </span>
                                                </button>
                                            </>
                                        }
                                        {(file.status === '대기' || file.status === '미리보기' ||  file.status === '업로드') &&
                                            <button type="button">
                                        <span
                                            className="inline-flex rounded-full bg-red-100 px-3 py-2 text-xs font-semibold leading-5 text-red-800">
                                            <button onClick={() => deleteFile(file.upload_ts)}>삭제</button>
                                            </span>
                                            </button>
                                        }
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div>
                                            {file.memo}
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div>
                                            {file.upload_ts}
                                        </div>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:flex my-3 sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">상태</h1>
                    <table className='text-xs'>
                        <tr>
                            <td className='w-24'>
                                <span
                                    className="inline-flex rounded-full bg-gray-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-gray-800"> 업로드 </span>
                            </td>
                            <td>
                                업로드된 파일, 대기 버튼을 눌러 파일을 서버에 표시 가능하게 변환하며 대기 상태로 바뀜
                            </td>
                        </tr>
                        <tr>
                            <td className='w-24'>

                                <span
                                    className="inline-flex rounded-full bg-yellow-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-yellow-800"> 대기 </span>
                            </td>
                            <td>
                                업로드된 파일의 기본 상태 아무런 영향을 주지 않음. <br/>미리 보기 중인 파일을 전시한 경우, 이미 전시 중이었던 파일은 대기 상태로 변함
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span
                                    className="inline-flex rounded-full bg-green-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-green-800">미리보기 </span>
                            </td>
                            <td>
                                미리 보기 클릭시 (관리자는 전시 전, 미리 볼 수 있음)
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span
                                    className="inline-flex rounded-full bg-blue-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-blue-800"> 전시 </span>
                            </td>
                            <td>
                                미리 보기 중인 것을 전시한 경우
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>

        <h1 className="mt-5 text-3xl font-extrabold tracking-tight text-slate-900">파일 업로드</h1>

        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">새로운 업무 파일을 업로드 하세요. 업로드 후 전시 하기 전에는 아무런 영향을 주지
                    않습니다.</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">업로더</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-3">

                            {userInStorage && userInStorage.payload && userInStorage.payload.username}

                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">파일 선택</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">

                            <input type='file' key={resetFile} onChange={handleFileInput}/>

                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">메모</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <input value={uploadContent.memo} type='text' name='memo'
                                   className='border-1 border-gray-200 border-b-1 bg-gray-100 p-3 w-64 my-2'
                                   onInput={haandleInput}/>
                            <button type="button" onClick={submitForm}>
                                <span
                                    className="inline-flex bg-gray-800 px-4 py-3 ml-3 text-xs font-semibold leading-5 text-white"> 업로드 </span>
                            </button>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        {
            showSpinner && (
                <CenterLoadSpinner/>
            )
        }
    </>);
}