import React, {useEffect} from "react";
import 'react-base-table/styles.css'
import {useState} from "react";


function App({document_code, document_code_sub}) {

    const [serverContent, setServerContent] = useState({})

    const fetchData = async () => {
        try {
            const response = await fetch(`https://api.seoulhousinginfo.com/admin/document/${document_code}/${document_code_sub}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            });
            const server_content = await response.json();
            server_content.data = JSON.parse(server_content.data)
            setServerContent((prev) => {
                return ({...prev, ...server_content})
            })

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // location 변경 감지시 pageview 이벤트 전송
    useEffect(() => {
        fetchData()
    }, []);

    return (<>
            <div className='text-xs md:text-sm flex justify-center '>
                <div style={{width: '768px'}}>
                    <div className='w-full' dangerouslySetInnerHTML={{__html: serverContent.data}}>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
