// ScrollRestoration.js
import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {screenWidthState} from "../App";

const ScrollRestoration = ({ history }) => {
    useEffect(() => {
        const unlisten = history.listen((_, action) => {
            if (action === 'PUSH') {
                history.replace({ ...history.location, state: { ...history.location.state, scroll: window.scrollY } });
                window.scrollTo(0, 0);
            } else if (action === 'POP' && history.location.state?.scroll) {
                window.scrollTo(0, history.location.state.scroll);
            }
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return null;
};

export default withRouter(ScrollRestoration);

export function useScreenWidth() {
    const [screenWidth, setScreenWidth] = useRecoilState(screenWidthState);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

}