import React from "react";
import 'react-base-table/styles.css'
import T1211 from "../work1/data1/T1211";
import G1211 from "../work1/data1/G1211";
import G1213 from "../work1/data1/G1213";
import G1214 from "../work1/data1/G1214";
import G1212 from "../work1/data1/G1212";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';
    

    const serverData = useSyncData(work, metaKey, dataKey)

    return serverData && (<>
        <Depth3Title title='사업추진 단계별 차트 (합계)'/>
        <G1211 data={JSON.parse(serverData.data['1211'])}/>
        <Depth3Title title='사업추진 단계별 차트 (도시 및 주거환경정비)'/>
        <G1212 data={JSON.parse(serverData.data['1211'])}/>
        <Depth3Title title='사업추진 단계별 차트 (재정비 촉진지구)'/>
        <G1213 data={JSON.parse(serverData.data['1211'])}/>
        <Depth3Title title='사업추진 단계별 차트 (소규모 정비사업)'/>
        <G1214 data={JSON.parse(serverData.data['1211'])}/>
        <Depth3Title title='사업추진 단계별 현황'/>
        <T1211 code='1211' data={JSON.parse(serverData.data['1211'])}/>
    </>)
}