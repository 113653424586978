import React, {useEffect, useState} from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";
import {filterDataByCondition2} from "../../../utils/TableUtils";


function App({data, searchCondition}) {



    const [filteredData, setFilteredData] = useState([]);
    const [minValue, setMinValue] = useState([]);
    const [maxValue, setMaxValue] = useState([]);

    useEffect(() => {
        const fixedrows = data.rows.map(([year, month, 인구수]) => {
            return {
                year: year, month: month,
                '인구수': 인구수 / 1
            };
        });

        if (searchCondition.시작월) {
            setFilteredData(filterDataByCondition2(fixedrows, searchCondition))
        }


    }, [data, searchCondition]);

    useEffect(() => {

        let min = 49999999;
        let max = -1;

        filteredData.forEach(v => {
            const value = v.인구수
            if (value < min) {
                min = value;
            }
            if (value > max) {
                max = value;
            }
        })
        setMinValue(parseInt((min - 999999) / 100000) * 100000) // 약 8백만
        setMaxValue(parseInt((max + 99999) / 100000) * 100000) // 약 8백만
        // console.log('인구수 최소/최대', min, max)


    }, [filteredData])

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -15, right: 10
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" domain={[minValue, maxValue]} orientation="left" tick={<CustomizedYAxisTick devide={1000} screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(천 명)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:20, fontSize: 12}}
                    />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend vertzicalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey="인구수" yAxisId='left' barSize={10} fill="#FD4857"/>


                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
