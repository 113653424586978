import React, {  useEffect, useState } from 'react';
import { Alert, Popover , notification, Col, Row , Layout, Image , Select, Table,Radio, Space, DatePicker, ConfigProvider    } from 'antd';
import { cloneDeep} from "lodash";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ko_KR.js';
import 'dayjs/locale/ko' 
import { Pie } from '@ant-design/plots';
import axios from "axios";
import seoul from './img/seoul.png'
import aptData from './data/apt.json'
const { RangePicker } = DatePicker;

// components
// views
dayjs.locale('ko');
export default function ForeCast_Si(props) {
    const [list, setList] = useState({
        '구': [],
      });
      const [tableList, setTableList] = useState({
        '전체': [],
        '전월세': [],
      });
      const [getbubjeoung, setGetBubjeoung] = useState([]);
      const [filteredInfo, setFilteredInfo] = useState({
        gu: null,
      });
      const [showNoti, contextHolder] = notification.useNotification();
      const [columns, setColumns] = useState([]);
      const [monthlyColumns, setMonthlyColumns] = useState([]);

      useEffect(() => {
        if (!filteredInfo.startPredictionTime) {
            const startPredictionTime = dayjs().format('YYYYMM');
            const endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
            getTableColumn(startPredictionTime,endPredictionTime)
            getJunWallTable()
            changeOption([startPredictionTime, endPredictionTime], 'time')
        } else {
            requireData(filteredInfo);
        }
      },[filteredInfo])
    
      const content = (
        <div>
          <p>건축연한이 파악되지 않는 임차 예측물량</p>
        </div>
      );
      //1000단위당 콤마
      const CommaFormattedString =(text) => {
        const formattedText = text.replace(/\b(\d{4,})\b/g, (match) => {
            return parseInt(match, 10).toLocaleString();
        });
    
        return formattedText;
        }
    
      const requireData = (data) => {
        if (!data.startPredictionTime || !data.endPredictionTime) {
          return;
        }
        const headers = {
          'Access-Control-Allow-Origin': '*',
        }
        const url = `https://api.seoulhousinginfo.com/forecast/seoul-house-data?startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}`
        axios.get(url,{},{headers})
        .then(res => {
          getMonthlyTable(res.data);
        })
        .catch(error => {
            console.log(error);
        })
        return 
      }
      //데이터를 테이블에 맞게 변환
      const getMonthlyTable = (res) => {
        //그래프
        const obj = {};
        obj['서울시'] = [];
        obj['서울시'].push(
            {
                'type': '월세',
                'value': Number(res.monty_rent),
            },
            {
                'type': '전세',
                'value': Number(res.lease),
            },
        )
        const objTable = {};
        objTable['전월세'] = [];
        objTable['전월세'].push({
            name: '서울시',
            '전세': Number(res.lease) > 0 ? Number(res.lease) : '-',
            '월세': Number(res.monty_rent) > 0 ? Number(res.monty_rent) : '-',
            '계': Number(res.total) > 0 ? Number(res.total) : '-',
            key: '서울시'
        })
        const area = res.area_val
        const type = res.type_val
        objTable['전체'] = [];
        objTable['전체'].push({
            name: '서울시 계',
            '40㎡미만': Number(area['val_0040'].total) > 0 ? Number(area['val_0040'].total) : '-',
            '40-85㎡미만': Number(area['val_4085'].total) > 0 ? Number(area['val_4085'].total) : '-',
            '85㎡이상': Number(area['val_8500'].total) > 0 ? Number(area['val_8500'].total) : '-',
            '아파트': Number(type['val_아파트'].total) > 0 ? Number(type['val_아파트'].total) : '-',
            '연립다세대': Number(type['val_연립다세대'].total) > 0 ? Number(type['val_연립다세대'].total) : '-',
            '단독다가구': Number(type['val_단독다가구'].total) > 0 ? Number(type['val_단독다가구'].total) : '-',
            key: '서울시'
        })
        objTable['전체'].push({
            name: '최소 물량 지역',
            '40㎡미만': area['val_0040'].min_val,
            '40-85㎡미만': area['val_4085'].min_val,
            '85㎡이상': area['val_8500'].min_val,
            '아파트': type['val_아파트'].min_val,
            '연립다세대': type['val_연립다세대'].min_val,
            '단독다가구': type['val_단독다가구'].min_val,
            key: '최소 물량 지역'
        })
        objTable['전체'].push({
            name: '최대 물량 지역',
            '40㎡미만': area['val_0040'].max_val,
            '40-85㎡미만': area['val_4085'].max_val,
            '85㎡이상': area['val_8500'].max_val,
            '아파트': type['val_아파트'].max_val,
            '연립다세대': type['val_연립다세대'].max_val,
            '단독다가구': type['val_단독다가구'].max_val,
            key: '최대 물량 지역'
        })
        setTableList(cloneDeep(objTable))
        setList(cloneDeep(obj))
      }
      //6개월 초과선택시 알림
      const openNotificationWithIcon = (type, des) => {
        showNoti[type]({
          message: type === 'success' ? '업로드에 성공하였습니다.' : '선택에 실패하였습니다.',
          description:
          type === 'success' ? des + '업로드 완료' : des,
          placement: 'top',
        });
      };
      const changeOption = (data, dataIndex) => {
        const res = cloneDeep(filteredInfo);
        if (data !== '0' && data !== '') {
          if (dataIndex === 'time') {
              res.startPredictionTime = data[0];
              res.endPredictionTime = data[1];
          }
        }
        setFilteredInfo(cloneDeep(res))
      };
      const config = (res) => {
        return {
          data: list[res],
          appendPadding: 10,
          angleField: 'value',
          colorField: 'type',
          radius: 0.9,
          legend: false,  
          color: ['#007185','#EB5C7A'],
          label: {
            type: 'inner',
            offset: '-50%',
            content: (data) => {
                return `${data.type}\n${data.value.toLocaleString()}`  // Format the number with comma for thousand separators
            },
            style: {
                justifyContent:'center',
                textAlign: 'center',
                fill: '#FFFFFF',
                fontSize: 18,
                fontWeight: 700,
            },
          },
          tooltip: {
            formatter: (datum) => {
              console.log(datum)
              return { name: datum.type, value: Number(datum.value)?.toLocaleString()};

            },
          }
        }
      };
      const getJunWallTable = (start, end) => {
        const area = [
            {
                title: '40㎡미만',
                align: "center",
                dataIndex: '40㎡미만',
                key: '40㎡미만',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },
            },
            {
                title: '40-85㎡미만',
                align: "center",
                dataIndex: '40-85㎡미만',
                key: '40-85㎡미만',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },            },
            {
                title: '85㎡이상',
                align: "center",
                dataIndex: '85㎡이상',
                key: '85㎡이상',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },            },
        ];
        const type = [
            {
                title: '아파트',
                align: "center",
                dataIndex: '아파트',
                key: '아파트',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },            },
            {
                title: '연립다세대',
                align: "center",
                dataIndex: '연립다세대',
                key: '연립다세대',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },            },
            {
                title: '단독다가구',
                align: "center",
                dataIndex: '단독다가구',
                key: '단독다가구',
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                  return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                  };
                },
                render: (text) => {
                    const t = CommaFormattedString(text.toString())
                  return  <Space style={{fontSize: 14}}>{t}</Space>
                },            },
        ];
        const column = [    
          {
            title: '구분',
            align: "center",
            dataIndex: 'name',
            key: 'key',
            onCell: (text, record) => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              {
                  return text === '확인불가' ? 
                  <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                  :
                    <Space style={{fontSize: 14, fontWeight: 700}}>{text}</Space>
                  
              }
            }
          },
        ]

        column.push(
          {
            title: '면적별 전월세 물량예측',
            children: area,
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {justifyContent:'center', alignItems:'center', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => <Space style={{fontSize: 14}}>{text}</Space>,
          },
        )
        column.push(
          {
            title: '주택유형별 전월세 물량예측',
            children: type,
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => <Space style={{fontSize: 14}}>{text}</Space>,
          },
        )
        setMonthlyColumns(column)
      }
      // 각 아파트이름으로 column 생성 
      const getTableColumn = (start, end) => {
        const column = [    
          {
            title: '구분',
            align: "center",
            dataIndex: 'name',
            key: 'key',
            onCell: (text, record) => {
              // const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              {
                  return text === '확인불가' ? 
                  <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                  :
                    <Space style={{fontSize: 14, fontWeight: 700}}>{text}</Space>
                  
              }
            }
          },
        ]
        column.push(
          {
            title: '전세',
            dataIndex: '전세',
            key: '전세',
            align: "center",
            onCell: (text, record) => {
              // const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              return <Space style={{fontSize: 14}}>{text}</Space>
          },
          },
        )
        column.push(
          {
            title: '월세',
            dataIndex: '월세',
            key: '월세',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? 'red' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6'},
                className: 'wallse-class-in-td',
              };
            },
            render: (text) => {                
              return <Space style={{fontSize: 14}}>{text}</Space>
            },
          },
        )
        column.push(
          {
            title: '계',
            dataIndex: '계',
            key: '계',
            align: "center",
            onCell: (text, record) => {
              // const checkCase = text['name'].length > 50 ? 'red' : 'green;
              return {
                ['style']: {borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              return <Space style={{fontSize: 14}}>{text}</Space>
            },          
          },
          )
        setColumns(column)
      }
      const CustomHeaderCell = ({ style, ...props }) => (
        <th {...props} style={{ ...style,borderRadius:0,  backgroundColor: '#FFEAE6', borderColor: '#D4D4D6', textAlign:'center', alignItems: 'center' }} />
      );
      
      const TableComponents = {
        header: {
          cell: CustomHeaderCell,
        },
      };
      const pieChartStyle = {
        backgroundImage: `url(${seoul})`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundRepeat: 'no-repeat',  // 이미지가 반복되지 않도록 설정
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: 700,
        height: 700,
        marginRight: 15
      };
    
    return (
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'red',
            },
          }}
        >
            {contextHolder}
                <Row style={{alignItems:'center', flex: 1, paddingTop: 10, paddingBottom: 10, marginBottom: 10, paddingLeft: 10, position: 'sticky',top: 0, backgroundColor:'#ffccc7', zIndex:100}}>
                    <Col>
                        기간: 
                        <RangePicker
                        defaultValue={[dayjs(),]}
                        disabledDate={(current) => dayjs(current).isBefore(dayjs('20230701'))}
                        style={{ marginLeft: 5, marginRight: 5, textAlign:'center',justifyContent:'center', alignItems:'center'}} locale={locale} picker="month" onChange={(value) =>{ 
                        const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                        dayjs.extend(isSameOrAfter)
                        const check = dayjs(value[1]).subtract(5,'month');
                        const check2 = dayjs(dayjs(value[0])).isSameOrAfter(check, 'month')
                        const dateList = [dayjs(value[0]).format('YYYYMM'),dayjs(value[1]).format('YYYYMM')]
                        if(check2 === true){
                            getTableColumn(dayjs(value[0]),dayjs(value[1]));
                            getJunWallTable(dayjs(value[0]),dayjs(value[1]));
                            changeOption(dateList, 'time')
                            } else{
                            openNotificationWithIcon('error', '최대 6개월까지 선택 가능합니다.')
                            } 
                        }
                        }
                        />
                    </Col>
              </Row>
              <Layout style={{backgroundColor:'#FFFFFF', marginRight: 10, minHeight: 500}}>
                <Row style={{alignItems:'center', paddingBottom: 200}}>
                  <Col style={pieChartStyle}>
                  {list['서울시']?.length > 0 && <Pie style={{justifyItems:'center', alignItems:'center', width: 400, height: 400, zIndex: 10}}{...config('서울시')} />}
                  </Col>
                  <Col>
                    {tableList['전월세']?.length > 0 && <Table components={TableComponents} style={{borderWidth:1,borderColor: '#00000'}} tableLayout={'auto'} bordered={true} columns={columns} dataSource={[...tableList['전월세']]} pagination={false}   />}
                    {tableList['전체']?.length > 0 &&<Table components={TableComponents}  style={{borderWidth:1,borderColor: '#00000'}} bordered={true} columns={monthlyColumns} dataSource={[...tableList['전체']]} pagination={false}   />}
                  </Col>

                </Row>
            
                {/* {tableList['전월세']?.length > 0 && <Col style={{marginTop: 20}}>
                  <Table components={TableComponents} style={{borderWidth:1,borderColor: '#00000'}} tableLayout={'auto'} bordered={true} columns={columns} dataSource={[...tableList['전월세']]} pagination={false}   />
                </Col>}
                {tableList['전체']?.length > 0 && <Col style={{marginTop: 20}} >
                    <Table components={TableComponents}  style={{borderWidth:1,borderColor: '#00000'}} bordered={true} columns={monthlyColumns} dataSource={[...tableList['전체']]} pagination={false}   />
                </Col>} */}

            </Layout>

        </ConfigProvider>
    );
}