// accordionState.js
import { atom } from 'recoil';

export const accordionState = atom({
    key: 'accordionState',
    default: {}
});

export const userState = atom({
    key: "userState",
    default: {},
});

export const localSettingState = atom({
    key: "localSettingState",
    default: {},
});

export const serverSettingState = atom({
    key: "serverSettingState",
    default: {},
});
