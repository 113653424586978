import {Auth} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Input from './common/Input';
import { useSetRecoilState } from "recoil";
import {userState} from "../utils/recoilstate";

const LogIn = () => {
    const history = useHistory();
    const [user, setUser] = useState({username: '', password: ''});


    const handleInputChange = (event, keyName) => {
        event.persist();
        setUser((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    const setUserState = useSetRecoilState(userState);

    const logIn = async () => {
        try {
            await Auth.signIn({
                username: user.username,
                password: user.password,
            }).then((user) => {
                // Update custom:last_accessed attribute
                const lastAccessed = new Date().toISOString();
                Auth.updateUserAttributes(user, {
                    'custom:last_accessed': lastAccessed,
            })
                return user
            }).then(user => {

                localStorage.setItem('user', JSON.stringify(user.signInUserSession.accessToken));
                setUserState(user.signInUserSession.accessToken)

                history.push('/work')
            }).catch(error => {
                console.log('error:', error)
            })
        } catch (error) {
            console.error('error', error);
        }
    }



    return (
        <>
            <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">로그인 하십시오</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                        </p>
                    </div>
                    <div className="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input
                                id='email-address'
                                name='email'
                                required
                                type='email'
                                className="relative block w-full rounded-b-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={user.username}
                                placeholder='아이디나 이메일'
                                onChange={(e) => handleInputChange(e, 'username')}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input
                                id='password'
                                name='password'
                                required
                                type='password'
                                className="relative block w-full rounded-b-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={user.password}
                                placeholder='패스워드'
                                onChange={(e) => handleInputChange(e, 'password')}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        console.log('enter!')
                                        logIn();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <button type="submit"
                                onClick={() => logIn()}
                                className="group relative flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg className="h-5 w-5 text-white group-hover:text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                          <path fillRule="evenodd"
                                                d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                                                clipRule="evenodd"/>
                                        </svg>
                                      </span>
                            로그인
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LogIn;