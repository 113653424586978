import useDeviceSize from "../components/Util/DeviceSize";
import React, {useEffect, useState} from "react";
import {DocumentArrowDownIcon} from "@heroicons/react/20/solid";
import {dashIfZero, numberWithCommas} from "../util/commonutils";
import {Column} from "react-base-table";
import * as PropTypes from "prop-types";

export function DownloadButton({dataCode, title='엑셀'}) {
    const handleClick = () => {
        // Make API request to get the download link
        fetch(`https://api.seoulhousinginfo.com/admin/workfile/category/download/link/${dataCode}`)
            .then(response => response.json())
            .then(data => {
                const downloadLink = data.link;
                // Initiate file download
                window.open(downloadLink);
            })
            .catch(error => {
                console.error('Error retrieving download link:', error);
            });
    };

    return (
        <button onClick={handleClick}>{title}</button>
    );
}

export function DownloadExcel({dataCode, title='엑셀'}) {
    return <div className="flex inline-flex justify-end mb-1">
        <DocumentArrowDownIcon className="h-4 w-4" aria-hidden="true"/>
        <span className="text-xs">
                            <DownloadButton dataCode={dataCode} title={title}/>
                        </span>
    </div>;
}


export function AutoSizer({width, height, children, code}) {

    const deviceSize = useDeviceSize()
    const tableWidth = width
    const tableHeight = height;
    const deviceWidth = (deviceSize.width - 20); /* 20을 때는 이유는 device 좌우에 빈칸을 만들어내기 위해서 더 작은 스케일로 만들기 위해서 */
    let scaleFactor = (deviceWidth) / (tableWidth);
    scaleFactor = scaleFactor * tableWidth >= 768 ? 768 / tableWidth : scaleFactor

    const [containerHeight, setContainerHeight] = useState(tableHeight);

    useEffect(() => {
        const scaledTableHeight = (tableHeight + 100) * scaleFactor;
        setContainerHeight(scaledTableHeight);
    }, [scaleFactor, tableHeight]);

    /* containerHeight 에 몇을 더하는가는 custom header 를 썼느냐 아니냐로 나뉜다. */

    return (
        <div className='h-{{tableHeight}}' style={{marginRight: '0px'}}>
            <div className='hidden'>
                maxwidth: {tableWidth} / deviceWidth - 20 : {deviceWidth} / scaleFactor : {scaleFactor} (1이면
                안됨)
            </div>
            <div className="w-full flex justify-center" style={{height: containerHeight}}>
                <div className="-ml-1"
                     style={{transform: `scale(${scaleFactor})`, transformOrigin: "top"}}>
                    <DownloadExcel dataCode={code}/>
                    {children}
                </div>
            </div>
        </div>
    );
}

export const makeProperData = (columns, actualdata, not_number = [-1], prefix = 'row-') => new Array(actualdata.length).fill(0).map((row, rowIndex) => {
    /* fill 입력값 만큼의 빈 배열 생성 */
    return columns.reduce((rowData, column, columnIndex) => {
        // rowData[column.dataKey] = `R ${rowIndex} - C ${columnIndex}`

        if (columnIndex < 1) {
            /* 첫열은 연도일 가능성도 있다. 이 때는 숫자로 취급하지 않는 것이다. */
            rowData[column.dataKey] = actualdata[rowIndex][columnIndex];
        } else {
            if (!isNaN(actualdata[rowIndex][columnIndex]) && !not_number.includes(columnIndex)) {
                rowData[column.dataKey] = dashIfZero(numberWithCommas(actualdata[rowIndex][columnIndex]))
            } else {
                if (not_number.includes(columnIndex) && actualdata[rowIndex][columnIndex] === 0) {
                    rowData[column.dataKey] = ' '
                } else {
                    rowData[column.dataKey] = actualdata[rowIndex][columnIndex];
                }
            }
        }

        return rowData
    }, {
        /* 기초 값 */
        id: `${prefix}${rowIndex}`, parentId: null,
    })
})

export function applyFixedColumns(headerData, leftmostHeaderCount) {
    return headerData.map((column, columnIndex) => {
        let frozen;

        if (columnIndex < leftmostHeaderCount) {
            frozen = Column.FrozenDirection.LEFT;
        }

        return {
            ...column,
            frozen,
            width: column.width, // adjust the width as needed
        };
    });
} /* column 정보에 rowspan function 을 inject 한다. */

export const rowRenderer = (leftmostheadercount, rows_to_merge, color_on_lines) => ({
                                                                                        rowData,
                                                                                        rowIndex,
                                                                                        cells,
                                                                                        columns
                                                                                    }) => {
    /* 이 함수는 행별로 계속 호출된다. 꾸미는 cells 와 헤더의 값 (columns) 이 포함되어 있다 */


    /* 행의 제목이 합계인 경우 가로선을 합계색으로 */
    // if leftmost title has a '합계' is true
    const sumrowindex = Object.values(rowData).some(value => (value === '합계' || value === '합 계'))
    if (sumrowindex) {
        // for each cell not except headers
        cells = cells.map((cell) => {
            const style = {
                ...cell.props.style, backgroundColor: '#fcf7b6', fontWeight: 'bold', color: '#111111',
            }
            return React.cloneElement(cell, {style})
        });
    }

    /* - 와 % 나 , 없이 숫자로 취급되는 것을 우측 정렬, 단 제목열은 중앙 정렬 (년도 등) */
    cells = cells.map((cell, i) => {
        try {
            if (i >= leftmostheadercount &&
                (cell.props.children[1].props.cellData === '-' || !isNaN(cell.props.children[1].props.cellData.replaceAll(',', '').replaceAll('%','')))) {
                const style = {
                    ...cell.props.style, justifyContent: 'flex-end',
                }
                return React.cloneElement(cell, {style})
            }
        } catch {
        }
        return cell
    });

    /* 각 행의 제목 영역 컬러링 */
    cells = cells.map((cell, i) => {
        const style = {
            ...cell.props.style, backgroundColor: '#dbeafe', color: '#111111', fontWeight: '500',
        }

        if (i < leftmostheadercount) {
            return React.cloneElement(cell, {style})
        } else return cell
    });

    /* 계산된 rowspan 의 갯수만큼 height 를 쫙좍 늘린다. */
    /* 행제목 세로 셀병합 */
    if (columns[0].rowspan) {
        const defined = columns[0].rowspan[rowIndex]
        const rowSpan = defined ? defined : 1
        if (rowSpan > 1) {
            const cell = cells[0]
            const style = {
                ...cell.props.style,
                backgroundColor: '#dbeafe',
                color: '#111111',
                height: rowSpan * 28,
                alignSelf: 'flex-start',
                borderBottom: '1px solid #eeeeee',
                borderRight: '1px solid #eeeeee',
                zIndex: 1,
            }
            cells[0] = React.cloneElement(cell, {style})
        }
    }

    // 2번째 행제목 셀병합
    if (columns[0].rowspan2) {
        const defined = columns[0].rowspan2[rowIndex]
        const rowSpan = defined ? defined : 1
        if (rowSpan > 1) {
            const cell = cells[1]
            const style = {
                ...cell.props.style,
                backgroundColor: '#dbeafe',
                color: '#111111',
                height: rowSpan * 28,
                alignSelf: 'flex-start',
                borderBottom: '1px solid #eeeeee',
                borderRight: '1px solid #eeeeee',
                zIndex: 1,
            }
            cells[1] = React.cloneElement(cell, {style})
        }
    }


    /* 행제목에 대한 가로 셀병합 */
    let colSpanIndex = 0
    if (rows_to_merge.includes(rowIndex)) {

        const colSpan = 2 /* leftmost 갯수로 받으면 더 좋지만 3열을 모두 병합하는게 없다고 가정하여 2까지만 병합한다 */
        let width = cells[colSpanIndex].props.style.width
        for (let i = 1; i < colSpan; i++) {
            if (cells[colSpanIndex + i]) {
                width += cells[colSpanIndex + i].props.style.width
                cells[colSpanIndex + i] = null
            }
        }

        const style = {
            ...cells[colSpanIndex].props.style, width, backgroundColor: '#dbeafe', color: '#111111'
            , display: 'flex', alignItems: 'center', justifyContent: 'center',
        }
        cells[colSpanIndex] = React.cloneElement(cells[colSpanIndex], {style})
    } else {
        // merge 행이 아니라도 행의 첫 열에 대해서 가운데 정렬 그리고 줄간격을 1.2em 으로
        const style = {
            ...cells[colSpanIndex].props.style, backgroundColor: '#dbeafe', color: '#111111'
            , display: 'flex', alignItems: 'center', justifyContent: 'center', lineHeight: '1.2em'
        }
        cells[colSpanIndex] = React.cloneElement(cells[colSpanIndex], {style})
    }

    if (leftmostheadercount > 1) {
        // merge 행이 아니라도 행의 첫 두번째도 제목행이라면 그에 대해서 가운데 정렬 그리고 줄간격을 1.2em 으로 (이는 행의 첫번째 제목이 세로로 병합된 경우 사용하는 것인데 2번째 열은 거의 고려할 필요는 없다.
        colSpanIndex = 1
        if (cells[colSpanIndex]) {
            const style = {
                ...cells[colSpanIndex].props.style, backgroundColor: '#dbeafe', color: '#111111'
                , display: 'flex', alignItems: 'center', justifyContent: 'center', lineHeight: '1.2em'
            }
            cells[colSpanIndex] = React.cloneElement(cells[colSpanIndex], {style})
        }
    }

    /* 특별한 행인 경우 가로선을 소계색으로, 단 열 제목이 2개인 경우, 첫번째 것은 소계 색을 바꾸지 않는다. */
    if (color_on_lines.includes(rowIndex)) {
        // for each cell not except headers
        cells = cells.map((cell, i) => {

            if (cell) {
                /* 소계열이 2보다 클 경우는 마지막 컬럼만 소계로 취급하여 색칠한다. */
                const style = {
                    ...cell.props.style, backgroundColor: '#fffbe6', fontWeight: 'bold', color: '#111111',
                }
                return React.cloneElement(cell, {style})
            }
        });
    }

    /* 행의 제목이 합계인 경우 가로선을 합계색으로 */
    // if leftmost title has a '합계' is true
    const subsumrowindex = Object.values(rowData).some(value => (value === '합계' || value === '합 계'))
    if (subsumrowindex) {
        // for each cell not except headers
        cells = cells.map((cell) => {
            if (cell) {
                const style = {
                    ...cell.props.style, backgroundColor: '#fcf7b6', fontWeight: 'bold', color: '#111111',
                }
                return React.cloneElement(cell, {style})
            }
        });
    }

    /* 열의 제목이 합계인 경우 세로선을 합계색으로 */
    // color rows and cols
    // if title is '합계'
    // TODO 배열로 colsumindex 로 바꿔야 함 indice
    const sumcolindex = columns.findIndex(column => column.title === '합계')
    if (sumcolindex > -1) {
        const cell = cells[sumcolindex]
        const style = {
            ...cell.props.style, backgroundColor: '#fcf7b6', fontWeight: 'bold', color: '#111111',
        }
        cells[sumcolindex] = React.cloneElement(cell, {style})
    }


    return cells
}
export const cellProps = (tableRef) => ({columnIndex}) => ({
    'data-col-idx': columnIndex, onMouseEnter: () => {
        const table = tableRef.current.getDOMNode()
        table.classList.add(`active-col-${columnIndex}`)
    }, onMouseLeave: () => {
        const table = tableRef.current.getDOMNode()
        table.classList.remove(`active-col-${columnIndex}`)
    },
})

export function convertPercentageToDecimal(value) {
    return parseFloat(value) / 100;
}

export function filterDataByCondition(fixedrows, searchCondition) {
    const filteredData = fixedrows.filter((row) => {
        const date = new Date(row[0], row[1] - 1, 1);
        const startDate = new Date(searchCondition.시작월 + '/01');
        const endDate = new Date(searchCondition.끝월 + '/01');

        return date >= startDate && date <= endDate;
    });
    return filteredData;
}

export function filterDataByCondition2(fixedrows, searchCondition) {
    const filteredData = fixedrows.filter(({year, month}) => {
        const date = new Date(year, month - 1, 1);
        const startDate = new Date(searchCondition.시작월 + '/01');
        const endDate = new Date(searchCondition.끝월 + '/01');

        return date >= startDate && date <= endDate;
    });
    return filteredData;
}