import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";

function App({data, code}) {

    const HEADER_SIZED = [
        { "key": "column-0", "dataKey": "column-0", "title": "구분", "title2": "구분", "title1": "구분", "width": 90, "rowspan": {} },
        { "key": "column-26", "dataKey": "column-26", "title": "합계", "title2": "합계", "title1": "합계", "width": 70 },

        { "key": "column-1", "dataKey": "column-1", "title": "합 계", "title2": "합계", "title1": "합계", "width": 50 },
        { "key": "column-2", "dataKey": "column-2", "title": "도시 및 주거환경정비사업", "title2": "도시 및 주거환경정비사업", "title1": "도시 및 주거환경정비사업", "width": 50 },
        { "key": "column-3", "dataKey": "column-3", "title": "재정비촉진지구 ", "title2": "재정비촉진지구 ", "title1": "재정비촉진지구 ", "width": 50 },
        { "key": "column-4", "dataKey": "column-4", "title": "소규모정비사업", "title2": "소규모정비사업", "title1": "소규모정비사업", "width": 50 },
        { "key": "column-11", "dataKey": "column-11", "title": "합 계", "title2": "합계", "title1": "합계", "width": 50 },

        { "key": "column-12", "dataKey": "column-12", "title": "도시 및 주거환경정비사업", "title2": "도시 및 주거환경정비사업", "title1": "도시 및 주거환경정비사업", "width": 50 },
        { "key": "column-13", "dataKey": "column-13", "title": "재정비촉진지구 ", "title2": "재정비촉진지구 ", "title1": "재정비촉진지구 ", "width": 50 },
        { "key": "column-14", "dataKey": "column-14", "title": "소규모정비사업", "title2": "소규모정비사업", "title1": "소규모정비사업", "width": 50 },
        { "key": "column-21", "dataKey": "column-21", "title": "합 계", "title2": "합계", "title1": "합계", "width": 50 },

        { "key": "column-22", "dataKey": "column-22", "title": "도시 및 주거환경정비사업", "title2": "도시 및 주거환경정비사업", "title1": "도시 및 주거환경정비사업", "width": 50 },
        { "key": "column-23", "dataKey": "column-23", "title": "재정비촉진지구 ", "title2": "재정비촉진지구 ", "title1": "재정비촉진지구 ", "width": 50 },
        { "key": "column-24", "dataKey": "column-24", "title": "소규모정비사업", "title2": "소규모정비사업", "title1": "소규모정비사업", "width": 50 },
    ]


    const LEFTMOST_HEADER_COUNT = 1
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [-1]

    const width_table = 760
    const height_table = 730

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-3 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '90px'}} className="row-span-3 flex word-spacing items-center justify-center">자치구 </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">합계</div>

                <div style={{width: '250px'}} className="row-span-1 col-span-5 flex word-spacing items-center justify-center">일반</div>
                <div style={{width: '150px'}} className="row-span-1 col-span-3 flex word-spacing items-center justify-center">재건축 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">공동 주택 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">단독 주택 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">아파트 지구 </div>
                <div style={{width: '100px'}} className="row-span-1 col-span-2 flex word-spacing items-center justify-center">재개발 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">주택 정비형 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">도시 정비형 </div>

                <div style={{width: '200px'}} className="row-span-1 col-span-4 flex word-spacing items-center justify-center">재촉지구 </div>
                <div style={{width: '100px'}} className="row-span-1 col-span-2 flex word-spacing items-center justify-center">재건축 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">공동 주택 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">단독 주택 </div>
                <div style={{width: '100px'}} className="row-span-1 col-span-2 flex word-spacing items-center justify-center">재개발 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">주택 정비형 </div>
                <div style={{width: '50px'}} className="row-span-1 flex word-spacing items-center justify-center">도시 정비형 </div>

                <div style={{width: '150px'}} className="row-span-1 col-span-3 flex word-spacing items-center justify-center">소규모정비 </div>
                <div style={{width: '50px'}} className="row-span-2 flex word-spacing items-center justify-center">가로 주택 </div>
                <div style={{width: '50px'}} className="row-span-2 flex word-spacing items-center justify-center">자율 주택 </div>
                <div style={{width: '50px'}} className="row-span-2 flex word-spacing items-center justify-center">소규모 재건축 </div>
            </div>

            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
        <div className='h-10' />
    </>);
}

export default App;
