import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data, searchCondition}) {

    const convertedData = data.rows.map(([year, p1, p2, p3, p4, p5]) => {
        return {
            year: year,
            '1인': p1 / 1,
            '2인': p2 / 1,
            '3인': p3 / 1,
            '4인': p4 / 1,
            '5인': p5 / 1,
        };
    });

    const filteredData = convertedData


    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -10, right: 10
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth} devide={1000}/>}  label={{  value: '(천 가구)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:10, fontSize: 12}} stroke="gray" />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey="1인" stackId='a' yAxisId='left' barSize={20} fill="#5b9bd5" />
                    <Bar dataKey="2인" stackId='a' yAxisId='left' barSize={20} fill="#ed7d31" />
                    <Bar dataKey="3인" stackId='a'  yAxisId='left' barSize={20} fill="#ffc000" />
                    <Bar dataKey="4인" stackId='a' yAxisId='left' barSize={20} fill="#4472c4"/>
                    <Bar dataKey="5인" stackId='a' yAxisId='left' barSize={20} fill="#70ad47" />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
