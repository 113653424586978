import React, {Component, useState} from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {useEffect} from "react";
import {Depth3Title} from "../Depth3View";

const editorConfiguration = {
    toolbar: ['bold', 'italic']
};

export default function App({document_code = '1141', document_code_sub = 'pc', document_name = '샘플'}) {

    const [content, setContent] = useState({
        document_name: document_name,
        document_code: document_code,
        object_extension: 'html',
        author: '손수민',
        content_for_device_type: document_code_sub,
        data: '',
    })

    const [serverContent, setServerContent] = useState({})

    const handleSave = async () => {
        try {
            const response = await fetch(`https://api.seoulhousinginfo.com/admin/document/${content.document_code}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(content),
            });
            // Handle the response if needed


        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    const fetchData = () => {
        fetch(`https://api.seoulhousinginfo.com/admin/document/${document_code}/${document_code_sub}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    // 400 에러가 발생했을 때
                    setServerContent((prev) => ({
                        document_name: document_name,
                        document_code: document_code,
                        object_extension: 'html',
                        author: '손수민',
                        content_for_device_type: document_code_sub,
                        data: '',
                    }));
                    throw new Error('Error fetching data');
                }
            })
            .then(server_content => {
                server_content.data = JSON.parse(server_content.data);
                setServerContent((prev) => ({...prev, ...server_content}));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    // location 변경 감지시 pageview 이벤트 전송
    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div className="App">
            <Depth3Title title={document_name}/>
            <div className='mt-5' >&nbsp;</div>
            <div className={"float-right"}>
            <button onClick={handleSave} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                저장
            </button>
            </div>
            <div className='text-xs md:text-sm flex justify-center '>
                <div style={{width: '768px'}}>
                    <CKEditor
                        editor={Editor}
                        // config={editorConfiguration}
                        // data="<p>엑셀 또는 워드에서 내용을 붙여 넣으세요,</p><p> 엑셀양식을 폰트,표 배경색등을 그대로 붙여넣고 싶을때는 일단 엑셀 내용을 워드에 한번 붙여 넣은 후 워드 내용을 복사해서 이곳에 붙여 넣으세요.</p><p>표는 하나씩 분리</p>"
                        data={serverContent.data}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent((prev) => {
                                return ({...prev, data})
                            })
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
            </div>


            <div className='mt-5' >&nbsp;</div>
            <Depth3Title title={"표시될 페이지 미리 보기"}/>
            <div className='mt-5' >&nbsp;</div>
            <div className='text-xs md:text-sm flex justify-center '>
                <div style={{width: '768px'}}>
                    <div dangerouslySetInnerHTML={{__html: content.data}}>
                    </div>
                </div>
            </div>

            <div className='mt-5' >&nbsp;</div>
            <Depth3Title title={"현재 사용자에게 보이는 화면"}/>
            <div className='mt-5' >&nbsp;</div>
            <div className='text-xs md:text-sm flex justify-center '>
                <div style={{width: '768px'}}>
                    <div dangerouslySetInnerHTML={{__html: serverContent.data}}>
                    </div>
                </div>
            </div>
        </div>
    )
}