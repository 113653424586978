import React from "react";
import 'react-base-table/styles.css'
import T1311 from "../work1/data1/T1311";
import {Depth3Title} from "../Depth3View";
import D1311 from "../work1/data1/Document";


export default function App() {


    return (<>
        <Depth3Title title='공급물량 산정기준'/>
        <D1311 document_code={"1311"} document_code_sub={"pc"}/>
    </>);
}