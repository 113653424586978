import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";

// components
// views
import P111 from "../views/chart/P111";
import P112 from "../views/chart/P112";
import P122 from "../views/chart/P122";
import P121 from "../views/chart/P121";
import P131 from "../views/chart/P131";
import P132 from "../views/chart/P132";
import P133 from "../views/chart/P133";
import P134 from "../views/chart/P134";
import P141 from "../views/chart/P141";
import P142 from "../views/chart/P142";
import P143 from "../views/chart/P143";
import P144 from "../views/chart/P144";
import AdminMembers from "../views/chart/AdminMembers";
import AdminFileWork1 from "../views/chart/AdminWorkData1";
import AdminFileWorkFile1 from "../views/chart/AdminWorkFileDownload1";
import AdminFileWorkFile2 from "../views/chart/AdminWorkFileDownload2";
import AdminFileWorkFile3 from "../views/chart/AdminWorkFileDownload3";
import AdminFileWork2 from "../views/chart/AdminWorkData2";
import AdminSetting from "../views/chart/AdminSetting";
import {BackButton} from "../util/commonutils";
import {HiShare} from "react-icons/all";
import P113 from "../views/chart/P113";
import P231 from "../views/chart/P231";
import P232 from "../views/chart/P232";
import P212 from "../views/chart/P212";
import P213 from "../views/chart/P213";
import P233 from "../views/chart/P233";
import P215_1 from "../views/chart/P215_1";
import P215_2 from "../views/chart/P215_2";
import P215_3 from "../views/chart/P215_3";
import P215_4 from "../views/chart/P215_4";
import P215_5 from "../views/chart/P215_5";
import P215_6 from "../views/chart/P215_6";
import P215_7 from "../views/chart/P215_7";
import P221 from "../views/chart/P221";
import P200 from "../views/chart/P200";
import PFlowerChart from "../views/chart/PFlowerChart";
import TestSpinner from "../views/chart/TestSpinner";
import CKEditorView from "../views/chart/CKEditorFullDemo";

function ShareButton() {
    const [showToast, setShowToast] = useState(false);

    const handleShareClick = async () => {
        try {
            await navigator.share({
                title: '서울주택정책지원센터 모바일 리포트',
                text: '현재 보고 있는 페이지를 공유 합니다.',
                url: window.location.href
            });
        } catch (error) {
            // Sharing failed
            setShowToast(true);
        }
    };

    const handleCopyClick = () => {
        const input = document.createElement('input');
        input.setAttribute('value', window.location.href);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        setShowToast(true);
    }

    useEffect(() => {
        let toastTimeout;
        if (showToast) {
            toastTimeout = setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
        return () => clearTimeout(toastTimeout);
    }, [showToast]);

    return (
        <div style={{position: 'relative'}}>
            <button onClick={navigator.share ? handleShareClick : handleCopyClick}
                    className="fixed bottom-3 z-[999] right-3 w-10 h-10 rounded-full bg-rose-100 shadow-md flex items-center justify-center focus:outline-none">
                <HiShare className="w-6 h-6 text-rose-600"/>
            </button>
            {showToast && <div style={{
                position: 'fixed',
                bottom: '1rem',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#000',
                color: '#fff',
                padding: '0.5rem',
                borderRadius: '0.25rem',
                zIndex: 999
            }}>URL이 복사되었습니다!</div>}
        </div>
    );

}


export default function Depth3Layout() {

    return (
        <>

            <div className="py-3 px-2 bg-rose-700">
                <p className="text-lg text-gray-50 flex justify-start items-center">
                    <BackButton/>
                    자료를 확인하세요
                </p>
            </div>
            <ShareButton/>
            <div id="rotating-ui" className="hidden animate-spin w-6 h-6 border-t-2 border-primary rounded-full"></div>
            <div className="overflow-hidden bg-white px-3 sm:py-8 lg:pb-8 xl:pb-8 pt-8">
                <div className='space-y-5 mb-10 '>
                    {/* 데이터 로딩 뷰 완성 목록 */}
                    <Route path="/work/2/data/1_5" exact component={P215_1}/>
                    <Route path="/work/2/data/1_5" exact component={P215_2}/>
                    <Route path="/work/2/data/1_5" exact component={P215_3}/>
                    <Route path="/work/2/data/1_5" exact component={P215_4}/>
                    <Route path="/work/2/data/1_5" exact component={P215_5}/>
                    <Route path="/work/2/data/1_5" exact component={P215_6}/>
                    <Route path="/work/2/data/1_5" exact component={P215_7}/>
                    <Route path="/work/1/data/1_1" exact component={P111}/>
                    <Route path="/work/1/data/1_2" exact component={P112}/>

                    {/* admin */}
                    <Route path="/work/0/data/1_1" exact component={AdminFileWork1}/>
                    <Route path="/work/0/data/1_2" exact component={AdminFileWorkFile1}/>
                    <Route path="/work/0/data/1_3" exact component={AdminFileWork2}/>
                    <Route path="/work/0/data/1_4" exact component={AdminFileWorkFile2}/>
                    <Route path="/work/0/data/1_4_1" exact component={AdminFileWorkFile3}/>
                    <Route path="/work/0/data/1_5" exact component={AdminSetting}/>
                    <Route path="/work/0/data/2" exact component={AdminMembers}/>
                    <Route path="/work/0/data/1311" exact render={() => <CKEditorView document_code="1311" document_code_sub="pc" document_name={"공급물량 산정기준"} />}/>
                    <Route path="/work/0/data/1123" exact render={() => <CKEditorView document_code="1123" document_code_sub="pc" document_name={"모아타운 현황"}/>}/>


                    <Route path="/work/1/data/1_3" exact component={P113}/> {/* deprecated */}
                    <Route path="/work/1/data/2_1" exact component={P121}/>
                    <Route path="/work/1/data/2_2" exact component={P122}/>
                    <Route path="/work/1/data/3_1" exact component={P131}/>
                    <Route path="/work/1/data/3_2" exact component={P132}/>
                    <Route path="/work/1/data/3_3" exact component={P133}/>
                    <Route path="/work/1/data/3_4" exact component={P134}/>
                    <Route path="/work/1/data/4_1" exact component={P141}/>
                    <Route path="/work/1/data/4_2" exact component={P142}/>
                    <Route path="/work/1/data/4_3" exact component={P143}/>
                    <Route path="/work/1/data/4_4" exact component={P144}/>

                    <Route path="/work/2/data/0_0" exact component={P200}/>

                    <Route path="/work/2/data/3_1" exact component={P231}/>
                    <Route path="/work/2/data/3_2" exact component={P232}/>
                    <Route path="/work/2/data/3_3" exact component={P233}/>
                    <Route path="/work/2/data/1_2" exact component={P212}/> {/* 월간 거래량 */}
                    <Route path="/work/2/data/1_3" exact component={P213}/> {/* 월간 매물량 */}


                    <Route path="/work/2/data/2_1" exact component={P221}/>

                    {/* 현정이가 플라워 만들어 보고 싶다고 해서 예제로 만든거 */}
                    <Route path="/work/0/data/0_flower" exact component={PFlowerChart}/>
                    <Route path="/work/0/data/0_spinner" exact component={TestSpinner}/>
                    <Route path="/work/0/data/1_flower" exact component={TestSpinner}/>
                    <Route path="/work/0/data/2_editor" exact component={CKEditorView}/>
                </div>
            </div>
        </>
    );
}
