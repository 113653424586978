/*eslint-disable*/
import React from "react";
import 'tw-elements';
import {AccordionBody, AccordionHeading} from "./Acoordian";
import {useParams} from "react-router-dom";
import {
    SVGFile,
    SVGMembers,
    SVGHomeSearch,
    SVGHousingPlan,
    SVGMarket,
    SVGOverview,
    SVGPerBusiness,
    SVGSeoulDistrict, SVGIndex
} from "../../svg/For2Depth";
import {SVGCategory1_construction, SVGCategory2} from "../../svg/For1Depth";


const depth2Menus = {
    "1": [
        {
            id: 'collapseOne1',
            title: '정비사업 총괄현황',
            desc: '서울시 정비사업 준공 및 예정구역을 포함한 총괄 현황',
            image: <SVGOverview/>,
            bodyItems: [
                {
                    title: "정비사업 총괄 및 예정구역 현황",
                    subtitle: "사업 총괄 및 예정구역 현황",
                    link: '/work/1/data/1_1',
                },
                {
                    title: "주요사업별 예정 및 추진구역",
                    subtitle: "신통기획, 공공재개발•재건축, 모아타운 현황",
                    link: '/work/1/data/1_2',
                },
                {
                    title: "정비사업 추진구역 현황",
                    subtitle: "사업 추진구역 현황",
                    link: '/work/1/data/1_3'
                }
            ],
        },
        {
            id: 'collapseOne_2',
            title: '정비사업 추진구역 현황',
            image: <SVGSeoulDistrict/>,
            desc: '구역지정 이후 사업추진중인 구역의 사업단계별, 자치구별 현황',
            bodyItems: [
                {
                    title: "사업추진단계별 현황",
                    subtitle: "사업추진구역의 구역지정부터 착공까지 각 단계별 현황",
                    link: '/work/1/data/2_1'
                },
                {
                    title: "자치구별 현황",
                    subtitle: "서울시 25개 자치구 기준 사업 추진중인 구역 현황",
                    link: '/work/1/data/2_2'
                },
            ],
        },
        {
            id: 'collapseOne2',
            title: '정비사업 공급전망',
            image: <SVGHousingPlan/>,
            desc: '정비사업으로 공급되는 주택의 년도별 물량 확인',
            bodyItems: [
                {
                    title: "공급물량 산정 기준",
                    subtitle: "정비사업 단계별 평균 소요기간 분석을 통한 준공물량 산정기준",
                    link: '/work/1/data/3_1'
                },
                {
                    title: "과거 5년간 정비사업 공급물량",
                    subtitle: "연도별 정비사업으로 공급된 주택물량",
                    link: '/work/1/data/3_2'
                },
                {
                    title: "향후 5년간 정비사업 공급예정물량",
                    subtitle: "당해년도 포함 정비사업 공급예정물량",
                    link: '/work/1/data/3_3'
                },
                {
                    title: "향후 2년간 자치구별 정비사업 공급물량",
                    subtitle: "당해년도 포함 자치구별 정비사업 준공구역목록 및 공급물량",
                    link: '/work/1/data/3_4'
                }
            ],
        },
        {
            id: 'collapseOne3',
            title: '정비사업 유형별 현황',
            image: <SVGPerBusiness/>,
            desc: '재개발, 재건축, 재정비촉진구역, 소규모정비사업별 추진현황',
            bodyItems: [
                {
                    title: "재개발 사업(도시 및 주거환경정비)",
                    subtitle: "도시정비형, 주택정비형의 구역수, 기존세대수 및 공급물량",
                    link: '/work/1/data/4_1'
                },
                {
                    title: "재건축 사업(도시 및 주거환경정비)",
                    subtitle: "단독주택, 공동주택, 아파트지구의 구역수, 기존세대수 및 공급물량",
                    link: '/work/1/data/4_2'
                },
                {
                    title: "재정비촉진지구",
                    subtitle: "재촉지구 내 재건축, 재개발사업의 구역수, 기존세대수 및 공급물량",
                    link: '/work/1/data/4_3'
                },
                {
                    title: "소규모정비사업",
                    subtitle: "가로주택, 자율주택, 소규모재건축 구역수, 기존세대수 및 공급물량",
                    link: '/work/1/data/4_4'
                }
            ]
        }
    ],
    "2": [
        {
            id: 'collapseOne1',
            title: '서울시 주택가격지수',
            image: <SVGIndex/>,
            desc: '서울형, KB 가격지수를 통해 주택유형별, 지역별, 항목별 지수 비교',
            bodyItems: [
                {
                    title: "서울시 주택가격지수",
                    subtitle: "아파트, 연립다세대, 선도아파트",
                    link: '/work/2/data/3_1'
                },
                {
                    title: "지역별 주택가격지수",
                    subtitle: "주택유형별 권역, 자치구",
                    link: '/work/2/data/3_2'
                },
                {
                    title: "항목별 주택가격지수",
                    subtitle: "주택유형별 면적, 연한별 지수",
                    link: '/work/2/data/3_3'
                },
            ]
        },
        {
            id: 'collapseOne2',
            title: '주택시장 영향 요인',
            image: <SVGMarket/>,
            desc: '거래량, 매물량, 경매(예정), 주택시장 영향요인 동향',
            bodyItems: [
                {
                    title: "주택 거래량",
                    subtitle: "지역별 주택 거래량 동향",
                    link: '/work/2/data/1_2'
                },
                {
                    title: "주택 매물량",
                    subtitle: "지역별 주택 매물량 동향",
                    link: '/work/2/data/1_3'
                },
                {
                    title: "주택경매 동향 (예정)",
                    subtitle: "2023년 말 추가예정",
                },
                {
                    title: "주택시장 영향 요인",
                    subtitle: "기준금리, 소비자 물가상승률, 가계부채, 주담대금리, 인구, 가구",
                    link: '/work/2/data/1_5'
                }
            ]
        },
        {
            id: 'collapseOne3',
            title: '실거래가 분석',
            image: <SVGHomeSearch/>,
            desc: '주택유형별로 자치구별, 행정동별 실거래를 검색해 보세요',
            link: '/work/2/data/2_1',
            bodyItems: [
            ]
        },
    ],
    "0":
        [ {
                id: 'collapseOne3',
                title: '전시설정',
                image: <SVGFile />,
                desc: '전시할 표와 그래프의 원본 데이터를 관리 합니다.',
                bodyItems: [
                    {
                        title: "정비사업주친현황 전시 내용 관리",
                        subtitle: "정비사업주친현황 전시할 내용을 관리 합니다.",
                        link: '/work/0/data/1_1'
                    },
                    {
                        title: "정비사업주친현황 다운로드 파일 관리",
                        subtitle: "정비사업주친현황 표의 다운로드 지원 파일을 관리 합니다.",
                        link: '/work/0/data/1_2'
                    },
                    {
                        title: "공급물량 산정 기준",
                        subtitle: "웹에디터로 공급물량 산정 기준 페이지를 편집합니다.",
                        link: '/work/0/data/1311'
                    },
                    {
                        title: "모아타운현황",
                        subtitle: "웹에디터로 모아타운현황 페이지를 편집합니다.",
                        link: '/work/0/data/1123'
                    },
                    {
                        title: "주택시장동향 전시 내용 관리",
                        subtitle: "주택시장동향 전시할 내용을 관리 합니다",
                        link: '/work/0/data/1_3'
                    },
                    {
                        title: "주택시장동향 다운로드 파일 관리",
                        subtitle: "주택시장동향 표의 다운로드 지원 파일을 관리 합니다",
                        link: '/work/0/data/1_4'
                    },
                    {
                        title: "전세, 월세 예측 자료 업로드",
                        subtitle: "전세, 월세 예측 자료 업로드 합니다",
                        link: '/work/0/data/1_4_1'
                    },
                    {
                        title: "설정",
                        subtitle: "미리보기 활성화 등 세부 기능을 설정합니다.",
                        link: '/work/0/data/1_5'
                    },
                ]
            },
            {
                id: 'collapseOne2',
                title: '회원 관리',
                image: <SVGMembers/>,
                desc: '회원을 추가하고 권한을 부여 합니다.',
                link: '/work/0/data/2',
                bodyItems: []
            },
        ]
}

export default function Depth2List() {
    const {workId} = useParams();
    const items = depth2Menus[workId]

    return (
        <>
            <section className="mt-10 md:mt-10 pb-40 relative ">
                <div id="accordionMenu" className=''>
                    {items.map((item) => (
                        <div
                            key={item.id}
                            className="rounded-t-lg border border-neutral-200 bg-white"
                        >
                            <AccordionHeading collapseFor={item.id} title={item.title} desc={item.desc} link={item.link}
                                              image={item.image}/>
                            <AccordionBody items={item.bodyItems} collapseFor={item.id}/>
                        </div>
                    ))}
                </div>

            </section>
        </>
    );
}
