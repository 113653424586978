import React, {useCallback, useEffect, useState} from "react";
import 'react-input-range/lib/css/index.css';
import Select from 'react-select'
import 'react-base-table/styles.css';
import * as PropTypes from "prop-types";
import S21X from "./S21X";
import {dong_coords} from "./map";
import axios from "axios";


const Checkbox = ({children, ...props}) => (<label style={{marginRight: '1em'}}>
    <input type="checkbox" {...props} />
    {children}
</label>);

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|iemobile/i.test(userAgent);
}

function TurnOffKeyboard(props) {
    return <div
        style={{
            color: "hsl(0, 0%, 40%)", display: "inline-block", fontSize: 12, fontStyle: "italic", marginTop: "1em",
        }}
    >
        <Checkbox
            checked={props.checked}
            onChange={props.onChange}
        >
            키보드 꺼짐 (키보드를 사용해 검색하려면 체크를 해제하세요)
        </Checkbox>
    </div>;
}

TurnOffKeyboard.propTypes = {
    checked: PropTypes.bool, onChange: PropTypes.func
};

function SelectBox({options, value, onChange, isDisabled}) {

    const [chooseOnly, setChooseOnly] = useState(isMobileDevice() ? true : false);

    return (<><Select
        options={options}
        value={value}
        isSearchable={!chooseOnly}
        menuPlacement="auto"
        menuPosition="fixed"
        isDisabled={isDisabled}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onChange={onChange}
        isMulti placeholder="선택하거나 검색하세요"/>

        {isMobileDevice() && <TurnOffKeyboard checked={chooseOnly} onChange={() => setChooseOnly((state) => !state)}/>}
    </>);
}

function SelectBoxesForApt({data, onChoosen}) {
    const [guOption, setGuOption] = useState([]);
    const [dongOption, setDongOption] = useState([]);
    const [aptOption, setAptOption] = useState([]);
    const [dongSet, setDongSet] = useState([]);
    const [choosenGu, setChoosenGu] = useState([]);
    const [choosenDong, setChoosenDong] = useState([]);
    const [choosenApt, setChoosenApt] = useState([]);
    const [choosenAptCache, setChoosenAptCache] = useState([]);

    useEffect(() => {
        const headers = data.headers.map(row => row.title).slice(2) // 구분은 제외하고 제목 리스트업
        const guset = new Set()
        const dongset = new Set()

        headers.forEach(h => {

            if (h.includes('자치구')) {
                guset.add(h.substring(h.indexOf('자치구') + 4)) // *자치구_ 를 날리고
            }
        })

        headers.forEach(h => {

            if (h.includes('법정동')) {
                dongset.add(h.substring(h.indexOf('법정동') + 4)) // *자치구_ 를 날리고
            }
        })

        const gulist = Array.from(guset).sort()
        setDongSet(dongset)

        const guOptions = gulist.map(g => {
            return {value: g, label: g}
        })

        setGuOption(guOptions)
    }, [data])

    const debouncedSearch = (fn, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };

    const loadOptions = async (inputValue, callback) => {
        // Make an API call to fetch the candidate data from the server with the search term
        // const response = await fetch(`/api/candidates?search=${inputValue}`);
        //
        const data = ['한양아파트 (잠실동)', '한양아파트 (광장동)', '현대아파트', '우성아파트', '대림아크로빌'] // inputValue 에 따라 서버에서 검색해서 리스팅 해야 한다.

        // Map the candidate data to an array of options for React-Select
        const options = data.map((candidate) => ({
            value: candidate,
            label: candidate,
        }));
        // const options = data.map((candidate) => ({
        //     value: candidate.id,
        //     label: candidate.name,
        // }));

        console.log('loaded options', options)

        // Call the callback function with the options array to populate the dropdown
        callback(options);
    };

    const handleInputChange = debouncedSearch(loadOptions, 500);

    const asyncSelectRef = React.useRef();

    const handleSelectChangeGu = (option) => {
        setChoosenGu(option.label)
        const gudong = dong_coords

        const dongs = new Set()
        gudong.forEach(d => {
            if (d.gu === option.value) {
                if (dongSet.has(d.dong)) {
                    dongs.add(d.dong)
                }
            }
        })
        // setChoosenDong(null)

        const dongOptions = Array.from(dongs).sort().map(g => {
            return {value: g, label: g}
        })

        setDongOption(dongOptions)
    };

    const handleSelectDong = (option) => {

        const gu = choosenGu
        const dong = option.value

        setChoosenDong(dong)

        const apiUrl = `https://api.seoulhousinginfo.com/apt/search/gu/${gu}/dong/${dong}/pnu`;

        axios.get(apiUrl)
            .then(response => {
                const pnus = JSON.parse(response.data[0]['검색어'])
                const aptOption = pnus.map(v => {
                    return {value: v[0], label: v[1]}
                })

                setAptOption(aptOption)
            })
            .catch(error => {
                console.log(error); // handle any errors
            });
    };

    useEffect(() => {

        onChoosen(choosenAptCache)

    }, [choosenAptCache])


    const handleSelectApt = (option) => {

        const gu = choosenGu
        const dong = choosenDong
        const pnu = option.value
        const apt_name = option.label


        const iscached = choosenAptCache.filter(v => {
            if (v.pnu === pnu) {
                return true
            }
        })

        if (iscached.length > 0) {
            // 이미 캐시되었으면 로딩해 오지는 않지만 선택옵션에는 추가한다.
            const matched = choosenAptCache.find(obj => obj.pnu = pnu)
            const new_arr_of_cache = choosenAptCache.filter(obj => obj.pnu !== pnu);
            new_arr_of_cache.push(matched);

            setChoosenAptCache(new_arr_of_cache)

            return
        }

        const apiUrl = `https://api.seoulhousinginfo.com/apt/median/gu/${gu}/dong/${dong}/pnu/${pnu}`;
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data)
                const medians = response.data

                setChoosenAptCache(prev => {
                    const newKey = `${dong} ${apt_name}`;
                    return [...prev, {pnu: pnu, label: newKey, value: medians}];
                })

            })
            .catch(error => {
                console.log(error); // handle any errors
            });
    };


    return (
        <div className='space-y-3'>
            <div className='w-1/2 pr-3 inline-block'>
                <Select
                    isSearchable={false}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    options={guOption}
                    onChange={handleSelectChangeGu}
                    placeholder="자치구 선택"
                />
            </div>
            <div className='w-1/2 inline-block'>
                <Select
                    isSearchable={false}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    options={dongOption}
                    isDisabled={dongOption.length === 0}
                    onChange={handleSelectDong}
                    placeholder="법정동 선택"
                />
            </div>
            <Select
                value={choosenApt}
                options={aptOption}
                onChange={handleSelectApt}
                placeholder="아파트를 선택하여 상세 조건에 추가"
                isDisabled={aptOption.length === 0}
                isClearable={true}
            />
        </div>
    );
}

function App({data, onChangeSearchOption}) {
    const [searchSlideCondition, setSearchSlideCondition] = useState({})
    const [searchCondition, setSearchCondition] = useState({
        /*
        // 기본 선택을 모두 하는 경우
        유형: ['매매', '전세'],
        주제: ['아파트', '연립다세대'],
        지수: ['KB지수', '서울형'],
        지역: ['시도권역별', '자치구별'],
        */
        유형: [], 주제: [], 지수: [], 지역: [], 시작월: '2021/01', 끝월: '2021/02', 검색어: '', 상세검색: [], 상세검색상세목록: [], 아파트캐시: []
    });

    const [uniqueOptions, setUniqueOptions] = useState([])
    const [titles, setTitles] = useState([])


    useEffect(() => {

        handleMonthRangeChange(searchSlideCondition.시작월, searchSlideCondition.끝월)

    }, [searchSlideCondition])

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [isDisabledSelectBox, setisDisabledSelectBox] = useState(false);

    function getUniqueValues(arr) {
        const result = [];

        for (const item of arr) {
            const tokens = item.split('_');

            for (let i = 0; i < tokens.length; i++) {
                if (!result[i]) {
                    result[i] = [];
                }

                if (!result[i].includes(tokens[i])) {
                    result[i].push(tokens[i]);
                }
            }
        }

        return result;
    }

    useEffect(() => {

        let headers = data.headers.map(row => row.title).slice(2) // 구분은 제외하고 제목 리스트업
        let uniqueOptions = getUniqueValues(headers)

        setUniqueOptions(uniqueOptions)

        console.log('uniqueOptions', uniqueOptions)
        setTitles(headers)

    }, [data])

    useEffect(() => {


            if (searchCondition.주제.length > 0 && searchCondition.지역.length > 0) {
                const lastcandidate = []

                searchCondition.주제.forEach(o1 => {
                    searchCondition.지역.forEach(o2 => {
                        uniqueOptions[2].forEach(o3 => {
                            const item = o1 + '_' + o2 + '_' + o3

                            if (titles.includes(item) && !lastcandidate.some(obj => obj.value === o3)) {
                                lastcandidate.push({value: o3, label: o3})
                            }
                        })
                    })
                })

                console.log('마지막 옵션 가능항목', lastcandidate)
                setSelectOptions(lastcandidate)

                if (lastcandidate.length) {
                    setisDisabledSelectBox(false)
                }
            } else {
                setSelectedOptions([])
                setisDisabledSelectBox(true)
            }

            let choosenHeaders = []
            searchCondition.주제.forEach((o1) => {
                searchCondition.지역.forEach((o2) => {

                    const item = `${o1}_${o2}`

                    if (titles.includes(item)) {
                        choosenHeaders.push(item)
                    }

                    searchCondition.상세검색.forEach((o4) => {
                        const item = `${o1}_${o2}_${o4}`
                        if (titles.includes(item)) {
                            choosenHeaders.push(item)
                        }
                    })

                })
            });

            console.log(selectedOptions)
            selectedOptions.forEach(v =>
                {
                    if(v.pnu) {
                        choosenHeaders.push(v.label)
                    }
                }
            )

            console.log('체크박스 변경시의 헤더', choosenHeaders)

            onChangeSearchOption({...searchCondition, 상세검색상세목록: choosenHeaders})

        }
        , [searchCondition])

    const handleOnSelectedChange = (newSelectedOptions) => {
        // Sort the selected options alphabetically by their label
        // 상세 검색을 했을 경우, 선택지들이 정렬되며, 선택지로 정해진다.
        const sortedOptions = newSelectedOptions.slice().sort((a, b) => a.label.localeCompare(b.label));
        setSelectedOptions(sortedOptions); // 선택지로 정해져버림

        const options = sortedOptions.map(v => v.value)

        const choosenHeaders = []
        searchCondition.유형.forEach((o1) => {
            searchCondition.주제.forEach((o2) => {
                searchCondition.항목.forEach((o3) => {
                    options.forEach((o4) => {
                        const item = o1 + '_' + o2 + '_' + o3 + '_' + o4

                        if (titles.includes(item)) {
                            choosenHeaders.push(item)
                        }
                    })
                })
            })
        });

        console.log('상세목록 변경시의 헤더', choosenHeaders)
        setSearchCondition(prevCondition => ({
            ...prevCondition, 상세검색상세목록: choosenHeaders, 상세검색: options
        }));

    };

    function handleMonthRangeChange(minDate, maxDate) {
        setSearchCondition(prevCondition => ({
            ...prevCondition, 시작월: minDate, 끝월: maxDate,
        }));
    }

    const onClick = useCallback((searchCondition) => {
        setSearchCondition(searchCondition)
    }, []);


    function handleRadioChange(event) {
        const {name, value} = event.target;
        setSearchCondition(prevCondition => ({
            ...prevCondition, [name]: value,
        }));
    }

    function handleCheckboxChange(event) {
        const {name, value, checked, id} = event.target;

        setSearchCondition((prevCondition) => ({
            ...prevCondition,
            [name]: checked ? [...prevCondition[name], value] : prevCondition[name].filter((val) => val !== value),
        }));
    }

    const onAptChoosen = (aptCache) => {
        console.log(aptCache)
        // last array of cache os new apt

        if (aptCache && aptCache.length > 0) {

            const last_touched_apt = aptCache[aptCache.length - 1]
            const new_apt_name = last_touched_apt.label

            setSearchCondition({...searchCondition, 아파트캐시: aptCache})
            const new_apt = {value: new_apt_name, label: new_apt_name, pnu:last_touched_apt.pnu}
            setSelectOptions((prev) => {
                const same_names = prev.filter(v => {
                    return v.label === new_apt_name
                })
                if (same_names.length > 0) {
                    return [...prev]
                }
                const new_list = new Set([...prev, new_apt]); // Create a new Set that includes the existing options and the new option

                return Array.from(new_list); // Convert the Set back to an array
            })
            setSelectedOptions((prev) => {
                const same_names = prev.filter(v => {
                    return v.label === new_apt_name
                })
                if (same_names.length > 0) {
                    return [...prev]
                }
                const new_list = new Set([...prev, new_apt]); // Create a new Set that includes the existing options and the new option

                return Array.from(new_list); // Convert the Set back to an array
            })
        }
    }

    return (

        <>
            <div className='p-3 border rounded-lg border-gray-200 mb-4 space-y-3 text-xs'>
                <div className="flex gap-x-4 items-center">
                    <div className="flex w-16">
                        주택유형
                    </div>
                    <div className="flex w-64 grow items-center space-x-3">
                        <div className='inline-flex items-center w-full'>
                            <input type="checkbox" id="option21" name="주제" value="아파트" class="peer"
                                   checked={searchCondition.주제.includes('아파트')}
                                   onChange={handleCheckboxChange}
                                   required/>
                            <label for="option21"
                                   class="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div class="block">
                                    아파트
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option22" name="주제" value="연립다세대"
                                   checked={searchCondition.주제.includes('연립다세대')}
                                   onChange={handleCheckboxChange}
                                   className="peer"/>
                            <label htmlFor="option22"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    연립다세대
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-4 items-center mt-3">
                    <div className="flex w-16">
                        지역구분
                    </div>
                    <div className="flex w-64 grow items-center space-x-3">
                        <div className='inline-flex items-center w-full'>
                            <input type="checkbox" id="option41" name="지역" value="서울시" className="peer"
                                   checked={searchCondition.지역.includes('서울시')}
                                   onChange={handleCheckboxChange}
                                   required/>
                            <label htmlFor="option41"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    서울시
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option42" name="지역" value="자치구" className="peer"
                                   checked={searchCondition.지역.includes('자치구')}
                                   onChange={handleCheckboxChange}
                                   required/>
                            <label htmlFor="option42"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    자치구
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option43" name="지역" value="법정동"
                                   checked={searchCondition.지역.includes('법정동')}
                                   onChange={handleCheckboxChange}
                                   className=" peer"/>
                            <label htmlFor="option43"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    법정동
                                </div>
                            </label>
                        </div>
                        {
                            searchCondition.주제.includes('아파트') &&
                            (
                                <>
                                    <div className='inline-flex items-center w-full'>
                                        <input type="checkbox" id="option44" name="지역" value="단지별"
                                               checked={searchCondition.지역.includes('단지별')}
                                               onChange={handleCheckboxChange}
                                               className=" peer"/>
                                        <label htmlFor="option44"
                                               className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                            <div className="block">
                                                단지별
                                            </div>
                                        </label>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
                <div className="">
                    검색기간
                </div>
                <S21X data={data.rows} onChangeSearchOption={setSearchSlideCondition}/>
                {
                    searchCondition.지역.includes('단지별') &&
                    (
                        <div className='border rounded-lg border-1 p-3'>
                            <div className="">
                                아파트 단지 검색 (검색하여 선택하면 상세 조건에 추가 됩니다)
                            </div>
                            <div className="">
                                <SelectBoxesForApt data={data} onChoosen={onAptChoosen}/>
                            </div>
                        </div>
                    )}
                <div className="">
                    상세조건
                </div>
                <div className="">
                    <SelectBox options={selectOptions} value={selectedOptions} onChange={handleOnSelectedChange}
                               isDisabled={false}/>
                </div>

            </div>

        </>)
}

export default App;
