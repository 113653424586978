import React, {  useEffect, useState } from 'react';
import { Alert, Popover , notification, Image, Col, Row , Layout, Menu, Select, Table,Radio, Space, DatePicker, ConfigProvider    } from 'antd';
import { cloneDeep} from "lodash";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ko_KR.js';
import 'dayjs/locale/ko' 
import { Bar, Column } from '@ant-design/plots';
import axios from "axios";
import {seoulGu, bubjungDong} from '../utils/guDong.js'
import headerImg from './img/seoulheader.jpg'
import seoulLogo from './img/charmingSeoul.png'
import Forecast_Dong from './ForeCast_Dong.js'
import Forecast_Apt from './ForeCast_Apt.js'
import Forecast_Si from './ForeCast_Si.js'
import Forecast_Gu from './ForeCast_Gu.js'

export default function ForecastMonthlyRent() {


  const { Sider, Content } = Layout;
  const [menu, setMenu] = useState('3');
  const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff', 
    height: '100%',
    position: 'sticky',top: 0,
    backgroundColor: '#d3d3d3',
  };
  useEffect(() => {
    selectMenu(menu);
  },[menu])
  const selectMenu = () => {
    console.log('menu', typeof(menu))
      if (menu === '1') {return <Forecast_Dong />}
      else if (menu === '2') {return <Forecast_Dong />}
      else if (menu === '3') {return <Forecast_Dong />}
      else if (menu === '4') {return <Forecast_Apt />}
  }

  return (
    <Space direction="vertical" style={{top:0, position: 'absolute',height: '100%', width: '100%', zIndex: 3}}>
      <Space direction="vertical" style={{top:0, position: 'absolute',height: '100%', width: '100%', zIndex: 3}}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'red',
          },
        }}
      >
      <Layout style={{flex: 1, backgroundColor: '#FFFFFF',  }}>
        <Image style={{flex: 1, minHeight: 50}} preview={false} src={ headerImg }/>
      </Layout>
      <Layout style={{marginBottom: 120}}>
        <Sider style={siderStyle}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="light"
            mode="inline"
            // selectable={false}
            onClick={(data) => {
              setMenu(data.key)
            }}
            defaultSelectedKeys={'2'}
            items={[
              {
                key: '1',
                label: '서울시',
              },
              {
                key: '2',
                label: '자치구',
              },
              // {
              //   key: '3',
              //   label: '법정동',
              // },
              // {
              //   key: '4',
              //   label: '대규모 아파트 단지',
              // },
            ]}
          />
        </Sider>
        <Layout style={{flex: 1, backgroundColor: '#FFFFFF',  }}>
          {menu === '1' && <Forecast_Si />}
          {menu === '2' && <Forecast_Gu />}
          {menu === '3' && <Forecast_Dong />}
          {menu === '4' && <Forecast_Apt />}
        </Layout>
      </Layout>
      </ConfigProvider>

      <footer className="relative bg-[#be123c] pt-8 pb-6">
        <div className="">
          <div className="flex flex-wrap text-center lg:text-center">
            
          <div className="text-center flex-grow" style={{flexDirection: 'row', backgroundColor:'green', height: '100%'}}>
              </div>
            <div className="flex w-full px-4 justify-center items-center">
                  <ul style={{justifyContent:'center', alignItems:'center', }}>
                      <img src={seoulLogo} style={{width: 150, height: 70, }}/>
                  </ul>
                <ul className="text-sm text-white font-semibold py-1" style={{justifyContent:'center', alignItems:'center', flexDirection: 'row',}}>
                  <li style={{}}>
                    서울특별시 중구 서소문로124, 서울시청 서소문2청사 주택정책지원센터 주택시장정보분석실(우편번호 04514)
                  </li>
                  <li>
                    대표전화 :02-120 또는 02-2133-7834 ©Seoul Metropolitan Gov all rights reserved
                  </li>
                  <div className="text-sm text-white font-semibold py-1">
                    Copyright © {new Date().getFullYear()} 서울특별시 주택정책지원센터 {" "}
                  </div>
                </ul>


            </div>
          </div>
          {/* <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} 서울특별시 주택정책지원센터 {" "}
              </div>
            </div>
          </div> */}
        </div>
      </footer>
    </Space>
    </Space>
  )
}