import React from 'react';
import * as d3 from 'd3';
import {interpolateViridis} from 'd3-scale-chromatic';

function StudentPointsChart(props) {
    let students = [
        {
            "name": "송파구",
            "pointsMath": [70, 74, 80, 85, 90],

            "growthMath": [0, 5.71, 14.29, 21.43, 28.57],
            "growthEnglish": [0, 4.62, 10.77, 16.67, 23.08]
        },
        {
            "name": "강동구",
            "pointsMath": [80, 85, 88, 92, 95],

            "growthMath": [0, 6.25, 10.00, 15.00, 18.75],
            "growthEnglish": [0, 4.00, 8.00, 3.66, 2.94]
        },
        {
            "name": "강남구",
            "pointsMath": [65, 68, 70, 72, 75],

            "growthMath": [0, 4.62, 7.69, 10.77, 15.38],
            "growthEnglish": [0, -2.50, -5.13, -6.67, -2.78]
        },
        {
            "name": "서초구",
            "pointsMath": [90, 92, 95, 97, 99],

            "growthMath": [0, 2.22, 5.56, 7.78, 10.00],
            "growthEnglish": [0, 3.53, 2.27, 2.22, 2.17]
        },
        {
            "name": "중구", "pointsMath": [75, 80, 85, 87, 90],

            "growthMath": [0, 6.67, 13.33, 16.47, 20.00],
            "growthEnglish": [0, 4.29, 11.43, 10.26, 21.43]
        },
        {
            "name": "양천구",
            "pointsMath": [82, 85, 88, 91, 94],

            "growthMath": [0, -3.66, -7.32, -10.99, -14.63],
            "growthEnglish": [0, -3.90, -6.82, -9.64, -13.48]
        },
        {
            "name": "강서구",
            "pointsMath": [72, 75, 78, 81, 84],

            "growthMath": [0, 4.17, 8.33, 11.11, 16.67],
            "growthEnglish": [0, -2.94, -6.85, -9.59, -12.66]
        },
        {
            "name": "광진구",
            "pointsMath": [78, 82, 85, 87, 90],

            "growthMath": [0, 5.13, 9.62, 11.11, 15.38],
            "growthEnglish": [0, 4.11, 7.69, 10.39, 13.95]
        },
        {
            "name": "용산구",
            "pointsMath": [88, 91, 94, 96, 99],

            "growthMath": [0, -3.41, -6.82, -8.33, -11.36],
            "growthEnglish": [0, 3.61, 6.74, 8.99, 11.94]
        }

    ];


    // Define the x-axis and y-axis scales
    const [xMin, xMax, yMin, yMax] = getAxisRange(students);
    const xScale = d3.scaleLinear()
        .domain([xMin, xMax])
        .range([50, 550]);
    const yScale = d3.scaleLinear()
        .domain([yMin, yMax])
        .range([350, 50]);

    function getAxisRange(students) {
        let xMin = Infinity, xMax = -Infinity, yMin = Infinity, yMax = -Infinity;
        students.forEach(student => {
            const {growthMath, growthEnglish} = student;
            const xMinStudent = d3.min(growthEnglish);
            const xMaxStudent = d3.max(growthEnglish);
            const yMinStudent = d3.min(growthMath);
            const yMaxStudent = d3.max(growthMath);
            if (xMinStudent < xMin) xMin = xMinStudent;
            if (xMaxStudent > xMax) xMax = xMaxStudent;
            if (yMinStudent < yMin) yMin = yMinStudent;
            if (yMaxStudent > yMax) yMax = yMaxStudent;
        });
        return [xMin, xMax, yMin, yMax];
    }

    // Define the dots and lines for each student's data points
    const studentData = students.map(student => {
        const {name, pointsMath, growthMath, growthEnglish} = student;

        // Define the color and size of the dots for this student's data points
        const dotColor = interpolateViridis(Math.random()); // use interpolatePastel1 from d3-scale-chromatic library
        const dotSize = d3.scaleLinear()
            .domain([0, pointsMath.length - 1])
            .range([3, 7]);

        // Define the width of the line connecting this student's data points
        const lineWidth = d3.scaleLinear()
            .domain([0, pointsMath.length - 1])
            .range([1, 5]);

        // Create an array of objects representing each data point, with x, y, size, and color properties
        const dataPoints = [];
        for (let i = 0; i < pointsMath.length; i++) {
            const x = xScale(growthEnglish[i]);
            const y = yScale(growthMath[i]);
            const size = dotSize(i);
            const color = dotColor;

            dataPoints.push({x, y, size, color});
        }

        // Create an array of objects representing each line segment connecting adjacent data points
        const lineSegments = [];
        for (let i = 0; i < pointsMath.length - 1; i++) {
            const x1 = xScale(growthEnglish[i]);
            const y1 = yScale(growthMath[i]);
            const x2 = xScale(growthEnglish[i + 1]);
            const y2 = yScale(growthMath[i + 1]);
            const width = lineWidth(i);

            lineSegments.push({x1, y1, x2, y2, width});
        }

        return {name, dataPoints, lineSegments};
    });

    // Render the dots and lines for each student's data points
    const studentDotsAndLines = studentData.flatMap(data => {
        const {name, dataPoints, lineSegments} = data;
        const dotsAndLines = [];

        // Render the dots for this student's data points
        dotsAndLines.push(
            <g key={`${name}-dots`}>
                {dataPoints.map((point, i) => (
                    <circle
                        key={`${name}-dot-${i}`}
                        className="dot"
                        cx={point.x}
                        cy={point.y}
                        r={point.size}
                        fill={point.color}
                        opacity={0.9} // set the opacity of the dots
                    />
                ))}
            </g>
        );

        // Render the lines connecting this student's data points
        dotsAndLines.push(
            <g key={`${name}-lines`}>
                {lineSegments.map((segment, i) => (
                    <line
                        key={`${name}-line-${i}`}
                        className="line"
                        x1={segment.x1}
                        y1={segment.y1}
                        x2={segment.x2}
                        y2={segment.y2}
                        stroke={dataPoints[i].color}
                        strokeWidth={dataPoints[i].size * 2 + 2}
                        opacity={0.2} // set the opacity of the lines
                    />
                ))}
            </g>
        );

        // Add the student's name to the last data point
        const lastDataPoint = dataPoints[dataPoints.length - 1];
        dotsAndLines.push(
            <g key={`${name}-name`}>
                <text
                    x={lastDataPoint.x + 10} // adjust the position of the name text along the x-axis
                    y={lastDataPoint.y + 5} // adjust the position of the name text along the y-axis
                    fontSize={14} // set the font size of the name text
                    fontWeight="bold" // set the font weight of the name text
                    fill={lastDataPoint.color}
                >
                    {name}
                </text>
            </g>
        );

        return dotsAndLines;
    });

// Calculate the x-axis and y-axis ticks
    const xAxisTicks = xScale.ticks(20).map(tick => (
        <g key={tick} transform={`translate(${xScale(tick)}, ${yScale(0)})`}>
            <line x1={0} y1={-5} x2={0} y2={5} stroke="#bbbbbb"/>
            {tick % 10 === 0 ? <text y={20} textAnchor="middle">{`${tick}%`}</text> : null}
            {tick !== 0 ? (
                <>
                    <line x1={0} y1={0} x2={0} y2={-350} stroke={tick % 10 === 0 ? "#bbbbbb" : "#dddddd"} strokeWidth="1" strokeDasharray={tick % 10 === 0 ? "0" : "4"}/>
                    <line x1={0} y1={0} x2={0} y2={350} stroke={tick % 10 === 0 ? "#bbbbbb" : "#dddddd"} strokeWidth="1" strokeDasharray={tick % 10 === 0 ? "0" : "4"}/>
                </>
            ) : null}
        </g>
    ));
    const yAxisTicks = yScale.ticks(20).map(tick => (
        <g key={tick} transform={`translate(${xScale(0)}, ${yScale(tick)})`}>
            <line x1={-5} y1={0} x2={5} y2={0} stroke="#bbbbbb"/>
            {tick % 10 === 0 ? <text x={-20} y={5} textAnchor="end">{`${tick}%`}</text> : null}
            {tick !== 0 ? (
                <>
                    <line x1={0} y1={0} x2={500} y2={0} stroke={tick % 10 === 0 ? "#bbbbbb" : "#dddddd"} strokeWidth="1" strokeDasharray={tick % 10 === 0 ? "0" : "4"}/>
                    <line x1={0} y1={0} x2={-500} y2={0} stroke={tick % 10 === 0 ? "#bbbbbb" : "#dddddd"} strokeWidth="1" strokeDasharray={tick % 10 === 0 ? "0" : "4"}/>
                </>
            ) : null}
        </g>
    ));


    return (
        <svg width="600" height="400">
            {/* Draw the x-axis and y-axis */}
            <g>
                <line x1={50} y1={yScale(0)} x2={550} y2={yScale(0)} stroke="black" strokeWidth="2"/>
                <line x1={xScale(0)} y1={50} x2={xScale(0)} y2={350} stroke="black" strokeWidth="2"/>
                <text x={550} y={yScale(0) + 55} textAnchor="end">매매변동률</text>
                <text x={xScale(0) - 25} y={25} textAnchor="end" transform={`rotate(-90, ${xScale(0) - 25}, 25)`}>전세변동률</text>
                {xAxisTicks}
                {yAxisTicks}
            </g>

            {/* Draw the dots and lines for each student's data points */}
            <g>{studentDotsAndLines}</g>
        </svg>
    );
}

export default StudentPointsChart;
