import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {SVGCategory1, SVGCategory1_construction, SVGCategory2, SVGCategoryAdmin} from "../svg/For1Depth";
import {userState} from "../utils/recoilstate";
import {useRecoilValue} from "recoil";
import {SignOut} from "../utils/SignOut";

const depth1menu = [
    {
        id: 1,
        name: '정비사업 추진현황',
        href: '/work/1',
        svg: <SVGCategory1_construction/>
    },
    {
        id: 2,
        name: '주택시장 동향',
        href: '/work/2',
        svg: <SVGCategory2/>
    },
    {
        id: 0,
        name: '관리자',
        href: '/work/0',
        svg: <SVGCategoryAdmin/>
    },
    // More Works
]


function Depth1List(props) {
    const user = useRecoilValue(userState);
    const [admin, setAdmin] = useState(false)

    useEffect(() => {
        if(user && Object.keys(user).length !== 0 && (user.payload['cognito:groups'] === undefined ||
             user.payload['cognito:groups'].length === 0
        ) ) {
            const signout = SignOut();
            eval(signout())
        }

        if (user && Object.keys(user).length !== 0 && user.payload['cognito:groups'] !== undefined && user.payload['cognito:groups'].includes("admin")) {
            setAdmin(true)
        }
    }, [user])

    useEffect(() => {

    }, [admin])

    return <div className="bg-white">
        <div className="mx-auto max-w-2xl py-4 mb-10 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-8">
                {depth1menu.map((product) => (
                    (product.id === 0 && !admin) ? null : (
                        <Link to={product.href} key={product.id}>
                            <div className="relative">
                                <div
                                    className="absolute inset-x-0 top-0 flex h-40 items-start justify-start overflow-hidden rounded-lg p-4">
                                    <div
                                        aria-hidden="true"
                                        className="absolute inset-x-0 bottom-0 h-40 bg-rose-100"
                                    />
                                    <p className="relative text-xl font-semibold">{product.name}</p>
                                </div>
                                <div className="relative h-40 w-full overflow-hidden rounded-lg">
                                    <div
                                        className="absolute inset-x-0 top-0 flex h-40 items-end justify-end overflow-hidden rounded-lg p-4">
                                        {product.svg}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                ))}
            </div>
        </div>
    </div>;
}


export default Depth1List;