import React from 'react';
import {Route, BrowserRouter as Router, Switch, Redirect,} from "react-router-dom";
import Login from './components/Login';
import Register from './components/Register';
import ConfirmRegister from './components/ConfirmRegister';
import ForecastMonthlyRent from "./components/Forecast/ForecastMonthlyRent";
import PrivateRoute from './components/PrivateRoute';
import Navigation from './views/Navigation';
import ScrollToTop from 'react-router-scroll-top';

import './tailwind.generated.css';

import UploadFile from "./components/UploadFile";
import Home from "./Home";
import Footer from "./components/Footer";
import Depth3Layout from "./layout/Depth3Layout";
import Depth2Layout from "./layout/Depth2Layout";

import {atom, RecoilRoot, useRecoilState} from 'recoil';
import {useScreenWidth} from "./utils/ScrollKeeper";
import {useEffect, useState} from "react";
import { useSetRecoilState } from "recoil";
import {Auth} from "aws-amplify";
import {serverSettingState, userState} from "./utils/recoilstate";
import {useLocation} from "react-router";
import ReactGA from "react-ga4";

const screenWidthState = atom({
    key: 'screenWidthState',
    default: window.innerWidth,
});

/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */
const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    // localhost는 기록하지 않음
    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            if (window.location.href.includes("forecast")) {
                ReactGA.initialize('G-G4ZJQCLKG1');
                setInitialized(true);
            }
        }
    }, []);

    // location 변경 감지시 pageview 이벤트 전송
    useEffect(() => {
        if (initialized) {
            // forecast only GA
            if (window.location.href.includes("forecast")) {
                ReactGA.set({ page: location.pathname });
                ReactGA.send("pageview");
            }
        }
    }, [initialized, location]);


    return null
};

export {screenWidthState};

function UIHandler() {
    useScreenWidth();

    return null
}

function UserInfo() {
    const setUserState = useSetRecoilState (userState);

    useEffect(() => {
        const userFromLocalStorage = localStorage.getItem('user');
        if (userFromLocalStorage) {
            setUserState(JSON.parse(userFromLocalStorage));
        }
    }, [setUserState]);

    return null;
}

function ServerSettings() {
    const [serverSetting, setServerSetting] = useRecoilState(serverSettingState);

    useEffect(() => {
        async function fetchHomepageSettings() {
            try {
                const response = await fetch('https://api.seoulhousinginfo.com/admin/settings');
                const data = await response.json();
                setServerSetting(data);

                console.log('got settings', data)
            } catch (error) {
                console.error('Error fetching homepage settings:', error);
            }
        }

        fetchHomepageSettings();
    }, []);

    // Render your component with the fetched homepage settings
    return null
}


function App() {

    return (
        <>
            <Router>
                <RecoilRoot>
                    <UserInfo/>
                    <RouteChangeTracker />
                    <ServerSettings/>
                    <UIHandler/>
                    <Navigation/>
                    <div className="App">
                        <header className="App-header">
                            <Switch>
                                <Route component={UploadFile} path="/upload"/>
                                {/* client side rendering, 서버는 cloudfront 에서 script 로 처리 함 */}
                                <Route exact path="/" render={() => (
                                    <Redirect to="/work" />
                                )} />
                                <PrivateRoute exact path="/work">
                                    {Home}
                                </PrivateRoute>
                                <PrivateRoute exact path="/work/:workid">
                                    {Depth2Layout}
                                </PrivateRoute>
                                <PrivateRoute exact path="/work/:workid/data/:dataid">
                                    {Depth3Layout}
                                </PrivateRoute>
                                <Route component={ConfirmRegister} path="/confirm-register"/>
                                <Route component={Login} path="/log-in"/>
                                <Route component={Register} path="/register"/>
                                <Route component={ForecastMonthlyRent} path="/forecast"/>
                            </Switch>
                        </header>
                    </div>
                    <Footer/>
                </RecoilRoot>

            </Router>
        </>
    )
}

export default App
