import React, {useState} from "react";
import 'react-base-table/styles.css'
import {Depth3Title} from "../Depth3View";
import S2131 from "../work1/data1/S2131";
import T2121 from "../work1/data1/T2121";
import G2121 from "../work1/data1/G2121";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const [searchCondition, setSearchCondition] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';
    

    const serverData = useSyncData(work, metaKey, dataKey)

    return serverData && (<>

        <Depth3Title title='월간 매물량'/>
        <G2121 data={JSON.parse(serverData.data['2121'])} data2={JSON.parse(serverData.data['2122'])} searchCondition={searchCondition}/>
        <S2131 data={JSON.parse(serverData.data['2121'])['rows']} data2={JSON.parse(serverData.data['2122'])['rows']} onChangeSearchOption={setSearchCondition}/>
        <T2121 data={JSON.parse(serverData.data['2121'])} data2={JSON.parse(serverData.data['2122'])} searchCondition={searchCondition}/>
    </>);
}