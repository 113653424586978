import React, {useEffect, useState} from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomizedYAxisTickRight, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data, data2, searchCondition}) {

    const [filteredData, setFilteredData] = useState([]);
    const [keys, setKeys] = useState({ key1: '', key2: '', key3: '' });

    useEffect(() => {

        let targetData
        if (searchCondition.유형 === '매매') {
            targetData = data['rows']
        } else {
            targetData = data2['rows']
        }

        const convertedData = targetData.map(([year, month, salesInventory, salesVolume, salesRate]) => {

            const key1 = keys.key1
            const key2 = keys.key2
            const key3 = keys.key3

            return {
                year: year, month: month,
                [key1]: salesInventory / 1000,
                [key2]: salesVolume / 1000,
                [key3]: parseFloat(salesRate.replace('%', '')),
            };
        });

        const filteredData = convertedData.filter(({year, month}) => {
            const date = new Date(year, month - 1, 1);
            const startDate = new Date(searchCondition.시작월 + '/01');
            const endDate = new Date(searchCondition.끝월 +  '/01');

            return date >= startDate && date <= endDate;
        });


        setFilteredData(filteredData)

    }, [data, data2, searchCondition, keys]);

    useEffect(() => {
        const key1 = searchCondition.유형 + ' 물량';
        const key2 = searchCondition.유형 + ' 거래량';
        const key3 = searchCondition.유형 + ' 거래율';

        setKeys({ key1, key2, key3 });

    }, [searchCondition]);

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -30, right: -20
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(천 건)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:30, fontSize: 12}}
                    />
                    <YAxis yAxisId="right" orientation="right" tick={<CustomizedYAxisTickRight screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(%)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:10, fontSize: 12}}
                        />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey={keys.key1} yAxisId='left' barSize={10} fill="#82ca9d"  unit=' 천건'/>
                    <Bar dataKey={keys.key2} yAxisId='left' barSize={10} fill="#FD4857"  unit=' 천건'/>
                    <Line yAxisId='right' type="linear" strokeWidth={2} dataKey={keys.key3} stroke="#004D92" unit=' %'/>

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
