import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {CustomizedLegend, CustomizedXAxisTick, formatTooltipValue, toNum} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data}) {

    const sums = [data.rows[1], data.rows[7], data.rows[12]]

    // make proper json for graph
    const graph_data = sums.map(([v1,v2,v3,v4,v5,v6,v7]) => ({
        name: v1, '사업예정':  toNum(v4), '사업추진중':  toNum(v5), '준공구역':  toNum(v6), '해제구역':  toNum(v7),
    }));

    const [screenWidth,] = useRecoilState(screenWidthState);

    const pivotedData = graph_data.reduce((result, item) => {
        Object.keys(item).forEach(key => {
            if (key !== 'name') {
                const newObj = result.find(obj => obj.status === key);
                if (newObj) {
                    newObj[item.name] = item[key];
                } else {
                    result.push({status: key, [item.name]: item[key]});
                }
            }
        });
        return result;
    }, []);

    const tots = pivotedData.map((a) => {
        const vals = Object.values(a)
        return vals.reduce((tot, cur) => tot += (Number(cur) || 0), 0)
    })

    return (<>
        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={5 / 3}>
                <ComposedChart
                    data={pivotedData}
                    margin={{
                        top: 20, right: 0, bottom: 40, left: -10,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey="status" angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={0}/>
                    <YAxis yAxisId="left" orientation="left" tick={false} />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomizedLegend screenWidth={screenWidth}/>}/>
                    <Bar dataKey="도시 및 주거환경정비" yAxisId='left' stackId="a" barSize={80} fill="#4f81bd"/>
                    <Bar dataKey="재정비촉진지구" yAxisId='left'  stackId="a" barSize={20} fill="#c0504d"/>
                    <Bar dataKey="소규모정비사업" yAxisId='left'  stackId="a" barSize={20} fill="#9bbb59"/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
