import React, {  useEffect, useState } from 'react';
import { Checkbox , Popover , notification, Col, Row , Layout, Typography, Select, Table,Radio, Space, DatePicker, ConfigProvider    } from 'antd';
import { cloneDeep} from "lodash";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ko_KR.js';
import 'dayjs/locale/ko' 
import { Bar, Column } from '@ant-design/plots';
import axios from "axios";
import {seoulGu, bubjungDong} from '../utils/guDong.js'
import dongData from './data/dong2.json'
import styled from 'styled-components';
import { AreaMap } from '@ant-design/maps';
import mapTemplate from './data/mapTemplate.json'
const { RangePicker } = DatePicker;

// components
// views
dayjs.locale('ko');
export default function ForeCast_Gu(props) {
      const [list, setList] = useState({
        '구': [],
        '전체': [],
      });
      const [depth, setDepth] = useState(1)
      const [tableList, setTableList] = useState({
        '전체': [],
        '월별': [],
      });
      const [size, setSize] = useState(null);
      const [getbubjeoung, setGetBubjeoung] = useState([]);
      const [filteredInfo, setFilteredInfo] = useState({
        gu: null,
      });
      const [showNoti, contextHolder] = notification.useNotification();
      const [columns, setColumns] = useState([]);
      const [monthlyColumns, setMonthlyColumns] = useState([]);
      const [secondTypeColumns, setSecondTypeColumns] = useState([]);
      const [thirdTypeColumns, setThirdTypeColumns] = useState([]);

      useEffect(() => {
          if (!filteredInfo.startPredictionTime) {
            const startPredictionTime = dayjs().format('YYYYMM');
            const endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
            getTableColumn(startPredictionTime,endPredictionTime)
            changeOption([startPredictionTime, endPredictionTime], 'time')
          } else {
            requireData(filteredInfo);
          }
      },[filteredInfo])

      const mapConfig = (res) => {
        if (!list['지도'] || list['지도']?.length < 1) {
          return
        }
        return {
          map: {
            type: 'mapbox',
            style: 'blank',
            // center: [120.19382669582967, 30.258134],
            zoom: 3,
            pitch: 0,
          },
          source: {
            data: list[res],
            parser: {
              type: 'geojson',
            },
          },
          autoFit: true,
          color: {
            field: 'total',
            scale: {
              type: 'linear',
            },
            value: ['#fcae91', '#fb6a4a', '#de2d26', '#a50f15'],
          },
          meta: {
            total: {
              type: 'linear',
              tickCount: 3,
              range: [0, 1],
         
            },
          },
          style: {
            opacity: 1,
            stroke: '#fff',
            lineWidth: 0.6,
            lineOpacity: 1,
          },
          state: {
            active: true,
          },
          label: {
            visible: true,
            field: 'name',
            style: {
              fill: '#000',
              opacity: 1,
              fontSize: 12,
              stroke: '#fff',
              strokeWidth: 1.5,
              textAllowOverlap: true,
              padding: [8, 8],
            },
          },
          // tooltip: {
          //   items: ['name', `${'total'}`],
          // },
          tooltip: {
            items: ['name', 'total'],
            customContent: (title, items) => {
              if (!items || !items?.length === 0) {
                return ''
              } else {
                  return `
                  <div style="background-color: white;padding: 10px;">
                    <p>name: ${items[0]?.value}</p>
                    <p>total: ${Number(items[1]?.value).toLocaleString()}</p>
                 </div>
                  `;
             }
            },
          },
          zoom: {
            position: 'bottomright',
          },
          legend: {
            position: 'topright',
            title: '단위: 호',
            formatter: (value) => {
              return parseFloat(value).toLocaleString();
            }
          },
        }
      };
    
    
      const content = (
        <div>
          <p>건축연한이 파악되지 않는 임차 예측물량</p>
        </div>
      );
      const requireData = (data) => {
        const headers = {
          'Access-Control-Allow-Origin': '*',
        }
        if (!data.gu) {
          const url = `https://api.seoulhousinginfo.com/forecast/seoul-group-by-gu?startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}`
          axios.get(url,{},{headers})
          .then(res => {
            getMonthlyTable(res.data, 1);
          })
          .catch(error => {
            console.log(error);
          })
          return;
        } else if (!data.selectType) {
          const url = `https://api.seoulhousinginfo.com/forecast/seoul-group-by-gu-detail?startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}&gu=${data.gu}&type=houseType`
          axios.get(url,{},{headers})
          .then(res => {
            getMonthlyTable(res.data, 2);
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const type = data?.selectType === '건축연한' ? 'yearType' : 'areaType'

          const url = `https://api.seoulhousinginfo.com/forecast/seoul-group-by-gu-detail?startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}&gu=${data.gu}&type=${type}`
          axios.get(url,{},{headers})
          .then(res => {
            getMonthlyTable(res.data, 3);
          })
          .catch(error => {
            console.log(error);
          })            
        }
        return 
      }
      //데이터를 테이블에 맞게 변환
      const getMonthlyTable = (res, depth) => {
          //depth Check
        //그래프
        const obj = {};
        obj['구'] = [];
        obj['전체'] = [];
        //테이블
        const objTable = {};
        objTable['전체'] = [];
        objTable['구'] = [];
        if (depth === 1) {
            obj['지도'] = cloneDeep(mapTemplate);
            const lease = res?.lease;
            const monty = res?.monty;
            const totalKeys = Object.keys(lease).filter(key => monty.hasOwnProperty(key));
            let seoul = {
              key: '서울시',
              name: '서울시',
              '전세' : 0,
              '월세' : 0,
              '소계':  0,
            };
            for (let i = 0; i < totalKeys.length; i++) {
              const gu = totalKeys[i];
              objTable['전체'].push({
                key: gu,
                name: gu,
                '전세' : Number(lease[gu]),
                '월세' : Number(monty[gu]),
                '소계':  Number(lease[gu] + monty[gu]),
              });
              seoul = {
                key: '서울시',
                name: '서울시',
                '전세' : seoul['전세'] + Number(lease[gu]),
                '월세' : seoul['월세'] + Number(monty[gu]),
                '소계':  seoul['소계'] + Number(lease[gu] + monty[gu]),
              }
              obj['전체'].push({
                key: gu,
                name: gu,
                '전/월세' : '전세',
                '거래수':  Number(lease[gu]),
              });
              obj['전체'].push({
                key: gu,
                name: gu,
                '전/월세' : '월세',
                '거래수':  Number(monty[gu]),
              });
              const foundGu = obj['지도'].features.findIndex(e => e['properties'].name  == gu);
              obj['지도'].features[foundGu]['properties']['total'] =  Number(lease[gu] + monty[gu]);
            }
            objTable['전체'].sort((a, b) => a['name'].localeCompare(b['name']));
            objTable['전체'].push(seoul)
            obj['전체'].sort((a, b) => a['name'].localeCompare(b['name']));
        } else if (depth === 2) {
            // res.sort((a, b) => a['구'] - b['구']);
            objTable['아파트'] = [];
            objTable['연립다세대'] = [];
            objTable['단독다가구'] = [];
            obj['아파트'] = [];
            obj['연립다세대'] = [];
            obj['단독다가구'] = [];
            const gu = [{
                'code': filteredInfo?.gu,
                '소계': 0,
                key: filteredInfo?.gu,
              },{
                  'code': '전세',
                  '소계': 0,
                  key: '전세',
              },{
                'code': '월세',
                '소계': 0,
                key: '월세',
            }]
            objTable['아파트'] = cloneDeep(gu)
            objTable['연립다세대'] = cloneDeep(gu)
            objTable['단독다가구'] = cloneDeep(gu)
            // 월 데이터가 아얘 없을떄를 대비해서 초기화
            const endMonth = dayjs(filteredInfo?.endPredictionTime).diff(dayjs(filteredInfo?.startPredictionTime), 'month')
            for (let k = 0; k < endMonth + 1; k++) {
              const month = dayjs(filteredInfo?.startPredictionTime).add(k,'month').format('MM월');
              objTable['아파트'][0][month] = 0
              objTable['연립다세대'][0][month] = 0
              objTable['단독다가구'][0][month] = 0
              objTable['아파트'][1][month] = 0
              objTable['연립다세대'][1][month] = 0
              objTable['단독다가구'][1][month] = 0
              objTable['아파트'][2][month] = 0
              objTable['연립다세대'][2][month] = 0
              objTable['단독다가구'][2][month] = 0
            }

            const aptData = res['아파트_data'];
            const multiHouseData = res['연립다세대_data'];
            const singleHouseData = res['단독다가구_data'];
            const totalKeys = Object.keys(aptData);
            for (let i = 0; i < totalKeys.length; i++) {
              const month = totalKeys[i];
              const checkMonth = dayjs(totalKeys[i]).format('MM월');
              const atpTotal = Number(aptData[month]['월세']) + Number(aptData[month]['전세'])
              const multiHouseTotal = Number(multiHouseData[month]['월세']) + Number(multiHouseData[month]['전세'])
              const singleHouseTotal = Number(singleHouseData[month]['월세']) + Number(singleHouseData[month]['전세'])
              //막대
              obj['아파트'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(aptData[month]['전세']),
                '예측시기': checkMonth,
                '전/월세': '전세'
              })
              obj['아파트'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(aptData[month]['월세']),
                '예측시기': checkMonth,
                '전/월세': '월세'
              })
              obj['연립다세대'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(multiHouseData[month]['전세']),
                '예측시기': checkMonth,
                '전/월세': '전세'
              })
              obj['연립다세대'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(multiHouseData[month]['월세']),
                '예측시기': checkMonth,
                '전/월세': '월세'
              })
              obj['단독다가구'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(singleHouseData[month]['전세']),
                '예측시기': checkMonth,
                '전/월세': '전세'
              })
              obj['단독다가구'].push({
                'code': filteredInfo?.gu,
                '거래수': Number(singleHouseData[month]['월세']),
                '예측시기': checkMonth,
                '전/월세': '월세'
              })
              //테이블
              //아파트
              objTable['아파트'][0][checkMonth] = Number(objTable['아파트'][0][checkMonth]) ? Number(objTable['아파트'][0][checkMonth]) + atpTotal : atpTotal;
              objTable['아파트'][0]['소계'] = Number(objTable['아파트'][0]['소계']) ? Number(objTable['아파트'][0]['소계']) + atpTotal : atpTotal;   
              objTable['아파트'][1][checkMonth] = Number(objTable['아파트'][1][checkMonth]) ? Number(objTable['아파트'][1][checkMonth]) + aptData[month]['전세'] : aptData[month]['전세'];
              objTable['아파트'][1]['소계'] = Number(objTable['아파트'][1]['소계']) ? Number(objTable['아파트'][1]['소계']) + aptData[month]['전세'] : aptData[month]['전세'];   
              objTable['아파트'][2][checkMonth] = Number(objTable['아파트'][2][checkMonth]) ? Number(objTable['아파트'][2][checkMonth]) + aptData[month]['월세'] : aptData[month]['월세'];
              objTable['아파트'][2]['소계'] = Number(objTable['아파트'][2]['소계']) ? Number(objTable['아파트'][2]['소계']) + aptData[month]['월세'] : aptData[month]['월세'];   
              //연립
              objTable['연립다세대'][0][checkMonth] = Number(objTable['연립다세대'][0][checkMonth]) ? Number(objTable['연립다세대'][0][checkMonth]) + multiHouseTotal : multiHouseTotal;
              objTable['연립다세대'][0]['소계'] = Number(objTable['연립다세대'][0]['소계']) ? Number(objTable['연립다세대'][0]['소계']) + multiHouseTotal : multiHouseTotal;   
              objTable['연립다세대'][1][checkMonth] = Number(objTable['연립다세대'][1][checkMonth]) ? Number(objTable['연립다세대'][1][checkMonth]) + multiHouseData[month]['전세'] : multiHouseData[month]['전세'];
              objTable['연립다세대'][1]['소계'] = Number(objTable['연립다세대'][1]['소계']) ? Number(objTable['연립다세대'][1]['소계']) + multiHouseData[month]['전세'] : multiHouseData[month]['전세'];   
              objTable['연립다세대'][2][checkMonth] = Number(objTable['연립다세대'][2][checkMonth]) ? Number(objTable['연립다세대'][2][checkMonth]) + multiHouseData[month]['월세'] : multiHouseData[month]['월세'];
              objTable['연립다세대'][2]['소계'] = Number(objTable['연립다세대'][2]['소계']) ? Number(objTable['연립다세대'][2]['소계']) + multiHouseData[month]['월세'] : multiHouseData[month]['월세'];   
              //단독
              objTable['단독다가구'][0][checkMonth] = Number(objTable['단독다가구'][0][checkMonth]) ? Number(objTable['단독다가구'][0][checkMonth]) + singleHouseTotal : singleHouseTotal;
              objTable['단독다가구'][0]['소계'] = Number(objTable['단독다가구'][0]['소계']) ? Number(objTable['단독다가구'][0]['소계']) + singleHouseTotal : singleHouseTotal;   
              objTable['단독다가구'][1][checkMonth] = Number(objTable['단독다가구'][1][checkMonth]) ? Number(objTable['단독다가구'][1][checkMonth]) + singleHouseData[month]['전세'] : singleHouseData[month]['전세'];
              objTable['단독다가구'][1]['소계'] = Number(objTable['단독다가구'][1]['소계']) ? Number(objTable['단독다가구'][1]['소계']) + singleHouseData[month]['전세'] : singleHouseData[month]['전세'];   
              objTable['단독다가구'][2][checkMonth] = Number(objTable['단독다가구'][2][checkMonth]) ? Number(objTable['단독다가구'][2][checkMonth]) + singleHouseData[month]['월세'] : singleHouseData[month]['월세'];
              objTable['단독다가구'][2]['소계'] = Number(objTable['단독다가구'][2]['소계']) ? Number(objTable['단독다가구'][2]['소계']) + singleHouseData[month]['월세'] : multiHouseData[month]['월세'];   

            }
        } else if (depth === 3) {
          if(filteredInfo.selectType === '면적') {
              obj['아파트월세'] = [];
              obj['연립다세대월세'] = [];
              obj['단독다가구월세'] = [];
              obj['아파트전세'] = [];
              obj['연립다세대전세'] = [];
              obj['단독다가구전세'] = [];
              const gu = [{
                'code': filteredInfo?.gu,
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: filteredInfo?.gu,
              },
              {
                'code': '전세',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '전세',
              },
              {
                'code': '40㎡미만',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '전세40㎡미만',
              },
              {
                'code': '40-85㎡',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '전세40-85㎡',
              },
              {
                'code': '85㎡이상',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '전세85㎡이상',
              },
              {
                'code': '월세',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '월세',
              },
              {
                'code': '40㎡미만',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '월세40㎡미만',
              },
              {
                'code': '40-85㎡',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '월세40-85㎡',
              },
              {
                'code': '85㎡이상',
                '아파트소계': 0,
                '연립다세대소계': 0,
                '단독다가구소계': 0,
                '계': 0,
                key: '월세85㎡이상',
              },
            ]
            objTable['면적별'] = cloneDeep(gu)
            const listData = cloneDeep(res['아파트_data']);
            //월 값
            const listKey = Object.keys(listData);
            for (let k = 0; k < listKey?.length; k++) {
              const aptJunData = cloneDeep(res['아파트_data'][listKey[k]]['전세']);
              const aptWallData = cloneDeep(res['아파트_data'][listKey[k]]['월세']);
              const multiJunData = cloneDeep(res['연립다세대_data'][listKey[k]]['전세']);
              const multiWallData = cloneDeep(res['연립다세대_data'][listKey[k]]['월세']);
              const singleJunData = cloneDeep(res['단독다가구_data'][listKey[k]]['전세']);
              const singleWallData = cloneDeep(res['단독다가구_data'][listKey[k]]['월세']);
              const aptMonthKey = '아파트' + dayjs(listKey[k]).format('MM월');
              const multiMonthKey = '연립다세대' + dayjs(listKey[k]).format('MM월');
              const singleMonthKey = '단독다가구' + dayjs(listKey[k]).format('MM월');
              for(let t = 0; t < objTable['면적별']?.length; t++) {
                const checkKey = objTable['면적별'][t].key;
                if (checkKey === filteredInfo?.gu) {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['total'] + aptWallData['total']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['total'] + multiWallData['total']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['total'] + singleWallData['total']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['total'] + aptWallData['total']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['total'] + multiWallData['total']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['total'] + singleWallData['total']
                }  else if (checkKey === '전세') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['total']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['total']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['total']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['total']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['total']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['total']
                } else if (checkKey === '전세40㎡미만') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['0040']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['0040']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['0040']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['0040']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['0040']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['0040']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40㎡미만'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40㎡미만'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40㎡미만'
                  })

                } else if (checkKey === '전세40-85㎡') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['4085']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['4085']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['4085']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['4085']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['4085']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['4085']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40-85㎡'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40-85㎡'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세40-85㎡'
                  })
                } else if (checkKey === '전세85㎡이상') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['8500']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['8500']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['8500']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['8500']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['8500']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['8500']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세85㎡이상'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세85㎡이상'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '전세85㎡이상'
                  })
                } else if (checkKey === '월세') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptWallData['total']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiWallData['total']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleWallData['total']

                  objTable['면적별'][t][aptMonthKey] = aptWallData['total']
                  objTable['면적별'][t][multiMonthKey] = multiWallData['total']
                  objTable['면적별'][t][singleMonthKey] = singleWallData['total']
                } else if (checkKey === '월세40㎡미만') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['0040']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['0040']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['0040']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['0040']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['0040']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['0040']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40㎡미만'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40㎡미만'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40㎡미만'
                  })
                
                } else if (checkKey === '월세40-85㎡') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['4085']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['4085']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['4085']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['4085']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['4085']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['4085']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40-85㎡'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40-85㎡'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['4085'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세40-85㎡'
                  })
                } else if (checkKey === '월세85㎡이상') {
                  objTable['면적별'][t]['아파트소계'] = objTable['면적별'][t]['아파트소계'] + aptJunData['8500']
                  objTable['면적별'][t]['연립다세대소계'] = objTable['면적별'][t]['연립다세대소계'] + multiJunData['8500']
                  objTable['면적별'][t]['단독다가구소계'] = objTable['면적별'][t]['단독다가구소계'] + singleJunData['8500']

                  objTable['면적별'][t][aptMonthKey] = aptJunData['8500']
                  objTable['면적별'][t][multiMonthKey] = multiJunData['8500']
                  objTable['면적별'][t][singleMonthKey] = singleJunData['8500']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세85㎡이상'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세85㎡이상'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['8500'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축면적": '월세85㎡이상'
                  })
                }
                objTable['면적별'][t]['계'] = objTable['면적별'][t]['계'] + objTable['면적별'][t]['연립다세대소계'] + objTable['면적별'][t]['단독다가구소계']

              }
            }

          } else {
            obj['아파트월세'] = [];
            obj['연립다세대월세'] = [];
            obj['단독다가구월세'] = [];
            obj['아파트전세'] = [];
            obj['연립다세대전세'] = [];
            obj['단독다가구전세'] = [];
            const gu = [{
              'code': filteredInfo?.gu,
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: filteredInfo?.gu,
            },
            {
              'code': '전세',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세',
            },
            {
              'code': '10년미만',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세10년미만',
            },
            {
              'code': '10-20년',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세10-20년미만',
            },
            {
              'code': '20-30년',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세20-30년미만',
            },
            {
              'code': '30년이상',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세30년이상',
            },
            {
              'code': '확인불가',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '전세확인불가',
            },
            {
              'code': '월세',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세',
            },
            {
              'code': '10년미만',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세10년미만',
            },
            {
              'code': '10-20년',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세10-20년미만',
            },
            {
              'code': '20-30년',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세20-30년미만',
            },
            {
              'code': '30년이상',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세30년이상',
            },
            {
              'code': '확인불가',
              '아파트소계': 0,
              '연립다세대소계': 0,
              '단독다가구소계': 0,
              '계': 0,
              key: '월세확인불가',
            },
          ]
            objTable['연한별'] = cloneDeep(gu)
            const aptData = cloneDeep(res['아파트_data']);
            //월 값
            const listKey = Object.keys(aptData);

          
            //차트 
            // 월 초기화
            for (let k = 0; k < listKey?.length; k++) {
              const aptJunData = cloneDeep(res['아파트_data'][listKey[k]]['전세']);
              const aptWallData = cloneDeep(res['아파트_data'][listKey[k]]['월세']);
              const multiJunData = cloneDeep(res['연립다세대_data'][listKey[k]]['전세']);
              const multiWallData = cloneDeep(res['연립다세대_data'][listKey[k]]['월세']);
              const singleJunData = cloneDeep(res['단독다가구_data'][listKey[k]]['전세']);
              const singleWallData = cloneDeep(res['단독다가구_data'][listKey[k]]['월세']);
              const aptMonthKey = '아파트' + dayjs(listKey[k]).format('MM월');
              const multiMonthKey = '연립다세대' + dayjs(listKey[k]).format('MM월');
              const singleMonthKey = '단독다가구' + dayjs(listKey[k]).format('MM월');

              for(let t = 0; t < objTable['연한별']?.length; t++) {
              
                const checkKey = objTable['연한별'][t].key;
                if (checkKey === filteredInfo?.gu) {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['total'] + aptWallData['total']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['total'] + multiWallData['total']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['total'] + singleWallData['total']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['total'] + aptWallData['total']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['total'] + multiWallData['total']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['total'] + singleWallData['total']
                } else if (checkKey === '전세') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['total']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['total']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['total']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['total']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['total']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['total']
                } else if (checkKey === '전세10년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['0010']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['0010']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['0010']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['0010']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['0010']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['0010']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10년미만'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10년미만'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10년미만'
                  })
                } else if (checkKey === '전세10-20년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['1020']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['1020']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['1020']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['1020']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['1020']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['1020']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10-20년미만'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10-20년미만'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세10-20년미만'
                  })
                } else if (checkKey === '전세20-30년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['2030']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['2030']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['2030']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['2030']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['2030']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['2030']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세20-30년미만'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세20-30년미만'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세20-30년미만'
                  })
                } else if (checkKey === '전세30년이상') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['3000']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['3000']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['3000']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['3000']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['3000']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['3000']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세30년이상'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세30년이상'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세30년이상'
                  })
                } else if (checkKey === '전세확인불가') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptJunData['9999']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiJunData['9999']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleJunData['9999']

                  objTable['연한별'][t][aptMonthKey] = aptJunData['9999']
                  objTable['연한별'][t][multiMonthKey] = multiJunData['9999']
                  objTable['연한별'][t][singleMonthKey] = singleJunData['9999']

                  obj['아파트전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptJunData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세확인불가'
                  })
                  obj['연립다세대전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiJunData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세확인불가'
                  })
                  obj['단독다가구전세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleJunData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '전세확인불가'
                  })
                } else if (checkKey === '월세') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['total']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['total']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['total']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['total']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['total']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['total']
                } else if (checkKey === '월세10년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['0010']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['0010']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['0010']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['0010']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['0010']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['0010']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptWallData['total'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세10년미만'
                  })
                } else if (checkKey === '월세10-20년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['1020']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['1020']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['1020']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['1020']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['1020']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['1020']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptWallData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세10-20년미만'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiWallData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세10-20년미만'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleWallData['1020'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세10-20년미만'
                  })
                } else if (checkKey === '월세20-30년미만') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['2030']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['2030']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['2030']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['2030']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['2030']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['2030']
                  
                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptWallData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세20-30년미만'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiWallData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세20-30년미만'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleWallData['2030'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세20-30년미만'
                  })
                } else if (checkKey === '월세30년이상') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['3000']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['3000']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['3000']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['3000']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['3000']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['3000']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptWallData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세30년이상'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiWallData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세30년이상'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleWallData['3000'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세30년이상'
                  })
                } else if (checkKey === '월세확인불가') {
                  objTable['연한별'][t]['아파트소계'] = objTable['연한별'][t]['아파트소계'] + aptWallData['9999']
                  objTable['연한별'][t]['연립다세대소계'] = objTable['연한별'][t]['연립다세대소계'] + multiWallData['9999']
                  objTable['연한별'][t]['단독다가구소계'] = objTable['연한별'][t]['단독다가구소계'] + singleWallData['9999']

                  objTable['연한별'][t][aptMonthKey] = aptWallData['9999']
                  objTable['연한별'][t][multiMonthKey] = multiWallData['9999']
                  objTable['연한별'][t][singleMonthKey] = singleWallData['9999']

                  obj['아파트월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': aptWallData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세확인불가'
                  })
                  obj['연립다세대월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': multiWallData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세확인불가'
                  })
                  obj['단독다가구월세'].push({
                    'code': filteredInfo.gu,
                    '거래수': singleWallData['9999'],
                    '예측시기': dayjs(listKey[k]).format('MM월'),
                    "건축연한": '월세확인불가'
                  })
                }
                objTable['연한별'][t]['계'] = objTable['연한별'][t]['계'] + objTable['연한별'][t]['연립다세대소계'] + objTable['연한별'][t]['단독다가구소계']
              }
            }
          }

        }
        setDepth(depth)
        setTableList(cloneDeep(objTable))
        setList(cloneDeep(obj))
      }
      //6개월 초과선택시 알림
      const openNotificationWithIcon = (type, des) => {
        showNoti[type]({
          message: type === 'success' ? '업로드에 성공하였습니다.' : '선택에 실패하였습니다.',
          description:
          type === 'success' ? des + '업로드 완료' : des,
          placement: 'top',
        });
      };
      const changeOption = (data, dataIndex) => {
        const res = cloneDeep(filteredInfo);
        if (data !== '0' && data !== '') {
            if (dataIndex === 'gu') {
                res.gu = data;
                if (res.startPredictionTime === undefined ||res.startPredictionTime === null){
                  res.startPredictionTime = dayjs().format('YYYYMM');
                  res.endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
                  getTableColumn(res.startPredictionTime,res.endPredictionTime)
                  getMonthTable(res.startPredictionTime,res.endPredictionTime)
                  getGuTable(res.startPredictionTime,res.endPredictionTime)
                  getTermColumn(res.startPredictionTime,res.endPredictionTime, res.selectType)
                } else {
                  getTableColumn(res.startPredictionTime,res.endPredictionTime)
                  getMonthTable(res.startPredictionTime,res.endPredictionTime)
                  getGuTable(res.startPredictionTime,res.endPredictionTime)
                  getTermColumn(res.startPredictionTime,res.endPredictionTime, res.selectType)
                }
            } else if (dataIndex === 'selectType') {
              if (size === data.target.value) {
                // 이미 선택된 항목을 다시 선택하면 선택 해제
                setSize(null);
                res.selectType = null;
              } else {
                setSize(data.target.value)
                res.selectType = data.target.value;
                getTermColumn(res.startPredictionTime,res.endPredictionTime, data.target.value)
                // if (data.target.value === '면적') {
                //   if (res.startPredictionTime === undefined || res.startPredictionTime === null){
                //     res.startPredictionTime = dayjs().format('YYYYMM');
                //     res.endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
                //     getGuTable(res.startPredictionTime,res.endPredictionTime)
                //   } else {
                //     res.startPredictionTime = filteredInfo.startPredictionTime;
                //     res.endPredictionTime = filteredInfo.endPredictionTime;
                //   }
                // }
              }
            } else if (dataIndex === 'time') {
                res.startPredictionTime = data[0];
                res.endPredictionTime = data[1];
            }
          }

        setFilteredInfo(cloneDeep(res))
      };
      const config = (res) => {
        return {
          data: list[res],
          isStack: true,
          xField: 'name',
          yField: '거래수',
          xAxis: {
            label: {
              formatter: (text) => {
                return text.split('').join('\n'); // 각 글자 사이에 줄바꿈 문자 삽입
            },
            style: {
              fontSize: 13
            }
            }
          },
          yAxis: {
            label: {
              formatter: (text) => {
                return text.toLocaleString();
              },
              style: {
                fontSize: 13
              }
            }
          },
          seriesField: '전/월세',
          label: {
            position: 'middle',
            content: (item) => {
              return !isNaN(item['거래수']) ? item['거래수'].toLocaleString() : item;
            },
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
          legend: {
            title: {
              text: '단위: 호',
              spacing: 8,
            },
            itemName: {
              style: {
                backgroundColor:'red',
                fontSize: 13  // Adjust the font size as needed
              }
            }
          },
          tooltip: {
            formatter: (datum) => {
              return {
                name: datum.name || '', 
                value: datum['거래수'].toLocaleString() // 숫자를 쉼표로 구분된 문자열로 변환
              };
            }
          }
        }
      };
      const config2 = (res) => {
        const type = filteredInfo.selectType === '면적' ? false : true
        return {
          data: list[res] ? list[res] : [],
          isStack: true,
          xField: '예측시기',
          xAxis: {
            label: {
              style: {
                fontSize: 13
              }
            }
          },
          yField: '거래수',
          seriesField: type === true ? '전/월세' : '면적',
          label: {
            position: 'middle',
            content: (item) => {
              return !isNaN(item['거래수']) ? item['거래수'].toLocaleString() : item;
            },
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
          legend: {
            title: {
              text: '단위: 호',
              spacing: 8,
            },
            itemName: {
              style: {
                fontSize: 13  // Adjust the font size as needed
              }
            }
          },
        }
      };
      const config3 = (res) => {
        return {
          data: list[res],
          isStack: true,
          xField: '거래수',
          yField: filteredInfo.selectType === "건축연한" ? "건축연한" : '건축면적',
          yAxis: {
            label: {
              formatter: (text) => {
                return text.substr(2)
              }
            }
          },
          seriesField: '예측시기',
          label: {
            position: 'middle',
            content: (item) => {
              return !isNaN(item['거래수']) ? item['거래수'].toLocaleString() : item;
            },
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
          legend: {
            title: {
              text: '단위: 호',
              spacing: 8,
            },
            itemName: {
              style: {
                backgroundColor:'red',
                fontSize: 13  // Adjust the font size as needed
              }
            }
          }
        }
      };
      const getMonthTable = (start, end) => {
        const startString = start.toString()
        const endString = end.toString()

        const endMonth = dayjs(endString).diff(dayjs(startString), 'month')
        const column = [    
          {
            title: '구분',
            dataIndex: 'code',
            key: 'code',
            onCell: (text, record) => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },

          },
        ]
        for (let i = 0; i < endMonth + 1; i++) {
            const month = dayjs(start).add(i,'month').format('MM월');
            column.push(
            {
                title: month.toString(),
                align: "center",
                dataIndex: '전세' + month.toString(),
                key:   month.toString(),
                onCell: (text, record) => {
                    const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
                    return {
                    ['style']: { borderColor: '#D4D4D6'},
                    className: 'example-class-in-td',
                    };
                },

             },
            )
            if (i === endMonth){
                column.push(
                  {
                    title: '소계',
                    dataIndex:  '전세' +'소계',
                    key: '아파트' + '전세' + '소계',
                    onCell: (text, record) => {
                      // const checkCase = text['name'].length > 50 ? 'red' : 'green;
                      return {
                        ['style']: {background: '#FFEAE6'},
                        className: 'example-class-in-td',
                      };
                    },
                  },
                  )
              }
        }
        
        setMonthlyColumns(column)
      }
      const getGuTable = (start, end) => {
        const endMonth = dayjs(end).diff(dayjs(start), 'month')

        const columnChildren = [    
          {
            title: '구분',
            align: "center",
            dataIndex: 'code',
            key: 'code',
            onCell: (text, record) => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => <Space  style={{fontSize: 14, fontWeight: 700 }}>{text}</Space>,
          },
        ]
        for (let i = 0; i < endMonth + 1; i++) {
            const month = dayjs(start).add(i,'month').format('MM월');
            columnChildren.push(
            {
                title: month.toString(),
                align: "center",
                dataIndex: month.toString(),
                key: month.toString(),
                onCell: (text, record) => {
                  const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
                  return {
                    ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                    className: 'wallse-class-in-td',
                  };
                },
                render: (text) => {
                  const t = text == 0 || text == undefined || text == null? '-' : text
                  return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                },               
              },
            )
            if (i === endMonth){
                columnChildren.push(
                  {
                    title: '계',
                    align: "center",
                    dataIndex:  '소계',
                    key: '소계',
                    onCell: (text, record) => {
                      // const checkCase = record === 25 ? '#FFF4F0' : '#FFFFFF';
                      return {
                        ['style']: {backgroundColor: '#FFF4F0', borderColor: '#D4D4D6'},
                        className: 'wallse-class-in-td',
                      };
                    },
                    render: (text) => {
                      const t = text == 0 || text == undefined || text == null? '-' : text
                      return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                    },                  
                  },

                  )
              }
        }
        setSecondTypeColumns(columnChildren)
      }
      // 전월세 column 생성 
      const getTableColumn = (start, end) => {
        const column = [    
          {
            title: '구분',
            align: "center",
            dataIndex: 'name',
            key: 'key',
            onCell: (text, record) => {
              // const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              {
                  return text === '확인불가' ? 
                  <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                  :
                    <Space style={{fontSize: 14, fontWeight: 700}}>{text}</Space>
                  
              }
            }
          },
        ]
        column.push(
          {
            title: '전세',
            dataIndex: '전세',
            key: '전세',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 25 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },             
          },
        )
        column.push(
          {
            title: '월세',
            dataIndex: '월세',
            key: '월세',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 25 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                className: 'wallse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },          
          },
        )
        column.push(
          {
            title: '계',
            dataIndex: '소계',
            key: '소계',
            align: "center",
            onCell: (text, record) => {
              // const checkCase = text['name'].length > 50 ? 'red' : 'green;
              return {
                ['style']: {backgroundColor:'#FFF4F0', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },         
          },
          )
        setColumns(column)
      }
      //연한별
      const getTermColumn = (start, end, type) => {
        //아파트, 연립, 다가구별
        //월세, 전세별
        //월별
        //column필요
        const endMonth = dayjs(end).diff(dayjs(start), 'month')
        const apt = []
        const row = []
        const single = []
        //구,전월세,연한별
        const column = [    
          {
            title: '구분',
            align: "center",
            dataIndex: 'code',
            key: 'key',
            onHeaderCell: () => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
              };
            },
            onCell: (text, record) => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                className: 'example-class-in-td',
              };
            },
            render: (text) => <Space  style={{fontSize: 14, fontWeight: 700 }}>{text}</Space>,
          },
        ]
        for (let i = 0; i < endMonth + 1; i++) {
            const month = dayjs(start).add(i,'month').format('MM월');
            apt.push(
            {
                title: month.toString(),
                align: "center",
                dataIndex: '아파트' + month.toString(),
                key: '아파트' + month.toString(),
                onHeaderCell: () => {
                  return {
                    ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                  };
                },
                onCell: (text, record) => {
                  let checkCase = '' 
                  if (record === 0) {
                    checkCase = '#FFCCC7'
                  } else if (record === 1) {
                    checkCase = '#EB5C7A'
                  } else if (record === 5) {
                    if (type === '면적') {
                      checkCase = '#067387'
                    } else {
                      checkCase = '#FFFFFF'
                    }
                  } else if (record === 7) {
                    if (type === '면적') {
                      checkCase = '#FFFFFF'
                    } else {
                      checkCase = '#067387'
                    }                  
                  } else {
                    checkCase = '#FFFFFF'
                  }                  return {
                    ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                    className: 'junse-class-in-td',
                  };
                },
                render: (text) => {
                  const t = text == 0 || text == undefined || text == null? '-' : text
                  return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                },    
              },
            )
            row.push(
            {
                title: month.toString(),
                align: "center",
                dataIndex: '연립다세대' + month.toString(),
                key: '연립다세대' + month.toString(),
                onHeaderCell: () => {
                  return {
                    ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                  };
                },
                onCell: (text, record) => {
                  let checkCase = '' 
                  if (record === 0) {
                    checkCase = '#FFCCC7'
                  } else if (record === 1) {
                    checkCase = '#EB5C7A'
                  } else if (record === 5) {
                    if (type === '면적') {
                      checkCase = '#067387'
                    } else {
                      checkCase = '#FFFFFF'
                    }
                  } else if (record === 7) {
                    if (type === '면적') {
                      checkCase = '#FFFFFF'
                    } else {
                      checkCase = '#067387'
                    }                  
                  } else {
                    checkCase = '#FFFFFF'
                  }                  return {
                    ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                    className: 'junse-class-in-td',
                  };
                },
                render: (text) => {
                  const t = text == 0 || text == undefined || text == null? '-' : text
                  return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                },    
                },
            )
            single.push(
            {
                title: month.toString(),
                align: "center",
                dataIndex: '단독다가구' + month.toString(),
                key: '단독다가구' + month.toString(),
                onHeaderCell: () => {
                  return {
                    ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                  };
                },
                onCell: (text, record) => {
                  let checkCase = '' 
                  if (record === 0) {
                    checkCase = '#FFCCC7'
                  } else if (record === 1) {
                    checkCase = '#EB5C7A'
                  } else if (record === 5) {
                    if (type === '면적') {
                      checkCase = '#067387'
                    } else {
                      checkCase = '#FFFFFF'
                    }
                  } else if (record === 7) {
                    if (type === '면적') {
                      checkCase = '#FFFFFF'
                    } else {
                      checkCase = '#067387'
                    }                  
                  } else {
                    checkCase = '#FFFFFF'
                  }
                  return {
                    ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                    className: 'junse-class-in-td',
                  };
                },
                render: (text) => {
                  const t = text == 0 || text == undefined || text == null? '-' : text
                  return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                },    
                },
            )
            if (i === endMonth){
                apt.push(
                  {
                    title: '소계',
                    align: "center",
                    dataIndex:  '아파트' + '소계',
                    key: '아파트' + '소계',
                    onHeaderCell: () => {
                      return {
                        ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                      };
                    },
                    onCell: (text, record) => {
                      let checkCase = '' 
                      if (record === 0) {
                        checkCase = '#FFCCC7'
                      } else if (record === 1) {
                        checkCase = '#EB5C7A'
                      } else if (record === 5) {
                        if (type === '면적') {
                          checkCase = '#067387'
                        } else {
                          checkCase = '#FFF4F0'
                        }
                      } else if (record === 7) {
                        if (type === '면적') {
                          checkCase = '#FFF4F0'
                        } else {
                          checkCase = '#067387'
                        }                  
                      } else {
                        checkCase = '#FFF4F0'
                      }
                      return {
                        ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                        className: 'junse-class-in-td',
                      };
                    },
                    render: (text) => {
                      const t = text == 0 || text == undefined || text == null? '-' : text
                      return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                    },    
                  },
                )
                row.push(
                  {
                    title: '소계',
                    align: "center",
                    dataIndex:  '연립다세대' + '소계',
                    key: '연립다세대' + '소계',
                    onHeaderCell: () => {
                      return {
                        ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                      };
                    },
                    onCell: (text, record) => {
                      let checkCase = '' 
                      if (record === 0) {
                        checkCase = '#FFCCC7'
                      } else if (record === 1) {
                        checkCase = '#EB5C7A'
                      } else if (record === 5) {
                        if (type === '면적') {
                          checkCase = '#067387'
                        } else {
                          checkCase = '#FFF4F0'
                        }
                      } else if (record === 7) {
                        if (type === '면적') {
                          checkCase = '#FFF4F0'
                        } else {
                          checkCase = '#067387'
                        }                  
                      } else {
                        checkCase = '#FFF4F0'
                      }                      
                      return {
                        ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                        className: 'junse-class-in-td',
                      };
                    },
                    render: (text) => {
                      const t = text == 0 || text == undefined || text == null? '-' : text
                      return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                    },    
                  },
                )
                single.push(
                  {
                    title: '소계',
                    align: "center",
                    dataIndex:  '단독다가구' + '소계',
                    key: '단독다가구' + '소계',
                    onHeaderCell: () => {
                      return {
                        ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
                      };
                    },
                    onCell: (text, record) => {
                      let checkCase = '' 
                      if (record === 0) {
                        checkCase = '#FFCCC7'
                      } else if (record === 1) {
                        checkCase = '#EB5C7A'
                      } else if (record === 5) {
                        if (type === '면적') {
                          checkCase = '#067387'
                        } else {
                          checkCase = '#FFF4F0'
                        }
                      } else if (record === 7) {
                        if (type === '면적') {
                          checkCase = '#FFF4F0'
                        } else {
                          checkCase = '#067387'
                        }                  
                      } else {
                        checkCase = '#FFF4F0'
                      } 
                      return {
                        ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                        className: 'junse-class-in-td',
                      };
                    },
                    render: (text) => {
                      const t = text == 0 || text == undefined || text == null? '-' : text
                      return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
                    },    
                  },
                )
              }
        }
        column.push(
          {
            title: '아파트',
            children: apt,
            key: '아파트',
            onHeaderCell: () => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
              };
            },
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6', borderWidth: 1},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },    
          },
        )
        column.push(
          {
            title: '연립다세대',
            children: row,
            key: '연립다세대',
            onHeaderCell: () => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
              };
            },
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6', borderWidth: 1},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },    
          },
        )
        column.push(
          {
            title: '단독다가구',
            children: single,
            key: '단독다가구',
            onHeaderCell: () => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
              };
            },
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6', borderWidth: 1},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },    
          },
        )
        column.push(
          {
            title: '계',
            align: "center",
            dataIndex: '계',
            key: '계',
            onHeaderCell: () => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6', borderWidth: 1},
              };
            },
            onCell: (text, record) => {
              let checkCase = '' 
              if (record === 0) {
                checkCase = '#FFCCC7'
              } else if (record === 1) {
                checkCase = '#EB5C7A'
              } else if (record === 5) {
                if (type === '면적') {
                  checkCase = '#067387'
                } else {
                  checkCase = '#FFF4F0'
                }
              } else if (record === 7) {
                if (type === '면적') {
                  checkCase = '#FFF4F0'
                } else {
                  checkCase = '#067387'
                }                  
              } else {
                checkCase = '#FFF4F0'
              }
              return {
                ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6', borderWidth: 1},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },    
          },
        )
        setThirdTypeColumns(column)
      }
      const CustomHeaderCell = ({ style, ...props }) => (
        <th {...props} style={{ ...style,borderRadius:1,  backgroundColor: '#FFEAE6', borderColor: '#D4D4D6', textAlign:'center', alignItems: 'center' }} />
      );
      
      const TableComponents = {
        header: {
          cell: CustomHeaderCell,
        },
      };
    const typeOne = () => {
        return <Layout style={{backgroundColor:'#FFFFFF', marginRight: 10,}}>
                <Row style={{}}>
                  <Col xs={24} lg={13}
                    pan={8}
                    style={{flex: 1,}}
                    >
                      {/* <Col 
                        style={{
                          display: 'flex',          
                          flex: 1,
                          justifyContent: 'flex-end',
                          flexDirection: 'column',
                          borderTopLeftRadius: 25,
                          backgroundColor:'#DF7988',
                          minWidth:350,
                          height: 500,
                          marginRight: 10,
                          padding: 20 }}
                        >
                          <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                              서울시
                              <Typography.Title level={3} style={{color: "#FFFFFF"}}>
                              예측물량
                          </Typography.Title>
                          </Typography.Title>
                          <Typography.Title style={{textAlign:'right', color: "#FFFFFF"}} level={5}>
                            단위: 호
                          </Typography.Title>
                      </Col> */}
                      {list['지도'] && <Col style={{flex: 1,height: 650,}}>
                        <AreaMap {...mapConfig('지도')} />
                      </Col>}
                    </Col>
                    {tableList['전체']?.length > 0 && <Col xs={24} lg={11}>
                        <Table components={TableComponents}  style={{borderWidth:1,borderColor: '#00000'}} bordered={true} columns={columns} dataSource={[...tableList['전체']]} pagination={false}   />
                    </Col>}
                </Row>
                <Col style={{flex: 1, marginTop: 25}}>
                    {/* <Bar style={{flex: 1,height: list['전체'].length > 0 ? list['전체'].length * 5 : 200, marginLeft: 30, minWidth: 550}}{...config('전체')} /> */}
                    <Column style={{flex: 1, marginLeft: 30}}{...config('전체')} />
                </Col>
            </Layout>
    }
    const typeTwo = () => {
        return <Layout style={{backgroundColor:'#FFFFFF', marginRight: 10,}}>
                <Row  style={{flex: 1, justifyContent: 'center'}}>
                    {/* <Col 
                    xs={24} lg={8}
                    pan={8}
                    style={{
                      display: 'flex',          
                      flex: 1,
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                      borderTopLeftRadius: 25,
                      backgroundColor:'#DF7988',
                      minWidth:350,
                      maxWidth: 500,
                      maxHeight: 500, marginRight: 10, padding: 20 }}>
                      <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                          주택유형별
                          {filteredInfo.selectType === '면적' && <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                            면적별
                          </Typography.Title>}
                          <Typography.Title level={3} style={{color: "#FFFFFF"}}>
                          예측물량
                          </Typography.Title>
                      </Typography.Title>
                      <Typography.Title style={{textAlign:'right', color: "#FFFFFF"}} level={5}>
                        단위: 호
                      </Typography.Title>
                    </Col> */}
                    {/* <Col xs={24} lg={16}> */}
                    <Col xs={24} lg={24} style={{paddingRight: 10}} >
                    {filteredInfo.selectType !== '면적' ? <Layout 
                      style={{
                        display: 'flex',          
                        flex: 1,
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        borderTopLeftRadius: 25,
                        backgroundColor:'#DF7988',
                        minWidth:350,
                        padding: 20 }}>
                
                        <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                            주택유형별 예측물량
                        </Typography.Title>
                        <Typography.Title style={{textAlign:'right', color: "#FFFFFF"}} level={5}>
                          단위: 호
                        </Typography.Title>
                      </Layout> : <Layout 
                      style={{
                        display: 'flex',          
                        flex: 1,
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        borderTopLeftRadius: 25,
                        backgroundColor:'#DF7988',
                        minWidth:350,
                        padding: 20 }}>
                
                        <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                          주택유형별&면적별 예측물량
                        </Typography.Title>
                        <Typography.Title style={{textAlign:'right', color: "#FFFFFF"}} level={5}>
                          단위: 호
                        </Typography.Title>
                      </Layout>}
                        {/* <Table  components={TableComponents}  style={{borderWidth:1,borderColor: '#00000'}} bordered={true} columns={monthlyColumns} dataSource={[]} locale={{ emptyText: ' ' }} pagination={false}   /> */}
                        <Layout style={{padding: 15, backgroundColor: '#FFEAE6',fontWeight:700, flex: 1, justifyContent:'center', alignItems:'center', borderColor: '#00000', borderWidth:1}}>
                            아파트
                        </Layout>
                        {tableList['아파트'] && <Table components={TableComponents}  style={{borderRightWidth: 0,borderBottomWidth: 0,borderWidth:1,borderColor: '#00000'}} bordered={true} columns={secondTypeColumns} dataSource={[...tableList['아파트']]} pagination={false}   />}
                        <Layout style={{padding: 15, backgroundColor: '#FFEAE6',fontWeight:700, flex: 1, justifyContent:'center', alignItems:'center', borderColor: '#00000', borderWidth:1}}>
                            연립다세대  
                        </Layout>
                        {tableList['연립다세대'] && <Table components={TableComponents}  style={{borderRightWidth: 0,borderBottomWidth: 0,borderWidth:1,borderColor: '#00000'}} bordered={true} columns={secondTypeColumns} dataSource={[...tableList['연립다세대']]} pagination={false}   />}
                        <Layout style={{padding: 15, backgroundColor: '#FFEAE6',fontWeight:700, flex: 1, justifyContent:'center', alignItems:'center', borderColor: '#00000', borderWidth:1}}>
                            단독다가구
                        </Layout>
                        {tableList['단독다가구'] && <Table components={TableComponents}  style={{borderRightWidth: 0,borderBottomWidth: 0,borderWidth:1,borderColor: '#00000'}} bordered={true} columns={secondTypeColumns} dataSource={[...tableList['단독다가구']]} pagination={false}   />}
                    </Col>
                </Row>
                <Row style={{flex: 1,marginTop: 40}}>
                        {/* <Bar style={{flex: 1,height: list['전체'].length > 0 ? list['전체'].length * 5 : 200, marginLeft: 30, minWidth: 550}}{...config('전체')} /> */}
                        <Col xs={24} lg={8}>
                            <Column style={{flex:1,}}{...config2('아파트')} />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Column style={{flex:1,}}{...config2('연립다세대')} />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Column style={{flex:1}}{...config2('단독다가구')} />  
                        </Col>
                </Row>
        </Layout>
    }
    const typeThree = () => {
       if (filteredInfo.selectType === "건축연한") {

        return <>
                <Col style={{marginRight: 10, marginLeft: 10, justifyContent:'center'}}  >
                    <Col style={{}}>
                        {tableList['연한별'] && <Table components={TableComponents}  style={{borderWidth:1,borderColor: '#D4D4D6'}} columns={thirdTypeColumns} dataSource={[...tableList['연한별']]}  pagination={false}   />}
                    </Col>

                    <Col>
                      <Space style={{backgroundColor:'#FFFFFF', marginTop: 20, fontSize: 16, fontWeight: 700}}>
                      전세
                      </Space>
                      <Row style={{marginTop: 20}}>
                        {list['아파트전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('아파트전세')}/></Col>}
                        {list['연립다세대전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('연립다세대전세')}/></Col>}
                        {list['단독다가구전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('단독다가구전세')}/></Col>}
                      </Row>
                      <Space style={{backgroundColor:'#FFFFFF', marginTop: 20, fontSize: 16, fontWeight: 700}}>
                        월세
                        </Space>
                      <Row style={{marginTop: 20}}>
                          {list['아파트월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('아파트월세')}/></Col>}
                          {list['연립다세대월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('연립다세대월세')}/></Col>}
                          {list['단독다가구월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('단독다가구월세')}/></Col>}
                      </Row>
                    </Col>
                </Col>
        </>
       } else {
        return <>
        <Col style={{marginRight: 10, marginLeft: 10, justifyContent:'center'}}  >
            <Col style={{}}>
                {tableList['면적별'] && <Table components={TableComponents}  style={{borderWidth:1,borderColor: '#D4D4D6'}} columns={thirdTypeColumns} dataSource={[...tableList['면적별']]}  pagination={false}   />}
            </Col>

            <Col>
              <Space style={{backgroundColor:'#FFFFFF', marginTop: 20, fontSize: 16, fontWeight: 700}}>
              전세
              </Space>
              <Row style={{marginTop: 20}}>
                {list['아파트전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('아파트전세')}/></Col>}
                {list['연립다세대전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('연립다세대전세')}/></Col>}
                {list['단독다가구전세'] && <Col xs={24} lg={8}><Bar style={{flex: 1, marginLeft: 10}}  {...config3('단독다가구전세')}/></Col>}
              </Row>
              <Space style={{backgroundColor:'#FFFFFF', marginTop: 20, fontSize: 16, fontWeight: 700}}>
                월세
                </Space>
              <Row style={{marginTop: 20}}>
                  {list['아파트월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('아파트월세')}/></Col>}
                  {list['연립다세대월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('연립다세대월세')}/></Col>}
                  {list['단독다가구월세'] && <Col xs={24} lg={8}><Bar style={{marginLeft: 10}}  {...config3('단독다가구월세')}/></Col>}
              </Row>
            </Col>
        </Col>
</>
       }
    }
    return (
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'red',
            },
          }}
        >
            {contextHolder}
            <Row style={{alignItems:'center', flex: 1, paddingTop: 10, paddingBottom: 10, marginBottom: 10, paddingLeft: 10, position: 'sticky',top: 0, backgroundColor:'#ffccc7', zIndex: 10000}}>
                <Col>
                    자치구:  
                    <Select
                    style={{minWidth: 100, marginLeft: 5, marginRight: 5}}
                    onChange={(value) => changeOption(value, 'gu')}
                    options={seoulGu}
                    defaultValue={'선택'}
                    />
                </Col>
                <Col>
                    기간: 
                    <RangePicker
                    defaultValue={[dayjs(),]}
                    disabledDate={(current) => dayjs(current).isBefore(dayjs('20230701'))}
                    style={{ marginLeft: 5, marginRight: 5, textAlign:'center',justifyContent:'center', alignItems:'center'}} locale={locale} picker="month" onChange={(value) =>{ 
                    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                    dayjs.extend(isSameOrAfter)
                    const check = dayjs(value[1]).subtract(5,'month');
                    const check2 = dayjs(dayjs(value[0])).isSameOrAfter(check, 'month')
                    const dateList = [dayjs(value[0]).format('YYYYMM'),dayjs(value[1]).format('YYYYMM')]
                    if(check2 === true){
                        getTableColumn(dayjs(value[0]),dayjs(value[1]));
                        getMonthTable (dayjs(value[0]),dayjs(value[1]));
                        getGuTable(dayjs(value[0]),dayjs(value[1]));
                        getTermColumn(dayjs(value[0]),dayjs(value[1]));
                        changeOption(dateList, 'time')
                        } else{
                        openNotificationWithIcon('error', '최대 6개월까지 선택 가능합니다.')
                        } 
                    }
                    }
                    />
                </Col>
                <Col style={{minWidth: 50, marginLeft: 5, marginRight: 5}}>
                    <Checkbox
                    disabled={filteredInfo.gu === null ? true : false}
                    checked={size === '면적' ? true : false}
                    style={{minWidth: 20, marginLeft: 5,marginRight: 5 }}
                    value={'면적'}
                    onChange={(value) => changeOption(value, 'selectType')}
                    />
                    면적
                </Col>
                <Col style={{minWidth: 50, marginLeft: 5, marginRight: 20}}>
                    <Checkbox
                    disabled={filteredInfo.gu === null ? true : false}
                    checked={size === "건축연한" ? true : false}
                    style={{minWidth: 20, marginLeft: 5,marginRight: 5 }}
                    value={"건축연한"}
                    onChange={(value) => changeOption(value, 'selectType')}
                    />
                    건축연한
                </Col>
              </Row>
            {depth === 1 && typeOne()}
            {depth === 2 && typeTwo()}
            {depth === 3 && typeThree()}

        </ConfigProvider>
    );
}