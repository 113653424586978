import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//s const columns = gen_table.headers

    const HEADER_SIZED = [{
        "key": "column-0", "dataKey": "column-0", "title": "구분", "title2": "구분", "width": 80, "rowspan": {"0": 2}
    }, {"key": "column-1", "dataKey": "column-1", "title": "구분", "title2": "구분", "width": 100}, {
        "key": "column-2", "dataKey": "column-2", "title": "합계", "title2": "합계", "width": 80
    }, {
        "key": "column-3", "dataKey": "column-3", "title": "소계", "title2": "소계", "title1": "사업예정", "width": 70
    }, {
        "key": "column-4", "dataKey": "column-4", "title": "공공개발", "title2": "공공개발", "title1": "사업예정", "width": 70
    }, {
        "key": "column-5", "dataKey": "column-5", "title": "신통기획", "title2": "신통기획", "title1": "사업예정", "width": 70
    }, {
        "key": "column-6", "dataKey": "column-6", "title": "구역지정\\n추진중", "title2": "구역지정\\n추진중", "width": 70
    }, {
        "key": "column-7", "dataKey": "column-7", "title": "소계", "title2": "소계", "title1": "사업추진중", "width": 70
    }, {
        "key": "column-8", "dataKey": "column-8", "title": "공공개발", "title2": "공공개발", "title1": "사업추진중", "width": 70
    }, {
        "key": "column-9", "dataKey": "column-9", "title": "신통기획", "title2": "신통기획", "title1": "사업추진중", "width": 70
    }, {"key": "column-10", "dataKey": "column-10", "title": "일반", "title2": "일반", "title1": "사업추진중", "width": 70}]
    const LEFTMOST_HEADER_COUNT = 2
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [-1]

    const width_table = 820
    const height_table = 57


    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-3 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '180px'}} className="row-span-3 col-span-2 flex word-spacing items-center justify-center">구분</div>
                <div style={{width: '80px'}} className="test row-span-3 flex word-spacing items-center justify-center">합계</div>
                <div style={{width: '280px'}} className="test col-span-4 flex word-spacing items-center justify-center">사업예정</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">소계</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">신통</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">구역지정 추진중</div>
                <div style={{width: '280px'}} className="col-span-4 flex word-spacing items-center justify-center">사업추진</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">소계</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">신통</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">일반</div>
            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;

