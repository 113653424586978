import React from "react";
import {Route, Switch} from "react-router-dom";
import Depth2List from "../components/Depth2/Depth2List";

// components
// views

export default function Depth2Layout({match}) {
    return (
        <>
            <div className="py-3 px-2 bg-rose-700 ">
                <p className="text-lg text-gray-50 flex justify-start items-center px-2 py-2">
                    메뉴를 선택하여 자료를 확인하세요
                </p>
            </div>
            <div className="px-3 bg-white">
                <Switch>
                    <Route path="/work/:workId">
                        <Depth2List/>
                    </Route>
                </Switch>
            </div>
        </>
    );
}
