import React from "react";
import 'react-base-table/styles.css'
import T1421 from "../work1/data1/T1421";
import T1422 from "../work1/data1/T1422";
import T1423 from "../work1/data1/T1423";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";


export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey);
    return (serverData && <>
        <Depth3Title title='재건축 정비구역 지정현황'/>
        <T1421 code='1421' data={JSON.parse(serverData.data['1421'])}/>
        <Depth3Title title='재건축 정비사업 추진현황'/>
        <T1422 code='1422' data={JSON.parse(serverData.data['1422'])}/>
        <Depth3Title title='재건축 정비사업 주택공급'/>
        <T1423 code='1423' data={JSON.parse(serverData.data['1423'])}/>
    </>);
}