import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//const columns = gen_table.headers
// 현재로서는 사이즈 정보만 body 를 위해서 사용하고 헤더는 따로 그린다. 단 rowspan 정보는 활용한다.
    const HEADER_SIZED = [
        {"key": "column-0", "dataKey": "column-0", "width": 80, "rowspan": {0: 1, 1: 1, 2: 3, 5: 2, 8: 2, 10: 2}},
        {"key": "column-1", "dataKey": "column-1", "width": 80,},
        {
            "key": "column-2",
            "dataKey": "column-2",
            "title": "합계",
            "width": 70,
            "title1": '합계',
            "title2": "합계",
            "sum": true,
        },
        {"key": "column-3", "dataKey": "column-3", "title": "공공", "width": 70, "title2": "공공"},
        {"key": "column-4", "dataKey": "column-4", "title": "신통기획", "title1": "공모지선정", "title2": "신통기획", "width": 70},
        {"key": "column-5", "dataKey": "column-5", "title": "공동주택", "title2": "시행중", "width": 70},
        {"key": "column-6", "dataKey": "column-6", "title": "아파트지구", "width": 70, "title2": "통과", "title1": "재건축안전 진단"},
        {
            "key": "column-7",
            "dataKey": "column-7",
            "title": "구역지정 추진 중",
            "title2": "구역 지정추진중",
            "title1": "구역 지정추진중",
            "width": 70
        },
        {
            "key": "column-8",
            "dataKey": "column-8",
            "title": "존치정비 구역 지정",
            "title2": "존치 정비구역",
            "title1": "존치 정비구역",
            "width": 70,
        },
    ]

    const LEFTMOST_HEADER_COUNT = 2
    const ROW_TO_BE_MERGE = [0, 1, 7, 12, 13, 14, 15]
    const ROW_SUB_SUM = [1, 7, 12]

    const width_table = 650
    const height_table = 450

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-3 grid-flow-col h-100 text-xs font-bold text-center table-column-headers">
                <div style={{width: '160px'}}
                     className="row-span-3 flex word-spacing items-center justify-center">사업유형
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">합계
                </div>
                <div style={{width: '420px'}}
                     className="col-span-6 flex word-spacing items-center justify-center">사업예정
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공개발
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">신통기획
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">안전진단
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">존치정비
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">구역지정
                    추진중
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">조합설립
                    추진중
                </div>
            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;
