import React from "react";
import {Surface, Symbols} from "recharts";

export const CustomizedXAxisTick = ({x, y, payload, screenWidth}) => {
    let fontSize = '12px';
    if (screenWidth >= 768 && screenWidth < 1024) {
        fontSize = '14px';
    } else if (screenWidth >= 1024) {
        fontSize = '16px';
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={fontSize} transform="rotate(-35)">
                {payload.value}
            </text>
        </g>
    );
};
export const CustomizedYAxisTick = ({x, y, payload, screenWidth, devide = 1}) => {
    let fontSize = '12px';
    if (screenWidth >= 768 && screenWidth < 1024) {
        fontSize = '14px';
    } else if (screenWidth >= 1024) {
        fontSize = '16px';
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} textAnchor="end" fill="#666" fontSize={fontSize}>
                {(payload.value / devide).toLocaleString()}
            </text>
        </g>
    );
};
export const CustomizedYAxisTickRight = ({x, y, payload, screenWidth}) => {
    let fontSize = '12px';
    if (screenWidth >= 768 && screenWidth < 1024) {
        fontSize = '14px';
    } else if (screenWidth >= 1024) {
        fontSize = '16px';
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dx={25} dy={5} textAnchor="end" fill="#666" fontSize={fontSize}>
                {payload.value}
            </text>
        </g>
    );
};
export const CustomizedLegend = ({screenWidth, payload}) => {
    let fontSize = '12px';
    if (screenWidth >= 768 && screenWidth < 1024) {
        fontSize = '14px';
    } else if (screenWidth >= 1024) {
        fontSize = '16px';
    }

    return (
        <div className="h-full w-full flex items-center justify-center">
            <ul className="flex items-center">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} className="flex items-center mr-4">
                        <div
                            className="w-3 h-3 mr-2"
                            style={{
                                backgroundColor: entry.color,
                            }}
                        />
                        <span style={{fontSize}}>{entry.value}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const CustomLegend = ({payload}) => {

    let longkey = false
    if (payload.length > 0) {

        longkey = payload.filter(v => {
                return v.dataKey.length > 10
            }
        ).length > 0
    }

    return (
        <>
            <div className={`flex pl-8 text-sm md:text-sm lg:text-base justify-start md:justify-center
                ${longkey ? 'flex-col' : 'flex-row md:flex-wrap'}`}
                 style={{paddingBottom: '30px'}}>
                {payload.map((entry, index) => (
                    <div key={`item-${index}`} className="inline-flex items-center ml-3 ">
                        {entry.type === 'line' ? (
                            <>
                                <Surface width={10} height={10} viewBox="0 0 10 10">
                                    <Symbols cx={5} cy={5} type="circle" size={50} fill={entry.color}/>
                                </Surface>
                            </>
                        ) : (
                            <Surface width={10} height={10} viewBox="0 0 10 10">
                                <Symbols cx={5} cy={5} type="square" size={50} fill={entry.color}/>
                            </Surface>
                        )}
                        <span className="ml-2 ">{entry.value}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export function calculateXAxisInterval(data) {
    const maxIntervals = 12;
    const dataLength = data.length;
    if (dataLength > maxIntervals) {
        return Math.ceil(dataLength / maxIntervals);
    } else {
        return 0;
    }
}


export const formatTooltipValue = (value) => {
    if (isNaN(value)) {
        return ""
    } else {
        try {
            if (parseInt(value) === value) {
                return value
            } else {
                return parseFloat(value).toFixed(1).toLocaleString()
            }
        } catch (e) {
            console.log(`error to process toFixed for ${value}`, e)
        }
    }
}

export const colors = ['#FF5733', '#4B0082', '#228B22', '#FFD700', '#EE82EE', '#20B2AA', '#FF6347', '#7B68EE', '#FFA07A', '#48D1CC', '#DC143C', '#008080', '#FF4500', '#32CD32', '#BA55D3', '#F4A460', '#8B4513', '#4682B4', '#BDB76B', '#6A5ACD']

export function isNumberAndNotNull(value) {
    return value !== null && typeof value === 'number';
}


export function recalc100(filteredData) {
    const firstRowIndex = {};

    // 각 컬럼의 첫 번째 숫자가 있는 행 번호를 찾음
    filteredData.forEach((aLine, index) => {
        Object.keys(aLine).forEach(key => {
            if (key !== "year" && key !== "month" && aLine[key] !== '' && !firstRowIndex.hasOwnProperty(key)) {
                firstRowIndex[key] = index;
            }
        });
    });

    const ratios = {};

    const recalcData = filteredData.map((aLine, index) => {
        const item = {...aLine};

        Object.keys(aLine).forEach(key => {
            if (key !== "year" && key !== "month") {
                if (aLine[key] === '' || aLine[key] === null || aLine[key] === undefined) {
                    // Remove the key if its value is an empty string, null, or undefined
                    delete item[key];
                } else {
                    if (index === firstRowIndex[key]) {
                        // 컬럼별 첫 행의 비율을 계산
                        ratios[key] = aLine[key] / 100;

                        // 첫 행의 값을 100으로 조정
                        item[key] = aLine[key] / ratios[key];
                    } else if (firstRowIndex[key] < index) {
                        // 컬럼별 첫 행의 비율을 사용하여 나머지 행의 값 조정
                        item[key] = aLine[key] / ratios[key] * 1;
                    }
                }
            }
        });

        return item;
    });
    return recalcData;
}

export function toNum(value) {
    if (isNaN(value) || value === "" || value === null) {
        return 0;
    } else {
        return value;
    }
}