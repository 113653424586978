export const dong_coords = [
    {
        "code": 1111051501,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "청운효자동",
        "lat": 37.584009,
        "lng": 126.970626
    },
    {
        "code": 1111053000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "사직동",
        "lat": 37.575408,
        "lng": 126.965944
    },
    {
        "code": 1111054000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "삼청동",
        "lat": 37.590758,
        "lng": 126.980996
    },
    {
        "code": 1111055000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "부암동",
        "lat": 37.594768,
        "lng": 126.965574
    },
    {
        "code": 1111056000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "평창동",
        "lat": 37.613029,
        "lng": 126.974485
    },
    {
        "code": 1111057000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "무악동",
        "lat": 37.57711,
        "lng": 126.958562
    },
    {
        "code": 1111058000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "교남동",
        "lat": 37.568547,
        "lng": 126.964454
    },
    {
        "code": 1111060000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "가회동",
        "lat": 37.582682,
        "lng": 126.984831
    },
    {
        "code": 1111061500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로1.2.3.4가동",
        "lat": 37.570962,
        "lng": 126.977881
    },
    {
        "code": 1111063000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로5.6가동",
        "lat": 37.573681,
        "lng": 127.009292
    },
    {
        "code": 1111064000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "이화동",
        "lat": 37.577245,
        "lng": 127.005622
    },
    {
        "code": 1111065000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "혜화동",
        "lat": 37.587817,
        "lng": 127.001745
    },
    {
        "code": 1111067000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "창신제1동",
        "lat": 37.573172,
        "lng": 126.97922
    },
    {
        "code": 1111068000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "창신제2동",
        "lat": 37.573172,
        "lng": 126.97922
    },
    {
        "code": 1111069000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "창신제3동",
        "lat": 37.573172,
        "lng": 126.97922
    },
    {
        "code": 1111070000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "숭인제1동",
        "lat": 37.573172,
        "lng": 126.97922
    },
    {
        "code": 1111071000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "숭인제2동",
        "lat": 37.573172,
        "lng": 126.97922
    },
    {
        "code": 1114052000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "소공동",
        "lat": 37.564079,
        "lng": 126.980046
    },
    {
        "code": 1114054000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "회현동",
        "lat": 37.557288,
        "lng": 126.979369
    },
    {
        "code": 1114055000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "명동",
        "lat": 37.560029,
        "lng": 126.985806
    },
    {
        "code": 1114057000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "필동",
        "lat": 37.560148,
        "lng": 126.995572
    },
    {
        "code": 1114058000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "장충동",
        "lat": 37.561919,
        "lng": 127.007818
    },
    {
        "code": 1114059000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "광희동",
        "lat": 37.564445,
        "lng": 127.005023
    },
    {
        "code": 1114060500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로동",
        "lat": 37.56669,
        "lng": 126.991319
    },
    {
        "code": 1114061500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "신당동",
        "lat": 37.557913,
        "lng": 127.013655
    },
    {
        "code": 1114062500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "다산동",
        "lat": 37.554398,
        "lng": 127.008155
    },
    {
        "code": 1114063500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "약수동",
        "lat": 37.552459,
        "lng": 127.008973
    },
    {
        "code": 1114064500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "청구동",
        "lat": 37.557074,
        "lng": 127.014616
    },
    {
        "code": 1114065000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "신당제5동",
        "lat": 37.56376,
        "lng": 126.997459
    },
    {
        "code": 1114066500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "동화동",
        "lat": 37.560042,
        "lng": 127.019399
    },
    {
        "code": 1114067000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "황학동",
        "lat": 37.568542,
        "lng": 127.020827
    },
    {
        "code": 1114068000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "중림동",
        "lat": 37.558842,
        "lng": 126.96632
    },
    {
        "code": 1117051000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "후암동",
        "lat": 37.549901,
        "lng": 126.980613
    },
    {
        "code": 1117052000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산2가동",
        "lat": 37.542884,
        "lng": 126.985008
    },
    {
        "code": 1117053000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "남영동",
        "lat": 37.542865,
        "lng": 126.973353
    },
    {
        "code": 1117055500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "청파동",
        "lat": 37.545502,
        "lng": 126.969899
    },
    {
        "code": 1117056000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로제1동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117057000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로제2동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117058000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "효창동",
        "lat": 37.542828,
        "lng": 126.960951
    },
    {
        "code": 1117059000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용문동",
        "lat": 37.537663,
        "lng": 126.959677
    },
    {
        "code": 1117062500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한강로동",
        "lat": 37.529054,
        "lng": 126.969503
    },
    {
        "code": 1117063000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이촌제1동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117064000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이촌제2동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117065000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이태원제1동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117066000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이태원제2동",
        "lat": 37.532612,
        "lng": 126.990182
    },
    {
        "code": 1117068500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한남동",
        "lat": 37.537541,
        "lng": 127.005165
    },
    {
        "code": 1117069000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "서빙고동",
        "lat": 37.518619,
        "lng": 126.991299
    },
    {
        "code": 1117070000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "보광동",
        "lat": 37.526873,
        "lng": 127.000917
    },
    {
        "code": 1120052000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "왕십리제2동",
        "lat": 37.563386,
        "lng": 127.037047
    },
    {
        "code": 1120053500,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "왕십리도선동",
        "lat": 37.56598,
        "lng": 127.031444
    },
    {
        "code": 1120054000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "마장동",
        "lat": 37.567363,
        "lng": 127.040731
    },
    {
        "code": 1120055000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "사근동",
        "lat": 37.558904,
        "lng": 127.047597
    },
    {
        "code": 1120056000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "행당제1동",
        "lat": 37.563386,
        "lng": 127.037047
    },
    {
        "code": 1120057000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "행당제2동",
        "lat": 37.563386,
        "lng": 127.037047
    },
    {
        "code": 1120058000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "응봉동",
        "lat": 37.550935,
        "lng": 127.033903
    },
    {
        "code": 1120059000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호1가동",
        "lat": 37.553509,
        "lng": 127.023661
    },
    {
        "code": 1120061500,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호2.3가동",
        "lat": 37.549549,
        "lng": 127.019842
    },
    {
        "code": 1120062000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호4가동",
        "lat": 37.545404,
        "lng": 127.024129
    },
    {
        "code": 1120064500,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "옥수동",
        "lat": 37.540788,
        "lng": 127.020624
    },
    {
        "code": 1120065000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수1가제1동",
        "lat": 37.543511,
        "lng": 127.042746
    },
    {
        "code": 1120066000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수1가제2동",
        "lat": 37.543511,
        "lng": 127.042746
    },
    {
        "code": 1120067000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수2가제1동",
        "lat": 37.540855,
        "lng": 127.056517
    },
    {
        "code": 1120069000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수2가제3동",
        "lat": 37.540855,
        "lng": 127.056517
    },
    {
        "code": 1120072000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "송정동",
        "lat": 37.553181,
        "lng": 127.062066
    },
    {
        "code": 1120079000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "용답동",
        "lat": 37.56147,
        "lng": 127.056348
    },
    {
        "code": 1121571000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "화양동",
        "lat": 37.54325,
        "lng": 127.073393
    },
    {
        "code": 1121573000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "군자동",
        "lat": 37.553038,
        "lng": 127.073657
    },
    {
        "code": 1121574000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "중곡제1동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121575000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "중곡제2동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121576000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "중곡제3동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121577000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "중곡제4동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121578000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "능동",
        "lat": 37.550578,
        "lng": 127.081722
    },
    {
        "code": 1121581000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "광장동",
        "lat": 37.547835,
        "lng": 127.104695
    },
    {
        "code": 1121582000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "자양제1동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121583000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "자양제2동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121584000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "자양제3동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121584700,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "자양제4동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121585000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "구의제1동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121586000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "구의제2동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1121587000,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "구의제3동",
        "lat": 37.53821,
        "lng": 127.082191
    },
    {
        "code": 1123053600,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "용신동",
        "lat": 37.575868,
        "lng": 127.037305
    },
    {
        "code": 1123054500,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "제기동",
        "lat": 37.583778,
        "lng": 127.037226
    },
    {
        "code": 1123056000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "전농제1동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123057000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "전농제2동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123060000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "답십리제1동",
        "lat": 37.57097,
        "lng": 127.056703
    },
    {
        "code": 1123061000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "답십리제2동",
        "lat": 37.57097,
        "lng": 127.056703
    },
    {
        "code": 1123065000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "장안제1동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123066000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "장안제2동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123070500,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "청량리동",
        "lat": 37.588799,
        "lng": 127.04528
    },
    {
        "code": 1123071000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "회기동",
        "lat": 37.593733,
        "lng": 127.051434
    },
    {
        "code": 1123072000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "휘경제1동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123073000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "휘경제2동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123074000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "이문제1동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1123075000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "이문제2동",
        "lat": 37.574428,
        "lng": 127.039712
    },
    {
        "code": 1126052000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목제2동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126054000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목제4동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126055000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목제5동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126056500,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목본동",
        "lat": 37.587331,
        "lng": 127.087419
    },
    {
        "code": 1126057000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목제7동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126057500,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목제3.8동",
        "lat": 37.582921,
        "lng": 127.088107
    },
    {
        "code": 1126058000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "상봉제1동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126059000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "상봉제2동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126060000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "중화제1동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126061000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "중화제2동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126062000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "묵제1동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126063000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "묵제2동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126065500,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "망우본동",
        "lat": 37.601504,
        "lng": 127.106215
    },
    {
        "code": 1126066000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "망우제3동",
        "lat": 37.606736,
        "lng": 127.092924
    },
    {
        "code": 1126068000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "신내1동",
        "lat": 37.605867,
        "lng": 127.099356
    },
    {
        "code": 1126069000,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "신내2동",
        "lat": 37.606188,
        "lng": 127.094222
    },
    {
        "code": 1129052500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "성북동",
        "lat": 37.597248,
        "lng": 126.992899
    },
    {
        "code": 1129055500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동",
        "lat": 37.590836,
        "lng": 127.01467
    },
    {
        "code": 1129057500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동",
        "lat": 37.593984,
        "lng": 127.020426
    },
    {
        "code": 1129058000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "돈암제1동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129059000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "돈암제2동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129060000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동",
        "lat": 37.585892,
        "lng": 127.021381
    },
    {
        "code": 1129061000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동",
        "lat": 37.58038,
        "lng": 127.022857
    },
    {
        "code": 1129062000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "정릉제1동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129063000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "정릉제2동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129064000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "정릉제3동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129065000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "정릉제4동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129066000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "길음제1동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129068500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "길음제2동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129070500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "종암동",
        "lat": 37.597176,
        "lng": 127.033894
    },
    {
        "code": 1129071500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "월곡제1동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129072500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "월곡제2동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129076000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "장위제1동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129077000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "장위제2동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129078000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "장위제3동",
        "lat": 37.58944,
        "lng": 127.016817
    },
    {
        "code": 1129081000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "석관동",
        "lat": 37.608265,
        "lng": 127.061465
    },
    {
        "code": 1130553400,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "삼양동",
        "lat": 37.627368,
        "lng": 127.0183
    },
    {
        "code": 1130553500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "미아동",
        "lat": 37.621106,
        "lng": 127.023865
    },
    {
        "code": 1130554500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "송중동",
        "lat": 37.616062,
        "lng": 127.033819
    },
    {
        "code": 1130555500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "송천동",
        "lat": 37.618268,
        "lng": 127.023845
    },
    {
        "code": 1130557500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "삼각산동",
        "lat": 37.616702,
        "lng": 127.017511
    },
    {
        "code": 1130559500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "번1동",
        "lat": 37.637825,
        "lng": 127.028802
    },
    {
        "code": 1130560300,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "번2동",
        "lat": 37.632572,
        "lng": 127.038693
    },
    {
        "code": 1130560800,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "번3동",
        "lat": 37.625852,
        "lng": 127.046619
    },
    {
        "code": 1130561500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "수유1동",
        "lat": 37.630309,
        "lng": 127.017684
    },
    {
        "code": 1130562500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "수유2동",
        "lat": 37.644753,
        "lng": 127.019941
    },
    {
        "code": 1130563500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "수유3동",
        "lat": 37.638832,
        "lng": 127.023089
    },
    {
        "code": 1130564500,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "우이동",
        "lat": 37.663286,
        "lng": 127.000508
    },
    {
        "code": 1130566000,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "인수동",
        "lat": 37.641485,
        "lng": 127.010591
    },
    {
        "code": 1132051100,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창제1동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132051200,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창제2동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132051300,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창제3동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132051400,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창제4동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132051500,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창제5동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132052100,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "도봉제1동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132052200,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "도봉제2동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132066000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "쌍문제1동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132067000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "쌍문제2동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132068000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "쌍문제3동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132068100,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "쌍문제4동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132069000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "방학제1동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132070000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "방학제2동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1132071000,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "방학제3동",
        "lat": 37.668639,
        "lng": 127.047364
    },
    {
        "code": 1135056000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "월계1동",
        "lat": 37.619919,
        "lng": 127.062984
    },
    {
        "code": 1135057000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "월계2동",
        "lat": 37.63251,
        "lng": 127.051031
    },
    {
        "code": 1135058000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "월계3동",
        "lat": 37.620949,
        "lng": 127.069054
    },
    {
        "code": 1135059500,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "공릉1동",
        "lat": 37.624843,
        "lng": 127.07382
    },
    {
        "code": 1135060000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "공릉2동",
        "lat": 37.621268,
        "lng": 127.083357
    },
    {
        "code": 1135061100,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "하계1동",
        "lat": 37.640485,
        "lng": 127.072685
    },
    {
        "code": 1135061200,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "하계2동",
        "lat": 37.632123,
        "lng": 127.068053
    },
    {
        "code": 1135061900,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "중계본동",
        "lat": 37.647853,
        "lng": 127.080401
    },
    {
        "code": 1135062100,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "중계1동",
        "lat": 37.652551,
        "lng": 127.077488
    },
    {
        "code": 1135062400,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "중계4동",
        "lat": 37.658763,
        "lng": 127.077989
    },
    {
        "code": 1135062500,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "중계2.3동",
        "lat": 37.64204,
        "lng": 127.068711
    },
    {
        "code": 1135063000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계1동",
        "lat": 37.679855,
        "lng": 127.05502
    },
    {
        "code": 1135064000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계2동",
        "lat": 37.65886,
        "lng": 127.068479
    },
    {
        "code": 1135066500,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계3.4동",
        "lat": 37.672624,
        "lng": 127.08302
    },
    {
        "code": 1135067000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계5동",
        "lat": 37.662994,
        "lng": 127.06947
    },
    {
        "code": 1135069500,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계6.7동",
        "lat": 37.654919,
        "lng": 127.066919
    },
    {
        "code": 1135070000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계8동",
        "lat": 37.666726,
        "lng": 127.051548
    },
    {
        "code": 1135071000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계9동",
        "lat": 37.66449,
        "lng": 127.063818
    },
    {
        "code": 1135072000,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계10동",
        "lat": 37.661245,
        "lng": 127.060243
    },
    {
        "code": 1138051000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "녹번동",
        "lat": 37.605314,
        "lng": 126.934567
    },
    {
        "code": 1138052000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "불광제1동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138053000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "불광제2동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138055100,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "갈현제1동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138055200,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "갈현제2동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138056000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "구산동",
        "lat": 37.611074,
        "lng": 126.907302
    },
    {
        "code": 1138057000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "대조동",
        "lat": 37.612256,
        "lng": 126.923141
    },
    {
        "code": 1138058000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "응암제1동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138059000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "응암제2동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138060000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "응암제3동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138062500,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "역촌동",
        "lat": 37.604582,
        "lng": 126.914611
    },
    {
        "code": 1138063100,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "신사제1동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138063200,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "신사제2동",
        "lat": 37.602766,
        "lng": 126.928839
    },
    {
        "code": 1138064000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "증산동",
        "lat": 37.584166,
        "lng": 126.905952
    },
    {
        "code": 1138065000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "수색동",
        "lat": 37.585574,
        "lng": 126.894529
    },
    {
        "code": 1138069000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "진관동",
        "lat": 37.639681,
        "lng": 126.937804
    },
    {
        "code": 1141052000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "천연동",
        "lat": 37.567945,
        "lng": 126.959437
    },
    {
        "code": 1141055500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "북아현동",
        "lat": 37.562431,
        "lng": 126.954397
    },
    {
        "code": 1141056500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "충현동",
        "lat": 37.56483,
        "lng": 126.954715
    },
    {
        "code": 1141058500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "신촌동",
        "lat": 37.56555,
        "lng": 126.941382
    },
    {
        "code": 1141061500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "연희동",
        "lat": 37.571987,
        "lng": 126.932812
    },
    {
        "code": 1141062000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍제제1동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141064000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍제제3동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141065500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍제제2동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141066000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍은제1동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141068500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍은제2동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141069000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "남가좌제1동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141070000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "남가좌제2동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141071000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "북가좌제1동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1141072000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "북가좌제2동",
        "lat": 37.579213,
        "lng": 126.936773
    },
    {
        "code": 1144055500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "아현동",
        "lat": 37.554474,
        "lng": 126.956308
    },
    {
        "code": 1144056500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "공덕동",
        "lat": 37.548092,
        "lng": 126.954994
    },
    {
        "code": 1144058500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "도화동",
        "lat": 37.539758,
        "lng": 126.950007
    },
    {
        "code": 1144059000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "용강동",
        "lat": 37.541502,
        "lng": 126.940443
    },
    {
        "code": 1144060000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "대흥동",
        "lat": 37.549807,
        "lng": 126.943177
    },
    {
        "code": 1144061000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "염리동",
        "lat": 37.549096,
        "lng": 126.947307
    },
    {
        "code": 1144063000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "신수동",
        "lat": 37.548524,
        "lng": 126.936813
    },
    {
        "code": 1144065500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "서강동",
        "lat": 37.54778,
        "lng": 126.932218
    },
    {
        "code": 1144066000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "서교동",
        "lat": 37.553838,
        "lng": 126.918765
    },
    {
        "code": 1144068000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "합정동",
        "lat": 37.547027,
        "lng": 126.909029
    },
    {
        "code": 1144069000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "망원제1동",
        "lat": 37.566404,
        "lng": 126.901763
    },
    {
        "code": 1144070000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "망원제2동",
        "lat": 37.566404,
        "lng": 126.901763
    },
    {
        "code": 1144071000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "연남동",
        "lat": 37.563027,
        "lng": 126.921519
    },
    {
        "code": 1144072000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "성산제1동",
        "lat": 37.566404,
        "lng": 126.901763
    },
    {
        "code": 1144073000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "성산제2동",
        "lat": 37.566404,
        "lng": 126.901763
    },
    {
        "code": 1144074000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "상암동",
        "lat": 37.570748,
        "lng": 126.87984
    },
    {
        "code": 1147051000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목1동",
        "lat": 37.530433,
        "lng": 126.870985
    },
    {
        "code": 1147052000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목2동",
        "lat": 37.546108,
        "lng": 126.871631
    },
    {
        "code": 1147053000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목3동",
        "lat": 37.544858,
        "lng": 126.864408
    },
    {
        "code": 1147054000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목4동",
        "lat": 37.532327,
        "lng": 126.868343
    },
    {
        "code": 1147055000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목5동",
        "lat": 37.535136,
        "lng": 126.87854
    },
    {
        "code": 1147056000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월1동",
        "lat": 37.532815,
        "lng": 126.83153
    },
    {
        "code": 1147057000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월2동",
        "lat": 37.52497,
        "lng": 126.844572
    },
    {
        "code": 1147058000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월3동",
        "lat": 37.533674,
        "lng": 126.82905
    },
    {
        "code": 1147059000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월4동",
        "lat": 37.524619,
        "lng": 126.840063
    },
    {
        "code": 1147060000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월5동",
        "lat": 37.538566,
        "lng": 126.827186
    },
    {
        "code": 1147061000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월6동",
        "lat": 37.516265,
        "lng": 126.843631
    },
    {
        "code": 1147061100,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월7동",
        "lat": 37.521756,
        "lng": 126.834791
    },
    {
        "code": 1147062000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정1동",
        "lat": 37.51855,
        "lng": 126.854354
    },
    {
        "code": 1147063000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정2동",
        "lat": 37.519267,
        "lng": 126.870723
    },
    {
        "code": 1147064000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정3동",
        "lat": 37.515086,
        "lng": 126.854859
    },
    {
        "code": 1147065000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정4동",
        "lat": 37.523422,
        "lng": 126.85868
    },
    {
        "code": 1147067000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정6동",
        "lat": 37.517053,
        "lng": 126.864399
    },
    {
        "code": 1147068000,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정7동",
        "lat": 37.514412,
        "lng": 126.8597
    },
    {
        "code": 1150051000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "염창동",
        "lat": 37.554552,
        "lng": 126.871764
    },
    {
        "code": 1150052000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "등촌제1동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150053000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "등촌제2동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150053500,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "등촌제3동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150054000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제1동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150055000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제2동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150056000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제3동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150057000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제4동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150059000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡본동",
        "lat": 37.544092,
        "lng": 126.847626
    },
    {
        "code": 1150059100,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제6동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150059300,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡제8동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150060300,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "가양제1동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150060400,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "가양제2동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150060500,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "가양제3동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150061100,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "발산제1동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150061500,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "우장산동",
        "lat": 37.548198,
        "lng": 126.841792
    },
    {
        "code": 1150062000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "공항동",
        "lat": 37.554779,
        "lng": 126.810453
    },
    {
        "code": 1150063000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "방화제1동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150064000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "방화제2동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1150064100,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "방화제3동",
        "lat": 37.550968,
        "lng": 126.849614
    },
    {
        "code": 1153051000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "신도림동",
        "lat": 37.509951,
        "lng": 126.882174
    },
    {
        "code": 1153052000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로제1동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153053000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로제2동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153054000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로제3동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153055000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로제4동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153056000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로제5동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153059500,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "가리봉동",
        "lat": 37.482704,
        "lng": 126.888019
    },
    {
        "code": 1153072000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "고척제1동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153073000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "고척제2동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153074000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "개봉제1동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153075000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "개봉제2동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153076000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "개봉제3동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153077000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "오류제1동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153078000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "오류제2동",
        "lat": 37.49565,
        "lng": 126.887771
    },
    {
        "code": 1153079000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "수궁동",
        "lat": 37.493837,
        "lng": 126.831527
    },
    {
        "code": 1154551000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "가산동",
        "lat": 37.476681,
        "lng": 126.883767
    },
    {
        "code": 1154561000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "독산제1동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154562000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "독산제2동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154563000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "독산제3동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154564000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "독산제4동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154567000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥제1동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154568000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥제2동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154569000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥제3동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154570000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥제4동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1154571000,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥제5동",
        "lat": 37.456979,
        "lng": 126.895656
    },
    {
        "code": 1156051500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포본동",
        "lat": 37.514692,
        "lng": 126.909387
    },
    {
        "code": 1156053500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동",
        "lat": 37.513786,
        "lng": 126.906901
    },
    {
        "code": 1156054000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "여의동",
        "lat": 37.517598,
        "lng": 126.934571
    },
    {
        "code": 1156055000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산제1동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156056000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산제2동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156058500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "도림동",
        "lat": 37.509105,
        "lng": 126.900533
    },
    {
        "code": 1156060500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동",
        "lat": 37.517141,
        "lng": 126.899454
    },
    {
        "code": 1156061000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평제1동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156062000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평제2동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156063000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제1동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156065000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제3동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156066000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제4동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156067000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제5동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156068000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제6동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156069000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길제7동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156070000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "대림제1동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156071000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "대림제2동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1156072000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "대림제3동",
        "lat": 37.52641,
        "lng": 126.896252
    },
    {
        "code": 1159051000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "노량진제1동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159052000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "노량진제2동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159053000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도제1동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159054000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도제2동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159055000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도제3동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159056000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도제4동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159060500,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "흑석동",
        "lat": 37.505492,
        "lng": 126.962317
    },
    {
        "code": 1159062000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당제1동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159063000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당제2동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159064000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당제3동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159065000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당제4동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159065100,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당제5동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159066000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "대방동",
        "lat": 37.506879,
        "lng": 126.927994
    },
    {
        "code": 1159067000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "신대방제1동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1159068000,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "신대방제2동",
        "lat": 37.512462,
        "lng": 126.939485
    },
    {
        "code": 1162052500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "보라매동",
        "lat": 37.488208,
        "lng": 126.932761
    },
    {
        "code": 1162054500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "청림동",
        "lat": 37.491928,
        "lng": 126.958841
    },
    {
        "code": 1162056500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "성현동",
        "lat": 37.489708,
        "lng": 126.948322
    },
    {
        "code": 1162057500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "행운동",
        "lat": 37.480738,
        "lng": 126.95695
    },
    {
        "code": 1162058500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "낙성대동",
        "lat": 37.476281,
        "lng": 126.958369
    },
    {
        "code": 1162059500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "청룡동",
        "lat": 37.479143,
        "lng": 126.941675
    },
    {
        "code": 1162060500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "은천동",
        "lat": 37.485376,
        "lng": 126.942503
    },
    {
        "code": 1162061500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "중앙동",
        "lat": 37.484016,
        "lng": 126.949645
    },
    {
        "code": 1162062500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "인헌동",
        "lat": 37.475126,
        "lng": 126.965259
    },
    {
        "code": 1162063000,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "남현동",
        "lat": 37.464115,
        "lng": 126.977754
    },
    {
        "code": 1162064500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "서원동",
        "lat": 37.479662,
        "lng": 126.931311
    },
    {
        "code": 1162065500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "신원동",
        "lat": 37.481698,
        "lng": 126.927268
    },
    {
        "code": 1162066500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "서림동",
        "lat": 37.475037,
        "lng": 126.934972
    },
    {
        "code": 1162068500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "신사동",
        "lat": 37.485479,
        "lng": 126.918044
    },
    {
        "code": 1162069500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "신림동",
        "lat": 37.463204,
        "lng": 126.935795
    },
    {
        "code": 1162071500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "난향동",
        "lat": 37.461554,
        "lng": 126.918807
    },
    {
        "code": 1162072500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "조원동",
        "lat": 37.482592,
        "lng": 126.907808
    },
    {
        "code": 1162073500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "대학동",
        "lat": 37.470593,
        "lng": 126.936855
    },
    {
        "code": 1162074500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "삼성동",
        "lat": 37.470135,
        "lng": 126.933007
    },
    {
        "code": 1162076500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "미성동",
        "lat": 37.476348,
        "lng": 126.915677
    },
    {
        "code": 1162077500,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "난곡동",
        "lat": 37.471024,
        "lng": 126.921689
    },
    {
        "code": 1165051000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "서초1동",
        "lat": 37.490077,
        "lng": 127.019534
    },
    {
        "code": 1165052000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "서초2동",
        "lat": 37.492051,
        "lng": 127.025083
    },
    {
        "code": 1165053000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "서초3동",
        "lat": 37.483594,
        "lng": 127.012518
    },
    {
        "code": 1165053100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "서초4동",
        "lat": 37.502624,
        "lng": 127.022175
    },
    {
        "code": 1165054000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "잠원동",
        "lat": 37.515102,
        "lng": 127.009083
    },
    {
        "code": 1165055000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포본동",
        "lat": 37.50049,
        "lng": 126.986012
    },
    {
        "code": 1165056000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포1동",
        "lat": 37.505136,
        "lng": 127.013354
    },
    {
        "code": 1165057000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포2동",
        "lat": 37.504644,
        "lng": 126.994495
    },
    {
        "code": 1165058000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포3동",
        "lat": 37.512027,
        "lng": 127.005572
    },
    {
        "code": 1165058100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포4동",
        "lat": 37.498651,
        "lng": 126.995469
    },
    {
        "code": 1165059000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배본동",
        "lat": 37.494151,
        "lng": 126.988688
    },
    {
        "code": 1165060000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배1동",
        "lat": 37.483406,
        "lng": 126.994486
    },
    {
        "code": 1165061000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배2동",
        "lat": 37.479887,
        "lng": 126.985499
    },
    {
        "code": 1165062000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배3동",
        "lat": 37.480255,
        "lng": 126.999028
    },
    {
        "code": 1165062100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배4동",
        "lat": 37.489624,
        "lng": 126.991383
    },
    {
        "code": 1165065100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "양재1동",
        "lat": 37.471701,
        "lng": 127.02675
    },
    {
        "code": 1165065200,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "양재2동",
        "lat": 37.47062,
        "lng": 127.041076
    },
    {
        "code": 1165066000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "내곡동",
        "lat": 37.458154,
        "lng": 127.076727
    },
    {
        "code": 1168051000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "신사동",
        "lat": 37.523807,
        "lng": 127.026492
    },
    {
        "code": 1168052100,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "논현1동",
        "lat": 37.511498,
        "lng": 127.028552
    },
    {
        "code": 1168053100,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "논현2동",
        "lat": 37.517353,
        "lng": 127.037164
    },
    {
        "code": 1168054500,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "압구정동",
        "lat": 37.530734,
        "lng": 127.028461
    },
    {
        "code": 1168056500,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "청담동",
        "lat": 37.524399,
        "lng": 127.050457
    },
    {
        "code": 1168058000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "삼성1동",
        "lat": 37.514315,
        "lng": 127.062824
    },
    {
        "code": 1168059000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "삼성2동",
        "lat": 37.511399,
        "lng": 127.046255
    },
    {
        "code": 1168060000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "대치1동",
        "lat": 37.493236,
        "lng": 127.056687
    },
    {
        "code": 1168061000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "대치2동",
        "lat": 37.502252,
        "lng": 127.064162
    },
    {
        "code": 1168063000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "대치4동",
        "lat": 37.49975,
        "lng": 127.057747
    },
    {
        "code": 1168064000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "역삼1동",
        "lat": 37.495361,
        "lng": 127.033058
    },
    {
        "code": 1168065000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "역삼2동",
        "lat": 37.49604,
        "lng": 127.04679
    },
    {
        "code": 1168065500,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "도곡1동",
        "lat": 37.490945,
        "lng": 127.038475
    },
    {
        "code": 1168065600,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "도곡2동",
        "lat": 37.483818,
        "lng": 127.046346
    },
    {
        "code": 1168066000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "개포1동",
        "lat": 37.481925,
        "lng": 127.057439
    },
    {
        "code": 1168067000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "개포2동",
        "lat": 37.489766,
        "lng": 127.069056
    },
    {
        "code": 1168069000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "개포4동",
        "lat": 37.478833,
        "lng": 127.051599
    },
    {
        "code": 1168070000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "세곡동",
        "lat": 37.464369,
        "lng": 127.104583
    },
    {
        "code": 1168072000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "일원본동",
        "lat": 37.48336,
        "lng": 127.08648
    },
    {
        "code": 1168073000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "일원1동",
        "lat": 37.491887,
        "lng": 127.087982
    },
    {
        "code": 1168074000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "일원2동",
        "lat": 37.492172,
        "lng": 127.073696
    },
    {
        "code": 1168075000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "수서동",
        "lat": 37.485881,
        "lng": 127.096706
    },
    {
        "code": 1171051000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "풍납1동",
        "lat": 37.538055,
        "lng": 127.122215
    },
    {
        "code": 1171052000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "풍납2동",
        "lat": 37.528779,
        "lng": 127.116814
    },
    {
        "code": 1171053100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "거여1동",
        "lat": 37.496923,
        "lng": 127.143302
    },
    {
        "code": 1171053200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "거여2동",
        "lat": 37.493534,
        "lng": 127.146891
    },
    {
        "code": 1171054000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "마천1동",
        "lat": 37.496025,
        "lng": 127.149894
    },
    {
        "code": 1171055000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "마천2동",
        "lat": 37.49688,
        "lng": 127.148538
    },
    {
        "code": 1171056100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "방이1동",
        "lat": 37.511009,
        "lng": 127.123923
    },
    {
        "code": 1171056200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "방이2동",
        "lat": 37.514596,
        "lng": 127.114379
    },
    {
        "code": 1171056600,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "오륜동",
        "lat": 37.515538,
        "lng": 127.134424
    },
    {
        "code": 1171057000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "오금동",
        "lat": 37.50401,
        "lng": 127.134003
    },
    {
        "code": 1171058000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "송파1동",
        "lat": 37.50609,
        "lng": 127.109571
    },
    {
        "code": 1171059000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "송파2동",
        "lat": 37.502323,
        "lng": 127.116741
    },
    {
        "code": 1171060000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "석촌동",
        "lat": 37.50273,
        "lng": 127.102497
    },
    {
        "code": 1171061000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "삼전동",
        "lat": 37.501901,
        "lng": 127.091876
    },
    {
        "code": 1171062000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "가락본동",
        "lat": 37.495585,
        "lng": 127.12183
    },
    {
        "code": 1171063100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "가락1동",
        "lat": 37.496537,
        "lng": 127.105726
    },
    {
        "code": 1171063200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "가락2동",
        "lat": 37.498639,
        "lng": 127.126488
    },
    {
        "code": 1171064100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "문정1동",
        "lat": 37.490024,
        "lng": 127.124055
    },
    {
        "code": 1171064200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "문정2동",
        "lat": 37.48989,
        "lng": 127.110917
    },
    {
        "code": 1171064600,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "장지동",
        "lat": 37.477368,
        "lng": 127.131914
    },
    {
        "code": 1171064700,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "위례동",
        "lat": 37.481576,
        "lng": 127.140424
    },
    {
        "code": 1171065000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실본동",
        "lat": 37.506167,
        "lng": 127.084325
    },
    {
        "code": 1171067000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실2동",
        "lat": 37.511956,
        "lng": 127.088281
    },
    {
        "code": 1171068000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실3동",
        "lat": 37.513354,
        "lng": 127.094316
    },
    {
        "code": 1171069000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실4동",
        "lat": 37.520419,
        "lng": 127.112285
    },
    {
        "code": 1171071000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실6동",
        "lat": 37.518133,
        "lng": 127.100683
    },
    {
        "code": 1171072000,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실7동",
        "lat": 37.508661,
        "lng": 127.07709
    },
    {
        "code": 1174051500,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "강일동",
        "lat": 37.567438,
        "lng": 127.173364
    },
    {
        "code": 1174052000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "상일동",
        "lat": 37.550583,
        "lng": 127.168797
    },
    {
        "code": 1174053000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "명일제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174054000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "명일제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174055000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "고덕제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174056000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "고덕제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174057000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "암사제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174058000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "암사제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174059000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "암사제3동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174060000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "천호제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174061000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "천호제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174062000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "천호제3동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174064000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "성내제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174065000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "성내제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174066000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "성내제3동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174068500,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "길동",
        "lat": 37.539616,
        "lng": 127.145914
    },
    {
        "code": 1174069000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "둔촌제1동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1174070000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "둔촌제2동",
        "lat": 37.53012,
        "lng": 127.123858
    },
    {
        "code": 1111010100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "청운동",
        "lat": 37.587111,
        "lng": 126.969069
    },
    {
        "code": 1111010200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "신교동",
        "lat": 37.583911,
        "lng": 126.968354
    },
    {
        "code": 1111010300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "궁정동",
        "lat": 37.584381,
        "lng": 126.971489
    },
    {
        "code": 1111010400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "효자동",
        "lat": 37.582416,
        "lng": 126.97167
    },
    {
        "code": 1111010500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "창성동",
        "lat": 37.580363,
        "lng": 126.972065
    },
    {
        "code": 1111010600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "통의동",
        "lat": 37.578307,
        "lng": 126.972872
    },
    {
        "code": 1111010700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "적선동",
        "lat": 37.576122,
        "lng": 126.973453
    },
    {
        "code": 1111010800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "통인동",
        "lat": 37.580141,
        "lng": 126.970127
    },
    {
        "code": 1111010900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "누상동",
        "lat": 37.580478,
        "lng": 126.965838
    },
    {
        "code": 1111011000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "누하동",
        "lat": 37.579423,
        "lng": 126.968521
    },
    {
        "code": 1111011100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "옥인동",
        "lat": 37.582175,
        "lng": 126.967275
    },
    {
        "code": 1111011200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "체부동",
        "lat": 37.577645,
        "lng": 126.970877
    },
    {
        "code": 1111011300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "필운동",
        "lat": 37.577076,
        "lng": 126.968848
    },
    {
        "code": 1111011400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "내자동",
        "lat": 37.575641,
        "lng": 126.971177
    },
    {
        "code": 1111011500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "사직동",
        "lat": 37.573711,
        "lng": 126.966295
    },
    {
        "code": 1111011600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "도렴동",
        "lat": 37.572445,
        "lng": 126.975158
    },
    {
        "code": 1111011700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "당주동",
        "lat": 37.571429,
        "lng": 126.97457
    },
    {
        "code": 1111011800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "내수동",
        "lat": 37.573113,
        "lng": 126.971819
    },
    {
        "code": 1111011900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "세종로",
        "lat": 37.572655,
        "lng": 126.976742
    },
    {
        "code": 1111012000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "신문로1가",
        "lat": 37.570366,
        "lng": 126.973968
    },
    {
        "code": 1111012100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "신문로2가",
        "lat": 37.571299,
        "lng": 126.968815
    },
    {
        "code": 1111012200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "청진동",
        "lat": 37.571886,
        "lng": 126.980676
    },
    {
        "code": 1111012300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "서린동",
        "lat": 37.569668,
        "lng": 126.979809
    },
    {
        "code": 1111012400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "수송동",
        "lat": 37.573994,
        "lng": 126.981139
    },
    {
        "code": 1111012500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "중학동",
        "lat": 37.575301,
        "lng": 126.979265
    },
    {
        "code": 1111012600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로1가",
        "lat": 37.570479,
        "lng": 126.980434
    },
    {
        "code": 1111012700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "공평동",
        "lat": 37.571692,
        "lng": 126.982996
    },
    {
        "code": 1111012800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "관훈동",
        "lat": 37.574336,
        "lng": 126.984857
    },
    {
        "code": 1111012900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "견지동",
        "lat": 37.573529,
        "lng": 126.983065
    },
    {
        "code": 1111013000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "와룡동",
        "lat": 37.57695,
        "lng": 126.991141
    },
    {
        "code": 1111013100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "권농동",
        "lat": 37.575619,
        "lng": 126.991498
    },
    {
        "code": 1111013200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "운니동",
        "lat": 37.576159,
        "lng": 126.989206
    },
    {
        "code": 1111013300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "익선동",
        "lat": 37.574082,
        "lng": 126.989819
    },
    {
        "code": 1111013400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "경운동",
        "lat": 37.574538,
        "lng": 126.986664
    },
    {
        "code": 1111013500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "관철동",
        "lat": 37.569182,
        "lng": 126.986053
    },
    {
        "code": 1111013600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "인사동",
        "lat": 37.571837,
        "lng": 126.986206
    },
    {
        "code": 1111013700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "낙원동",
        "lat": 37.572558,
        "lng": 126.988732
    },
    {
        "code": 1111013800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로2가",
        "lat": 37.570195,
        "lng": 126.986454
    },
    {
        "code": 1111013900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "팔판동",
        "lat": 37.582882,
        "lng": 126.980836
    },
    {
        "code": 1111014000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "삼청동",
        "lat": 37.585404,
        "lng": 126.982123
    },
    {
        "code": 1111014100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "안국동",
        "lat": 37.577515,
        "lng": 126.983939
    },
    {
        "code": 1111014200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "소격동",
        "lat": 37.580122,
        "lng": 126.981424
    },
    {
        "code": 1111014300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "화동",
        "lat": 37.580693,
        "lng": 126.982268
    },
    {
        "code": 1111014400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "사간동",
        "lat": 37.577668,
        "lng": 126.980593
    },
    {
        "code": 1111014500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "송현동",
        "lat": 37.577322,
        "lng": 126.982187
    },
    {
        "code": 1111014600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "가회동",
        "lat": 37.582235,
        "lng": 126.984666
    },
    {
        "code": 1111014700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "재동",
        "lat": 37.578363,
        "lng": 126.985575
    },
    {
        "code": 1111014800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "계동",
        "lat": 37.581321,
        "lng": 126.987016
    },
    {
        "code": 1111014900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "원서동",
        "lat": 37.581687,
        "lng": 126.988696
    },
    {
        "code": 1111015000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "훈정동",
        "lat": 37.57098,
        "lng": 126.995131
    },
    {
        "code": 1111015100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "묘동",
        "lat": 37.572598,
        "lng": 126.991715
    },
    {
        "code": 1111015200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "봉익동",
        "lat": 37.572398,
        "lng": 126.992873
    },
    {
        "code": 1111015300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "돈의동",
        "lat": 37.572085,
        "lng": 126.990736
    },
    {
        "code": 1111015400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "장사동",
        "lat": 37.569411,
        "lng": 126.99383
    },
    {
        "code": 1111015500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "관수동",
        "lat": 37.5692,
        "lng": 126.990527
    },
    {
        "code": 1111015600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로3가",
        "lat": 37.570337,
        "lng": 126.992072
    },
    {
        "code": 1111015700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "인의동",
        "lat": 37.573102,
        "lng": 126.997921
    },
    {
        "code": 1111015800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "예지동",
        "lat": 37.569643,
        "lng": 126.997971
    },
    {
        "code": 1111015900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "원남동",
        "lat": 37.575326,
        "lng": 126.997429
    },
    {
        "code": 1111016000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "연지동",
        "lat": 37.57359,
        "lng": 126.999447
    },
    {
        "code": 1111016100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로4가",
        "lat": 37.570618,
        "lng": 126.998135
    },
    {
        "code": 1111016200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "효제동",
        "lat": 37.572859,
        "lng": 127.003417
    },
    {
        "code": 1111016300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로5가",
        "lat": 37.57054,
        "lng": 127.003794
    },
    {
        "code": 1111016400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "종로6가",
        "lat": 37.572572,
        "lng": 127.006996
    },
    {
        "code": 1111016500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "이화동",
        "lat": 37.577066,
        "lng": 127.006358
    },
    {
        "code": 1111016600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "연건동",
        "lat": 37.5775,
        "lng": 127.000903
    },
    {
        "code": 1111016700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "충신동",
        "lat": 37.575189,
        "lng": 127.006471
    },
    {
        "code": 1111016800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "동숭동",
        "lat": 37.581654,
        "lng": 127.005083
    },
    {
        "code": 1111016900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "혜화동",
        "lat": 37.587955,
        "lng": 127.001157
    },
    {
        "code": 1111017000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "명륜1가",
        "lat": 37.588347,
        "lng": 126.997733
    },
    {
        "code": 1111017100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "명륜2가",
        "lat": 37.584521,
        "lng": 126.998638
    },
    {
        "code": 1111017200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "명륜4가",
        "lat": 37.582225,
        "lng": 127.000008
    },
    {
        "code": 1111017300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "명륜3가",
        "lat": 37.588359,
        "lng": 126.994739
    },
    {
        "code": 1111017400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "창신동",
        "lat": 37.574729,
        "lng": 127.012062
    },
    {
        "code": 1111017500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "숭인동",
        "lat": 37.575979,
        "lng": 127.018802
    },
    {
        "code": 1111017600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "교남동",
        "lat": 37.567917,
        "lng": 126.965108
    },
    {
        "code": 1111017700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "평동",
        "lat": 37.567121,
        "lng": 126.967018
    },
    {
        "code": 1111017800,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "송월동",
        "lat": 37.569779,
        "lng": 126.96576
    },
    {
        "code": 1111017900,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "홍파동",
        "lat": 37.571862,
        "lng": 126.965154
    },
    {
        "code": 1111018000,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "교북동",
        "lat": 37.572612,
        "lng": 126.960436
    },
    {
        "code": 1111018100,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "행촌동",
        "lat": 37.574294,
        "lng": 126.962469
    },
    {
        "code": 1111018200,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "구기동",
        "lat": 37.609211,
        "lng": 126.957246
    },
    {
        "code": 1111018300,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "평창동",
        "lat": 37.61042,
        "lng": 126.969837
    },
    {
        "code": 1111018400,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "부암동",
        "lat": 37.594148,
        "lng": 126.964248
    },
    {
        "code": 1111018500,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "홍지동",
        "lat": 37.60031,
        "lng": 126.957773
    },
    {
        "code": 1111018600,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "신영동",
        "lat": 37.602696,
        "lng": 126.961378
    },
    {
        "code": 1111018700,
        "sido": "서울특별시",
        "gu": "종로구",
        "dong": "무악동",
        "lat": 37.575906,
        "lng": 126.959334
    },
    {
        "code": 1114010100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "무교동",
        "lat": 37.567732,
        "lng": 126.979171
    },
    {
        "code": 1114010200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "다동",
        "lat": 37.567577,
        "lng": 126.981026
    },
    {
        "code": 1114010300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "태평로1가",
        "lat": 37.568174,
        "lng": 126.976805
    },
    {
        "code": 1114010400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로1가",
        "lat": 37.566295,
        "lng": 126.980137
    },
    {
        "code": 1114010500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로2가",
        "lat": 37.565757,
        "lng": 126.985892
    },
    {
        "code": 1114010600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남대문로1가",
        "lat": 37.567451,
        "lng": 126.982976
    },
    {
        "code": 1114010700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "삼각동",
        "lat": 37.567588,
        "lng": 126.983748
    },
    {
        "code": 1114010800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "수하동",
        "lat": 37.567242,
        "lng": 126.984082
    },
    {
        "code": 1114010900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "장교동",
        "lat": 37.567116,
        "lng": 126.987193
    },
    {
        "code": 1114011000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "수표동",
        "lat": 37.567358,
        "lng": 126.989674
    },
    {
        "code": 1114011100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "소공동",
        "lat": 37.563731,
        "lng": 126.980135
    },
    {
        "code": 1114011200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남창동",
        "lat": 37.55845,
        "lng": 126.977464
    },
    {
        "code": 1114011300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "북창동",
        "lat": 37.5625,
        "lng": 126.978056
    },
    {
        "code": 1114011400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "태평로2가",
        "lat": 37.563334,
        "lng": 126.976861
    },
    {
        "code": 1114011500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남대문로2가",
        "lat": 37.563785,
        "lng": 126.981752
    },
    {
        "code": 1114011600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남대문로3가",
        "lat": 37.561033,
        "lng": 126.978259
    },
    {
        "code": 1114011700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남대문로4가",
        "lat": 37.560635,
        "lng": 126.976863
    },
    {
        "code": 1114011800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남대문로5가",
        "lat": 37.557154,
        "lng": 126.974366
    },
    {
        "code": 1114011900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "봉래동1가",
        "lat": 37.558687,
        "lng": 126.972589
    },
    {
        "code": 1114012000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "봉래동2가",
        "lat": 37.557841,
        "lng": 126.97062
    },
    {
        "code": 1114012100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "회현동1가",
        "lat": 37.557439,
        "lng": 126.980748
    },
    {
        "code": 1114012200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "회현동2가",
        "lat": 37.558033,
        "lng": 126.983443
    },
    {
        "code": 1114012300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "회현동3가",
        "lat": 37.56006,
        "lng": 126.984082
    },
    {
        "code": 1114012400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충무로1가",
        "lat": 37.561279,
        "lng": 126.98343
    },
    {
        "code": 1114012500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충무로2가",
        "lat": 37.561903,
        "lng": 126.987942
    },
    {
        "code": 1114012600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "명동1가",
        "lat": 37.564135,
        "lng": 126.984148
    },
    {
        "code": 1114012700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "명동2가",
        "lat": 37.56288,
        "lng": 126.984505
    },
    {
        "code": 1114012800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남산동1가",
        "lat": 37.55926,
        "lng": 126.984605
    },
    {
        "code": 1114012900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남산동2가",
        "lat": 37.558534,
        "lng": 126.985813
    },
    {
        "code": 1114013000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남산동3가",
        "lat": 37.560392,
        "lng": 126.987105
    },
    {
        "code": 1114013100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "저동1가",
        "lat": 37.564362,
        "lng": 126.987314
    },
    {
        "code": 1114013200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충무로4가",
        "lat": 37.562463,
        "lng": 126.995255
    },
    {
        "code": 1114013300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충무로5가",
        "lat": 37.562923,
        "lng": 126.999921
    },
    {
        "code": 1114013400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "인현동2가",
        "lat": 37.56378,
        "lng": 126.995779
    },
    {
        "code": 1114013500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "예관동",
        "lat": 37.564357,
        "lng": 126.99822
    },
    {
        "code": 1114013600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "묵정동",
        "lat": 37.561401,
        "lng": 127.000871
    },
    {
        "code": 1114013700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "필동1가",
        "lat": 37.560992,
        "lng": 126.992264
    },
    {
        "code": 1114013800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "필동2가",
        "lat": 37.55896,
        "lng": 126.995427
    },
    {
        "code": 1114013900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "필동3가",
        "lat": 37.559069,
        "lng": 126.997152
    },
    {
        "code": 1114014000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "남학동",
        "lat": 37.56041,
        "lng": 126.99086
    },
    {
        "code": 1114014100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "주자동",
        "lat": 37.560755,
        "lng": 126.98985
    },
    {
        "code": 1114014200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "예장동",
        "lat": 37.557397,
        "lng": 126.990492
    },
    {
        "code": 1114014300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "장충동1가",
        "lat": 37.561397,
        "lng": 127.007583
    },
    {
        "code": 1114014400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "장충동2가",
        "lat": 37.55991,
        "lng": 127.00256
    },
    {
        "code": 1114014500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "광희동1가",
        "lat": 37.565194,
        "lng": 127.005069
    },
    {
        "code": 1114014600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "광희동2가",
        "lat": 37.563799,
        "lng": 127.008655
    },
    {
        "code": 1114014700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "쌍림동",
        "lat": 37.563176,
        "lng": 127.004625
    },
    {
        "code": 1114014800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로6가",
        "lat": 37.566868,
        "lng": 127.00631
    },
    {
        "code": 1114014900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로7가",
        "lat": 37.565117,
        "lng": 127.009288
    },
    {
        "code": 1114015000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로4가",
        "lat": 37.566587,
        "lng": 126.998139
    },
    {
        "code": 1114015100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로5가",
        "lat": 37.566455,
        "lng": 127.001862
    },
    {
        "code": 1114015200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "주교동",
        "lat": 37.568136,
        "lng": 126.999252
    },
    {
        "code": 1114015300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "방산동",
        "lat": 37.568469,
        "lng": 127.001903
    },
    {
        "code": 1114015400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "오장동",
        "lat": 37.564685,
        "lng": 127.0007
    },
    {
        "code": 1114015500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "을지로3가",
        "lat": 37.566292,
        "lng": 126.992337
    },
    {
        "code": 1114015600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "입정동",
        "lat": 37.567709,
        "lng": 126.993042
    },
    {
        "code": 1114015700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "산림동",
        "lat": 37.567564,
        "lng": 126.996085
    },
    {
        "code": 1114015800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충무로3가",
        "lat": 37.562676,
        "lng": 126.991874
    },
    {
        "code": 1114015900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "초동",
        "lat": 37.564226,
        "lng": 126.992375
    },
    {
        "code": 1114016000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "인현동1가",
        "lat": 37.564472,
        "lng": 126.994232
    },
    {
        "code": 1114016100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "저동2가",
        "lat": 37.564385,
        "lng": 126.990257
    },
    {
        "code": 1114016200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "신당동",
        "lat": 37.559441,
        "lng": 127.013427
    },
    {
        "code": 1114016300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "흥인동",
        "lat": 37.566792,
        "lng": 127.016839
    },
    {
        "code": 1114016400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "무학동",
        "lat": 37.564149,
        "lng": 127.015091
    },
    {
        "code": 1114016500,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "황학동",
        "lat": 37.56794,
        "lng": 127.020367
    },
    {
        "code": 1114016600,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "서소문동",
        "lat": 37.563075,
        "lng": 126.974142
    },
    {
        "code": 1114016700,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "정동",
        "lat": 37.566905,
        "lng": 126.972078
    },
    {
        "code": 1114016800,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "순화동",
        "lat": 37.562471,
        "lng": 126.970566
    },
    {
        "code": 1114016900,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "의주로1가",
        "lat": 37.564783,
        "lng": 126.968176
    },
    {
        "code": 1114017000,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "충정로1가",
        "lat": 37.567411,
        "lng": 126.968642
    },
    {
        "code": 1114017100,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "중림동",
        "lat": 37.558715,
        "lng": 126.96584
    },
    {
        "code": 1114017200,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "의주로2가",
        "lat": 37.559382,
        "lng": 126.969985
    },
    {
        "code": 1114017300,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "만리동1가",
        "lat": 37.556408,
        "lng": 126.967319
    },
    {
        "code": 1114017400,
        "sido": "서울특별시",
        "gu": "중구",
        "dong": "만리동2가",
        "lat": 37.553986,
        "lng": 126.96415
    },
    {
        "code": 1117010100,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "후암동",
        "lat": 37.549171,
        "lng": 126.978938
    },
    {
        "code": 1117010200,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동2가",
        "lat": 37.544184,
        "lng": 126.98511
    },
    {
        "code": 1117010300,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동4가",
        "lat": 37.534964,
        "lng": 126.986876
    },
    {
        "code": 1117010400,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "갈월동",
        "lat": 37.546267,
        "lng": 126.972941
    },
    {
        "code": 1117010500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "남영동",
        "lat": 37.542934,
        "lng": 126.97341
    },
    {
        "code": 1117010600,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동1가",
        "lat": 37.534968,
        "lng": 126.977282
    },
    {
        "code": 1117010700,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "동자동",
        "lat": 37.551535,
        "lng": 126.974137
    },
    {
        "code": 1117010800,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "서계동",
        "lat": 37.552113,
        "lng": 126.966089
    },
    {
        "code": 1117010900,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "청파동1가",
        "lat": 37.54887,
        "lng": 126.967046
    },
    {
        "code": 1117011000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "청파동2가",
        "lat": 37.546067,
        "lng": 126.967745
    },
    {
        "code": 1117011100,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "청파동3가",
        "lat": 37.543407,
        "lng": 126.968276
    },
    {
        "code": 1117011200,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로1가",
        "lat": 37.539977,
        "lng": 126.966998
    },
    {
        "code": 1117011300,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로2가",
        "lat": 37.536307,
        "lng": 126.963165
    },
    {
        "code": 1117011400,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "신창동",
        "lat": 37.53573,
        "lng": 126.955199
    },
    {
        "code": 1117011500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "산천동",
        "lat": 37.535428,
        "lng": 126.952396
    },
    {
        "code": 1117011600,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "청암동",
        "lat": 37.53504,
        "lng": 126.9465
    },
    {
        "code": 1117011700,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로3가",
        "lat": 37.53414,
        "lng": 126.956862
    },
    {
        "code": 1117011800,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "원효로4가",
        "lat": 37.533504,
        "lng": 126.952763
    },
    {
        "code": 1117011900,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "효창동",
        "lat": 37.543291,
        "lng": 126.961214
    },
    {
        "code": 1117012000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "도원동",
        "lat": 37.539188,
        "lng": 126.95794
    },
    {
        "code": 1117012100,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용문동",
        "lat": 37.537571,
        "lng": 126.959936
    },
    {
        "code": 1117012200,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "문배동",
        "lat": 37.536423,
        "lng": 126.969346
    },
    {
        "code": 1117012300,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "신계동",
        "lat": 37.534581,
        "lng": 126.964922
    },
    {
        "code": 1117012400,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한강로1가",
        "lat": 37.534384,
        "lng": 126.973765
    },
    {
        "code": 1117012500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한강로2가",
        "lat": 37.531715,
        "lng": 126.970242
    },
    {
        "code": 1117012600,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동3가",
        "lat": 37.533189,
        "lng": 126.974319
    },
    {
        "code": 1117012700,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동5가",
        "lat": 37.527123,
        "lng": 126.97083
    },
    {
        "code": 1117012800,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한강로3가",
        "lat": 37.524659,
        "lng": 126.963299
    },
    {
        "code": 1117012900,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이촌동",
        "lat": 37.524133,
        "lng": 126.961987
    },
    {
        "code": 1117013000,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "이태원동",
        "lat": 37.5367,
        "lng": 126.992151
    },
    {
        "code": 1117013100,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "한남동",
        "lat": 37.533889,
        "lng": 127.003955
    },
    {
        "code": 1117013200,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "동빙고동",
        "lat": 37.523599,
        "lng": 126.995949
    },
    {
        "code": 1117013300,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "서빙고동",
        "lat": 37.521042,
        "lng": 126.993023
    },
    {
        "code": 1117013400,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "주성동",
        "lat": 37.522591,
        "lng": 126.998382
    },
    {
        "code": 1117013500,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "용산동6가",
        "lat": 37.51915,
        "lng": 126.983164
    },
    {
        "code": 1117013600,
        "sido": "서울특별시",
        "gu": "용산구",
        "dong": "보광동",
        "lat": 37.528858,
        "lng": 126.999766
    },
    {
        "code": 1120010100,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "상왕십리동",
        "lat": 37.569332,
        "lng": 127.025349
    },
    {
        "code": 1120010200,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "하왕십리동",
        "lat": 37.562462,
        "lng": 127.029586
    },
    {
        "code": 1120010300,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "홍익동",
        "lat": 37.566144,
        "lng": 127.032921
    },
    {
        "code": 1120010400,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "도선동",
        "lat": 37.563897,
        "lng": 127.033258
    },
    {
        "code": 1120010500,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "마장동",
        "lat": 37.567305,
        "lng": 127.039084
    },
    {
        "code": 1120010600,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "사근동",
        "lat": 37.561371,
        "lng": 127.046789
    },
    {
        "code": 1120010700,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "행당동",
        "lat": 37.559076,
        "lng": 127.035869
    },
    {
        "code": 1120010800,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "응봉동",
        "lat": 37.551169,
        "lng": 127.031462
    },
    {
        "code": 1120010900,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호동1가",
        "lat": 37.554697,
        "lng": 127.023412
    },
    {
        "code": 1120011000,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호동2가",
        "lat": 37.552482,
        "lng": 127.020133
    },
    {
        "code": 1120011100,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호동3가",
        "lat": 37.549504,
        "lng": 127.021812
    },
    {
        "code": 1120011200,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "금호동4가",
        "lat": 37.547468,
        "lng": 127.02006
    },
    {
        "code": 1120011300,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "옥수동",
        "lat": 37.542764,
        "lng": 127.014662
    },
    {
        "code": 1120011400,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수동1가",
        "lat": 37.544661,
        "lng": 127.046772
    },
    {
        "code": 1120011500,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "성수동2가",
        "lat": 37.541123,
        "lng": 127.056125
    },
    {
        "code": 1120011800,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "송정동",
        "lat": 37.552124,
        "lng": 127.067652
    },
    {
        "code": 1120012200,
        "sido": "서울특별시",
        "gu": "성동구",
        "dong": "용답동",
        "lat": 37.564354,
        "lng": 127.052795
    },
    {
        "code": 1121510100,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "중곡동",
        "lat": 37.561882,
        "lng": 127.084745
    },
    {
        "code": 1121510200,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "능동",
        "lat": 37.553729,
        "lng": 127.082381
    },
    {
        "code": 1121510300,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "구의동",
        "lat": 37.544655,
        "lng": 127.089493
    },
    {
        "code": 1121510400,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "광장동",
        "lat": 37.546992,
        "lng": 127.103926
    },
    {
        "code": 1121510500,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "자양동",
        "lat": 37.534692,
        "lng": 127.074327
    },
    {
        "code": 1121510700,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "화양동",
        "lat": 37.544427,
        "lng": 127.070047
    },
    {
        "code": 1121510900,
        "sido": "서울특별시",
        "gu": "광진구",
        "dong": "군자동",
        "lat": 37.553878,
        "lng": 127.073516
    },
    {
        "code": 1123010100,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "신설동",
        "lat": 37.575204,
        "lng": 127.026023
    },
    {
        "code": 1123010200,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "용두동",
        "lat": 37.576328,
        "lng": 127.034621
    },
    {
        "code": 1123010300,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "제기동",
        "lat": 37.584134,
        "lng": 127.03796
    },
    {
        "code": 1123010400,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "전농동",
        "lat": 37.579797,
        "lng": 127.053848
    },
    {
        "code": 1123010500,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "답십리동",
        "lat": 37.569883,
        "lng": 127.056757
    },
    {
        "code": 1123010600,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "장안동",
        "lat": 37.570749,
        "lng": 127.068233
    },
    {
        "code": 1123010700,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "청량리동",
        "lat": 37.586643,
        "lng": 127.044621
    },
    {
        "code": 1123010800,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "회기동",
        "lat": 37.591447,
        "lng": 127.051971
    },
    {
        "code": 1123010900,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "휘경동",
        "lat": 37.589592,
        "lng": 127.061228
    },
    {
        "code": 1123011000,
        "sido": "서울특별시",
        "gu": "동대문구",
        "dong": "이문동",
        "lat": 37.598851,
        "lng": 127.062465
    },
    {
        "code": 1126010100,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "면목동",
        "lat": 37.58442,
        "lng": 127.086487
    },
    {
        "code": 1126010200,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "상봉동",
        "lat": 37.596267,
        "lng": 127.085704
    },
    {
        "code": 1126010300,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "중화동",
        "lat": 37.59975,
        "lng": 127.077718
    },
    {
        "code": 1126010400,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "묵동",
        "lat": 37.611266,
        "lng": 127.077829
    },
    {
        "code": 1126010500,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "망우동",
        "lat": 37.596678,
        "lng": 127.100419
    },
    {
        "code": 1126010600,
        "sido": "서울특별시",
        "gu": "중랑구",
        "dong": "신내동",
        "lat": 37.607237,
        "lng": 127.098924
    },
    {
        "code": 1129010100,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "성북동",
        "lat": 37.594567,
        "lng": 126.997514
    },
    {
        "code": 1129010200,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "성북동1가",
        "lat": 37.589374,
        "lng": 127.003652
    },
    {
        "code": 1129010300,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "돈암동",
        "lat": 37.598415,
        "lng": 127.020288
    },
    {
        "code": 1129010400,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동1가",
        "lat": 37.589858,
        "lng": 127.00746
    },
    {
        "code": 1129010500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동2가",
        "lat": 37.588696,
        "lng": 127.008412
    },
    {
        "code": 1129010600,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동3가",
        "lat": 37.590052,
        "lng": 127.010816
    },
    {
        "code": 1129010700,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동4가",
        "lat": 37.591541,
        "lng": 127.010756
    },
    {
        "code": 1129010800,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동5가",
        "lat": 37.591666,
        "lng": 127.01559
    },
    {
        "code": 1129010900,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동6가",
        "lat": 37.59377,
        "lng": 127.014858
    },
    {
        "code": 1129011000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동소문동7가",
        "lat": 37.596303,
        "lng": 127.014721
    },
    {
        "code": 1129011100,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동1가",
        "lat": 37.585059,
        "lng": 127.007942
    },
    {
        "code": 1129011200,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동2가",
        "lat": 37.585259,
        "lng": 127.010538
    },
    {
        "code": 1129011300,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동3가",
        "lat": 37.586298,
        "lng": 127.012802
    },
    {
        "code": 1129011400,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동4가",
        "lat": 37.589818,
        "lng": 127.013135
    },
    {
        "code": 1129011500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "삼선동5가",
        "lat": 37.58825,
        "lng": 127.015789
    },
    {
        "code": 1129011600,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동1가",
        "lat": 37.592065,
        "lng": 127.01826
    },
    {
        "code": 1129011700,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동2가",
        "lat": 37.590091,
        "lng": 127.020053
    },
    {
        "code": 1129011800,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동3가",
        "lat": 37.594108,
        "lng": 127.020597
    },
    {
        "code": 1129011900,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동4가",
        "lat": 37.595087,
        "lng": 127.018541
    },
    {
        "code": 1129012000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "동선동5가",
        "lat": 37.596185,
        "lng": 127.016726
    },
    {
        "code": 1129012100,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동1가",
        "lat": 37.588393,
        "lng": 127.021456
    },
    {
        "code": 1129012200,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동2가",
        "lat": 37.586638,
        "lng": 127.022973
    },
    {
        "code": 1129012300,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동3가",
        "lat": 37.583915,
        "lng": 127.02328
    },
    {
        "code": 1129012400,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동4가",
        "lat": 37.580811,
        "lng": 127.024633
    },
    {
        "code": 1129012500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "안암동5가",
        "lat": 37.586083,
        "lng": 127.029493
    },
    {
        "code": 1129012600,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동4가",
        "lat": 37.582645,
        "lng": 127.021437
    },
    {
        "code": 1129012700,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동5가",
        "lat": 37.582359,
        "lng": 127.019936
    },
    {
        "code": 1129012800,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동6가",
        "lat": 37.580444,
        "lng": 127.019694
    },
    {
        "code": 1129012900,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동7가",
        "lat": 37.580033,
        "lng": 127.02182
    },
    {
        "code": 1129013000,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동1가",
        "lat": 37.586226,
        "lng": 127.019779
    },
    {
        "code": 1129013100,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동2가",
        "lat": 37.585602,
        "lng": 127.018306
    },
    {
        "code": 1129013200,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "보문동3가",
        "lat": 37.584277,
        "lng": 127.017551
    },
    {
        "code": 1129013300,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "정릉동",
        "lat": 37.608442,
        "lng": 127.008288
    },
    {
        "code": 1129013400,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "길음동",
        "lat": 37.607316,
        "lng": 127.02476
    },
    {
        "code": 1129013500,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "종암동",
        "lat": 37.597405,
        "lng": 127.035032
    },
    {
        "code": 1129013600,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "하월곡동",
        "lat": 37.605662,
        "lng": 127.035753
    },
    {
        "code": 1129013700,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "상월곡동",
        "lat": 37.606723,
        "lng": 127.048393
    },
    {
        "code": 1129013800,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "장위동",
        "lat": 37.614232,
        "lng": 127.050118
    },
    {
        "code": 1129013900,
        "sido": "서울특별시",
        "gu": "성북구",
        "dong": "석관동",
        "lat": 37.608823,
        "lng": 127.060712
    },
    {
        "code": 1130510100,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "미아동",
        "lat": 37.621298,
        "lng": 127.024902
    },
    {
        "code": 1130510200,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "번동",
        "lat": 37.635123,
        "lng": 127.032072
    },
    {
        "code": 1130510300,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "수유동",
        "lat": 37.639388,
        "lng": 127.017183
    },
    {
        "code": 1130510400,
        "sido": "서울특별시",
        "gu": "강북구",
        "dong": "우이동",
        "lat": 37.658785,
        "lng": 127.01119
    },
    {
        "code": 1132010500,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "쌍문동",
        "lat": 37.652731,
        "lng": 127.02871
    },
    {
        "code": 1132010600,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "방학동",
        "lat": 37.666224,
        "lng": 127.03665
    },
    {
        "code": 1132010700,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "창동",
        "lat": 37.64344,
        "lng": 127.039413
    },
    {
        "code": 1132010800,
        "sido": "서울특별시",
        "gu": "도봉구",
        "dong": "도봉동",
        "lat": 37.678103,
        "lng": 127.043249
    },
    {
        "code": 1135010200,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "월계동",
        "lat": 37.622192,
        "lng": 127.05865
    },
    {
        "code": 1135010300,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "공릉동",
        "lat": 37.623601,
        "lng": 127.077388
    },
    {
        "code": 1135010400,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "하계동",
        "lat": 37.63689,
        "lng": 127.073145
    },
    {
        "code": 1135010500,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "상계동",
        "lat": 37.6671,
        "lng": 127.072189
    },
    {
        "code": 1135010600,
        "sido": "서울특별시",
        "gu": "노원구",
        "dong": "중계동",
        "lat": 37.646989,
        "lng": 127.082411
    },
    {
        "code": 1138010100,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "수색동",
        "lat": 37.585121,
        "lng": 126.89426
    },
    {
        "code": 1138010200,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "녹번동",
        "lat": 37.605253,
        "lng": 126.929107
    },
    {
        "code": 1138010300,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "불광동",
        "lat": 37.620465,
        "lng": 126.927435
    },
    {
        "code": 1138010400,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "갈현동",
        "lat": 37.621223,
        "lng": 126.914993
    },
    {
        "code": 1138010500,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "구산동",
        "lat": 37.61139,
        "lng": 126.910297
    },
    {
        "code": 1138010600,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "대조동",
        "lat": 37.612276,
        "lng": 126.92285
    },
    {
        "code": 1138010700,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "응암동",
        "lat": 37.592677,
        "lng": 126.920257
    },
    {
        "code": 1138010800,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "역촌동",
        "lat": 37.60477,
        "lng": 126.915663
    },
    {
        "code": 1138010900,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "신사동",
        "lat": 37.595711,
        "lng": 126.909195
    },
    {
        "code": 1138011000,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "증산동",
        "lat": 37.584724,
        "lng": 126.907223
    },
    {
        "code": 1138011400,
        "sido": "서울특별시",
        "gu": "은평구",
        "dong": "진관동",
        "lat": 37.644355,
        "lng": 126.936524
    },
    {
        "code": 1141010100,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "충정로2가",
        "lat": 37.565192,
        "lng": 126.964529
    },
    {
        "code": 1141010200,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "충정로3가",
        "lat": 37.562611,
        "lng": 126.961351
    },
    {
        "code": 1141010300,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "합동",
        "lat": 37.561567,
        "lng": 126.966566
    },
    {
        "code": 1141010400,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "미근동",
        "lat": 37.563162,
        "lng": 126.967026
    },
    {
        "code": 1141010500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "냉천동",
        "lat": 37.567257,
        "lng": 126.96389
    },
    {
        "code": 1141010600,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "천연동",
        "lat": 37.568285,
        "lng": 126.960571
    },
    {
        "code": 1141010700,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "옥천동",
        "lat": 37.569686,
        "lng": 126.96089
    },
    {
        "code": 1141010800,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "영천동",
        "lat": 37.570759,
        "lng": 126.96046
    },
    {
        "code": 1141010900,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "현저동",
        "lat": 37.576486,
        "lng": 126.95422
    },
    {
        "code": 1141011000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "북아현동",
        "lat": 37.561666,
        "lng": 126.95617
    },
    {
        "code": 1141011100,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍제동",
        "lat": 37.588734,
        "lng": 126.946107
    },
    {
        "code": 1141011200,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "대현동",
        "lat": 37.558086,
        "lng": 126.944504
    },
    {
        "code": 1141011300,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "대신동",
        "lat": 37.566438,
        "lng": 126.945199
    },
    {
        "code": 1141011400,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "신촌동",
        "lat": 37.568134,
        "lng": 126.946792
    },
    {
        "code": 1141011500,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "봉원동",
        "lat": 37.569952,
        "lng": 126.947063
    },
    {
        "code": 1141011600,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "창천동",
        "lat": 37.558584,
        "lng": 126.934113
    },
    {
        "code": 1141011700,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "연희동",
        "lat": 37.570389,
        "lng": 126.929628
    },
    {
        "code": 1141011800,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "홍은동",
        "lat": 37.59156,
        "lng": 126.938775
    },
    {
        "code": 1141011900,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "북가좌동",
        "lat": 37.580344,
        "lng": 126.912261
    },
    {
        "code": 1141012000,
        "sido": "서울특별시",
        "gu": "서대문구",
        "dong": "남가좌동",
        "lat": 37.577538,
        "lng": 126.922311
    },
    {
        "code": 1144010100,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "아현동",
        "lat": 37.555483,
        "lng": 126.955998
    },
    {
        "code": 1144010200,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "공덕동",
        "lat": 37.548828,
        "lng": 126.959006
    },
    {
        "code": 1144010300,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "신공덕동",
        "lat": 37.544374,
        "lng": 126.956854
    },
    {
        "code": 1144010400,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "도화동",
        "lat": 37.539966,
        "lng": 126.95
    },
    {
        "code": 1144010500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "용강동",
        "lat": 37.541967,
        "lng": 126.940414
    },
    {
        "code": 1144010600,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "토정동",
        "lat": 37.54035,
        "lng": 126.940474
    },
    {
        "code": 1144010700,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "마포동",
        "lat": 37.537003,
        "lng": 126.945323
    },
    {
        "code": 1144010800,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "대흥동",
        "lat": 37.551055,
        "lng": 126.943731
    },
    {
        "code": 1144010900,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "염리동",
        "lat": 37.551546,
        "lng": 126.947279
    },
    {
        "code": 1144011000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "노고산동",
        "lat": 37.554402,
        "lng": 126.938556
    },
    {
        "code": 1144011100,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "신수동",
        "lat": 37.547351,
        "lng": 126.937074
    },
    {
        "code": 1144011200,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "현석동",
        "lat": 37.54384,
        "lng": 126.933903
    },
    {
        "code": 1144011300,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "구수동",
        "lat": 37.545679,
        "lng": 126.932695
    },
    {
        "code": 1144011400,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "창전동",
        "lat": 37.550966,
        "lng": 126.929835
    },
    {
        "code": 1144011500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "상수동",
        "lat": 37.547431,
        "lng": 126.922946
    },
    {
        "code": 1144011600,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "하중동",
        "lat": 37.54575,
        "lng": 126.928674
    },
    {
        "code": 1144011700,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "신정동",
        "lat": 37.54421,
        "lng": 126.930866
    },
    {
        "code": 1144011800,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "당인동",
        "lat": 37.54625,
        "lng": 126.920245
    },
    {
        "code": 1144012000,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "서교동",
        "lat": 37.55391,
        "lng": 126.918985
    },
    {
        "code": 1144012100,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "동교동",
        "lat": 37.55765,
        "lng": 126.925663
    },
    {
        "code": 1144012200,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "합정동",
        "lat": 37.549046,
        "lng": 126.911558
    },
    {
        "code": 1144012300,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "망원동",
        "lat": 37.55662,
        "lng": 126.903976
    },
    {
        "code": 1144012400,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "연남동",
        "lat": 37.562962,
        "lng": 126.921783
    },
    {
        "code": 1144012500,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "성산동",
        "lat": 37.563317,
        "lng": 126.91087
    },
    {
        "code": 1144012600,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "중동",
        "lat": 37.5704,
        "lng": 126.907656
    },
    {
        "code": 1144012700,
        "sido": "서울특별시",
        "gu": "마포구",
        "dong": "상암동",
        "lat": 37.577131,
        "lng": 126.893488
    },
    {
        "code": 1147010100,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신정동",
        "lat": 37.521636,
        "lng": 126.857957
    },
    {
        "code": 1147010200,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "목동",
        "lat": 37.539776,
        "lng": 126.869079
    },
    {
        "code": 1147010300,
        "sido": "서울특별시",
        "gu": "양천구",
        "dong": "신월동",
        "lat": 37.529444,
        "lng": 126.834248
    },
    {
        "code": 1150010100,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "염창동",
        "lat": 37.551366,
        "lng": 126.8713
    },
    {
        "code": 1150010200,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "등촌동",
        "lat": 37.551325,
        "lng": 126.858411
    },
    {
        "code": 1150010300,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "화곡동",
        "lat": 37.538358,
        "lng": 126.847336
    },
    {
        "code": 1150010400,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "가양동",
        "lat": 37.568238,
        "lng": 126.845059
    },
    {
        "code": 1150010500,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "마곡동",
        "lat": 37.566092,
        "lng": 126.830395
    },
    {
        "code": 1150010600,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "내발산동",
        "lat": 37.552616,
        "lng": 126.83573
    },
    {
        "code": 1150010700,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "외발산동",
        "lat": 37.546721,
        "lng": 126.821871
    },
    {
        "code": 1150010800,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "공항동",
        "lat": 37.558805,
        "lng": 126.813297
    },
    {
        "code": 1150010900,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "방화동",
        "lat": 37.569138,
        "lng": 126.812192
    },
    {
        "code": 1150011000,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "개화동",
        "lat": 37.582069,
        "lng": 126.801198
    },
    {
        "code": 1150011100,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "과해동",
        "lat": 37.560661,
        "lng": 126.794953
    },
    {
        "code": 1150011200,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "오곡동",
        "lat": 37.554615,
        "lng": 126.775768
    },
    {
        "code": 1150011300,
        "sido": "서울특별시",
        "gu": "강서구",
        "dong": "오쇠동",
        "lat": 37.541148,
        "lng": 126.796575
    },
    {
        "code": 1153010100,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "신도림동",
        "lat": 37.509454,
        "lng": 126.880425
    },
    {
        "code": 1153010200,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "구로동",
        "lat": 37.4929,
        "lng": 126.886427
    },
    {
        "code": 1153010300,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "가리봉동",
        "lat": 37.482944,
        "lng": 126.888062
    },
    {
        "code": 1153010600,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "고척동",
        "lat": 37.50356,
        "lng": 126.855829
    },
    {
        "code": 1153010700,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "개봉동",
        "lat": 37.49497,
        "lng": 126.851409
    },
    {
        "code": 1153010800,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "오류동",
        "lat": 37.494129,
        "lng": 126.840746
    },
    {
        "code": 1153010900,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "궁동",
        "lat": 37.49687,
        "lng": 126.831303
    },
    {
        "code": 1153011000,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "온수동",
        "lat": 37.493507,
        "lng": 126.820255
    },
    {
        "code": 1153011100,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "천왕동",
        "lat": 37.482016,
        "lng": 126.841789
    },
    {
        "code": 1153011200,
        "sido": "서울특별시",
        "gu": "구로구",
        "dong": "항동",
        "lat": 37.484059,
        "lng": 126.82298
    },
    {
        "code": 1154510100,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "가산동",
        "lat": 37.477598,
        "lng": 126.888291
    },
    {
        "code": 1154510200,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "독산동",
        "lat": 37.469371,
        "lng": 126.901246
    },
    {
        "code": 1154510300,
        "sido": "서울특별시",
        "gu": "금천구",
        "dong": "시흥동",
        "lat": 37.453325,
        "lng": 126.906222
    },
    {
        "code": 1156010100,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동",
        "lat": 37.513448,
        "lng": 126.907724
    },
    {
        "code": 1156010200,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동1가",
        "lat": 37.51771,
        "lng": 126.913057
    },
    {
        "code": 1156010300,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동2가",
        "lat": 37.520839,
        "lng": 126.910094
    },
    {
        "code": 1156010400,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동3가",
        "lat": 37.518155,
        "lng": 126.907998
    },
    {
        "code": 1156010500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동4가",
        "lat": 37.517981,
        "lng": 126.903506
    },
    {
        "code": 1156010600,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동5가",
        "lat": 37.521133,
        "lng": 126.907084
    },
    {
        "code": 1156010700,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동6가",
        "lat": 37.521556,
        "lng": 126.90309
    },
    {
        "code": 1156010800,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동7가",
        "lat": 37.524619,
        "lng": 126.90667
    },
    {
        "code": 1156010900,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "영등포동8가",
        "lat": 37.527015,
        "lng": 126.905253
    },
    {
        "code": 1156011000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "여의도동",
        "lat": 37.524371,
        "lng": 126.925958
    },
    {
        "code": 1156011100,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동1가",
        "lat": 37.522336,
        "lng": 126.898748
    },
    {
        "code": 1156011200,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동2가",
        "lat": 37.522967,
        "lng": 126.892688
    },
    {
        "code": 1156011300,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동3가",
        "lat": 37.525551,
        "lng": 126.896668
    },
    {
        "code": 1156011400,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동4가",
        "lat": 37.528887,
        "lng": 126.898848
    },
    {
        "code": 1156011500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동5가",
        "lat": 37.532856,
        "lng": 126.899187
    },
    {
        "code": 1156011600,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동6가",
        "lat": 37.534373,
        "lng": 126.903445
    },
    {
        "code": 1156011700,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "당산동",
        "lat": 37.528625,
        "lng": 126.907439
    },
    {
        "code": 1156011800,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "도림동",
        "lat": 37.509277,
        "lng": 126.902494
    },
    {
        "code": 1156011900,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동1가",
        "lat": 37.512364,
        "lng": 126.897201
    },
    {
        "code": 1156012000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동2가",
        "lat": 37.51329,
        "lng": 126.893779
    },
    {
        "code": 1156012100,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동3가",
        "lat": 37.516929,
        "lng": 126.897249
    },
    {
        "code": 1156012200,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동4가",
        "lat": 37.515082,
        "lng": 126.890475
    },
    {
        "code": 1156012300,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동5가",
        "lat": 37.515863,
        "lng": 126.885676
    },
    {
        "code": 1156012400,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "문래동6가",
        "lat": 37.519342,
        "lng": 126.886528
    },
    {
        "code": 1156012500,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동1가",
        "lat": 37.523571,
        "lng": 126.888814
    },
    {
        "code": 1156012600,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동2가",
        "lat": 37.52427,
        "lng": 126.884578
    },
    {
        "code": 1156012700,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동3가",
        "lat": 37.528148,
        "lng": 126.889731
    },
    {
        "code": 1156012800,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동4가",
        "lat": 37.537079,
        "lng": 126.89626
    },
    {
        "code": 1156012900,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동5가",
        "lat": 37.538964,
        "lng": 126.893422
    },
    {
        "code": 1156013000,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동6가",
        "lat": 37.542645,
        "lng": 126.890065
    },
    {
        "code": 1156013100,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양화동",
        "lat": 37.545629,
        "lng": 126.891554
    },
    {
        "code": 1156013200,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "신길동",
        "lat": 37.507088,
        "lng": 126.913103
    },
    {
        "code": 1156013300,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "대림동",
        "lat": 37.494306,
        "lng": 126.900642
    },
    {
        "code": 1156013400,
        "sido": "서울특별시",
        "gu": "영등포구",
        "dong": "양평동",
        "lat": 37.522602,
        "lng": 126.881344
    },
    {
        "code": 1159010100,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "노량진동",
        "lat": 37.510162,
        "lng": 126.940418
    },
    {
        "code": 1159010200,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도동",
        "lat": 37.499206,
        "lng": 126.940385
    },
    {
        "code": 1159010300,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "상도1동",
        "lat": 37.502889,
        "lng": 126.950645
    },
    {
        "code": 1159010400,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "본동",
        "lat": 37.511491,
        "lng": 126.954336
    },
    {
        "code": 1159010500,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "흑석동",
        "lat": 37.505964,
        "lng": 126.962578
    },
    {
        "code": 1159010600,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "동작동",
        "lat": 37.496605,
        "lng": 126.980985
    },
    {
        "code": 1159010700,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "사당동",
        "lat": 37.483974,
        "lng": 126.974999
    },
    {
        "code": 1159010800,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "대방동",
        "lat": 37.504791,
        "lng": 126.927862
    },
    {
        "code": 1159010900,
        "sido": "서울특별시",
        "gu": "동작구",
        "dong": "신대방동",
        "lat": 37.492678,
        "lng": 126.917721
    },
    {
        "code": 1162010100,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "봉천동",
        "lat": 37.482717,
        "lng": 126.949951
    },
    {
        "code": 1162010200,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "신림동",
        "lat": 37.476412,
        "lng": 126.925691
    },
    {
        "code": 1162010300,
        "sido": "서울특별시",
        "gu": "관악구",
        "dong": "남현동",
        "lat": 37.474116,
        "lng": 126.976721
    },
    {
        "code": 1165010100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "방배동",
        "lat": 37.484677,
        "lng": 126.989955
    },
    {
        "code": 1165010200,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "양재동",
        "lat": 37.474839,
        "lng": 127.041717
    },
    {
        "code": 1165010300,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "우면동",
        "lat": 37.466629,
        "lng": 127.021892
    },
    {
        "code": 1165010400,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "원지동",
        "lat": 37.446848,
        "lng": 127.053774
    },
    {
        "code": 1165010600,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "잠원동",
        "lat": 37.513667,
        "lng": 127.016166
    },
    {
        "code": 1165010700,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "반포동",
        "lat": 37.502816,
        "lng": 127.010493
    },
    {
        "code": 1165010800,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "서초동",
        "lat": 37.488514,
        "lng": 127.016222
    },
    {
        "code": 1165010900,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "내곡동",
        "lat": 37.456881,
        "lng": 127.074171
    },
    {
        "code": 1165011000,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "염곡동",
        "lat": 37.462035,
        "lng": 127.052837
    },
    {
        "code": 1165011100,
        "sido": "서울특별시",
        "gu": "서초구",
        "dong": "신원동",
        "lat": 37.447112,
        "lng": 127.058841
    },
    {
        "code": 1168010100,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "역삼동",
        "lat": 37.500335,
        "lng": 127.037596
    },
    {
        "code": 1168010300,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "개포동",
        "lat": 37.47826,
        "lng": 127.052024
    },
    {
        "code": 1168010400,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "청담동",
        "lat": 37.523404,
        "lng": 127.046858
    },
    {
        "code": 1168010500,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "삼성동",
        "lat": 37.512792,
        "lng": 127.053085
    },
    {
        "code": 1168010600,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "대치동",
        "lat": 37.501711,
        "lng": 127.057851
    },
    {
        "code": 1168010700,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "신사동",
        "lat": 37.522893,
        "lng": 127.028589
    },
    {
        "code": 1168010800,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "논현동",
        "lat": 37.513093,
        "lng": 127.031353
    },
    {
        "code": 1168011000,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "압구정동",
        "lat": 37.528752,
        "lng": 127.031766
    },
    {
        "code": 1168011100,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "세곡동",
        "lat": 37.466494,
        "lng": 127.103021
    },
    {
        "code": 1168011200,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "자곡동",
        "lat": 37.476796,
        "lng": 127.100387
    },
    {
        "code": 1168011300,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "율현동",
        "lat": 37.47145,
        "lng": 127.107251
    },
    {
        "code": 1168011400,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "일원동",
        "lat": 37.491326,
        "lng": 127.083799
    },
    {
        "code": 1168011500,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "수서동",
        "lat": 37.485767,
        "lng": 127.098137
    },
    {
        "code": 1168011800,
        "sido": "서울특별시",
        "gu": "강남구",
        "dong": "도곡동",
        "lat": 37.486509,
        "lng": 127.040738
    },
    {
        "code": 1171010100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "잠실동",
        "lat": 37.506967,
        "lng": 127.083673
    },
    {
        "code": 1171010200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "신천동",
        "lat": 37.517302,
        "lng": 127.104967
    },
    {
        "code": 1171010300,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "풍납동",
        "lat": 37.534673,
        "lng": 127.117643
    },
    {
        "code": 1171010400,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "송파동",
        "lat": 37.506058,
        "lng": 127.111216
    },
    {
        "code": 1171010500,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "석촌동",
        "lat": 37.502699,
        "lng": 127.102321
    },
    {
        "code": 1171010600,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "삼전동",
        "lat": 37.502724,
        "lng": 127.092352
    },
    {
        "code": 1171010700,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "가락동",
        "lat": 37.496486,
        "lng": 127.126006
    },
    {
        "code": 1171010800,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "문정동",
        "lat": 37.486581,
        "lng": 127.127495
    },
    {
        "code": 1171010900,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "장지동",
        "lat": 37.478077,
        "lng": 127.133269
    },
    {
        "code": 1171011100,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "방이동",
        "lat": 37.512017,
        "lng": 127.116902
    },
    {
        "code": 1171011200,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "오금동",
        "lat": 37.503984,
        "lng": 127.135124
    },
    {
        "code": 1171011300,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "거여동",
        "lat": 37.494161,
        "lng": 127.145196
    },
    {
        "code": 1171011400,
        "sido": "서울특별시",
        "gu": "송파구",
        "dong": "마천동",
        "lat": 37.497117,
        "lng": 127.152393
    },
    {
        "code": 1174010100,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "명일동",
        "lat": 37.549276,
        "lng": 127.147555
    },
    {
        "code": 1174010200,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "고덕동",
        "lat": 37.561068,
        "lng": 127.160582
    },
    {
        "code": 1174010300,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "상일동",
        "lat": 37.54822,
        "lng": 127.17063
    },
    {
        "code": 1174010500,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "길동",
        "lat": 37.539123,
        "lng": 127.143163
    },
    {
        "code": 1174010600,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "둔촌동",
        "lat": 37.531157,
        "lng": 127.142891
    },
    {
        "code": 1174010700,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "암사동",
        "lat": 37.552497,
        "lng": 127.13301
    },
    {
        "code": 1174010800,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "성내동",
        "lat": 37.531338,
        "lng": 127.129174
    },
    {
        "code": 1174010900,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "천호동",
        "lat": 37.543676,
        "lng": 127.131748
    },
    {
        "code": 1174011000,
        "sido": "서울특별시",
        "gu": "강동구",
        "dong": "강일동",
        "lat": 37.569204,
        "lng": 127.17162
    }
]