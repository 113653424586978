import React from "react";
import 'react-base-table/styles.css'
import T1221 from "../work1/data1/T1221";
import SeoulMap from "../work1/data1/SeoulMap";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";


export default function App() {

    const all_data =
        {'data': {'1229': '{"headers": [{"key": "column-0", "dataKey": "column-0", "title": "구분", "title2": "구분", "title1": "구분", "width": 70, "rowspan": {}}, {"key": "column-1", "dataKey": "column-1", "title": "합계", "title2": "합계", "title1": "합계", "width": 70}, {"key": "column-2", "dataKey": "column-2", "title": "도시 및 주거환경 정비", "title2": "도시 및 주거환경 정비", "title1": "도시 및 주거환경 정비", "width": 70}, {"key": "column-3", "dataKey": "column-3", "title": "재정비 촉진지구", "title2": "재정비 촉진지구", "title1": "재정비 촉진지구", "width": 70}, {"key": "column-4", "dataKey": "column-4", "title": "소규모정비사업", "title2": "소규모정비사업", "title1": "소규모정비사업", "width": 70}], "rows": [["합계", 814, 307, 120, 387], ["강남구", 37, 26, 0, 11], ["강동구", 48, 10, 6, 32], ["강북구", 39, 5, 5, 29], ["강서구", 22, 3, 4, 15], ["관악구", 24, 9, 3, 12], ["광진구", 12, 4, 2, 6], ["구로구", 33, 15, 0, 18], ["금천구", 18, 2, 0, 16], ["노원구", 12, 5, 4, 3], ["도봉구", 6, 1, 0, 5], ["동대문구", 30, 12, 17, 1], ["동작구", 18, 1, 13, 4], ["마포구", 38, 21, 1, 16], ["서대문구", 31, 13, 7, 11], ["서초구", 64, 39, 0, 25], ["성동구", 30, 17, 3, 10], ["성북구", 72, 8, 12, 52], ["송파구", 53, 19, 4, 30], ["양천구", 37, 2, 5, 30], ["영등포구", 39, 22, 8, 9], ["용산구", 36, 27, 4, 5], ["은평구", 20, 7, 7, 6], ["종로구", 31, 25, 3, 3], ["중구", 21, 12, 8, 1], ["중랑구", 43, 2, 4, 37]]}'}, 'file_timestamp': 1686864132, 'org_file_name': 'C:\\lsrc\\seoulcity\\shp_file_processor\\files\\정비사업추진현황20230616.xlsx'}


    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey)

    return serverData && (<>
        <Depth3Title title='자치구별 추진구역 히트맵'/>
        <SeoulMap data={JSON.parse(serverData.data['1229'])}/>
        <Depth3Title title='자치구별 사업현황'/>
        <T1221 data={JSON.parse(serverData.data['1221'])} code={1221}/>
    </>);
}