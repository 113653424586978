import React, {useState} from "react";
import 'react-base-table/styles.css'
import {Depth3Title} from "../Depth3View";
import S2211 from "../work1/data1/S2211";
import G2211 from "../work1/data1/G2211";
import {T223x} from "./P221_dat";
import {useSyncData} from "../../utils/fileSync";
import {DownloadExcel} from "../../utils/TableUtils";

export default function App() {

    const [searchCondition, setSearchCondition] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';

    const serverData = useSyncData(work, metaKey, dataKey)

    return serverData && (<>
        <Depth3Title title='실거래가 분석'/>
        <DownloadExcel title="서울시 아파트" dataCode={2211} />
        <DownloadExcel title="자치구 아파트" dataCode={2212} />
        <DownloadExcel title="서울시 연립다세대" dataCode={2221} />
        <DownloadExcel title="자치구 연립다세대" dataCode={2222} />
        <div className='rounded-lg bg-gray-100 p-3 space-y-3'>
            <div>
            - 실거래 전수 신고자료를 활용하  여 매월 주택유형 및 세부지역별(아파트는 단지별) 실거래 중위가격을 표현했습니다.
            </div>
            <div>
            - 자료의 특성상, 편차가 있지만 시계열 추세흐름을 참고해주시면 감사하겠습니다.
            </div>
            <div>
            - 주택유형 및 지역구분은 중복선택이 가능하며 아파트는 단지명을 통해 상세조건을 입력하면 단지별 중위값을 알 수 있습니다.
            </div>

        </div>
        <S2211 data={JSON.parse(serverData.data['2291'])} onChangeSearchOption={setSearchCondition}/>
        <Depth3Title title='시계열 동향'/>
        <G2211 data={JSON.parse(serverData.data['2291'])} searchCondition={searchCondition}/>
    </>);
}