import {Link} from "react-router-dom";
import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {accordionState} from "../../utils/recoilstate";

function RightArrowInAccordionBody() {
    return <svg width='50' viewBox="0 0 24 24" fill="none">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path d="M16 12L8 12" stroke="#ed5564" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"></path>
            <path d="M13 15L15.913 12.087V12.087C15.961 12.039 15.961 11.961 15.913 11.913V11.913L13 9" stroke="#ed5564"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
    </svg>;
}

function JumpTo2Depth({link, title, subtitle}) {
    return (

        <Link
            to={link}
        >
            <div className="py-4 ml-3 flex justify-between">
                <div className="">
                    <div className="text-xl">
                        {title}
                    </div>
                    <div className="text-gray-500 mt-2">
                        {subtitle}
                    </div>
                </div>
                <div className="">
                    <RightArrowInAccordionBody/>
                </div>
            </div>
        </Link>
    )
}

function DownArrowForAccordion() {
    return <svg width='20' fill="#ed5564" viewBox="0 0 1920 1920">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path d="m.08 568.063 176.13-176.13 783.988 783.864 783.74-783.864 176.129 176.13-959.87 960.118z"
                  fillRule="evenodd"></path>
        </g>
    </svg>;
}

export function AccordionHeading(props) {
    const [accordionStates, setAccordionStates] = useRecoilState(accordionState);
    const isOpen = accordionStates[props.collapseFor];

    // ...

    // Update the accordion state when the button is clicked
    const handleClick = (event) => {
        // event.preventDefault();
        setAccordionStates({
            ...accordionStates,
            [props.collapseFor]: !isOpen,
        });

        console.log('new accordion state')
        console.log(accordionState)
    };

    return (
        <h2 className="mb-0" id={'heading-id-' + props.collapseFor}>
            {
                props.link ? (
                    <Link to={props.link}>
                    <button
                        className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] text-primary-400"
                        type="button"
                    >
                        <div className="w-1/6">
                            {props.image}
                        </div>
                        <div className='w-5/6 ml-4'>
                            <h3 className="text-2xl font-medium leading-6 text-gray-900">{props.title}</h3>
                            <p className="text-lg text-gray-500 mt-2">
                                {props.desc}
                            </p>
                        </div>
                        <span
                            className="ml-2 mr-3 h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
                    <RightArrowInAccordionBody/>
                </span>
                    </button>
                    </Link>
                ) : (
                    <button
                        onClick={handleClick}
                        className="group relative flex w-full items-center rounded-none border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] text-primary-400"
                        type="button"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target={"#" + props.collapseFor}
                        aria-expanded="true"
                        aria-controls="">
                        <div className="w-1/6">
                            {props.image}
                        </div>
                        <div className='w-5/6 ml-4'>
                            <h3 className="text-2xl font-medium leading-6 text-gray-900">{props.title}</h3>
                            <p className="text-lg text-gray-500 mt-2">
                                {props.desc}
                            </p>
                        </div>
                        <span
                            className="ml-2 -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
                     <DownArrowForAccordion/>
                </span>
                    </button>

                )}
        </h2>
    );
}

export function AccordionBody({items, collapseFor}) {

    const accordionStates = useRecoilValue(accordionState);
    const isOpen = accordionStates[collapseFor];


    return <div
        id={collapseFor}
        className={isOpen ? '' : 'hidden'}
        data-te-collapse-item
        aria-labelledby="headingOne5">
        <div className="py-4 px-5">
            <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                    <ul className="divide-y divide-gray-200 opacity-100">
                        {items.map(item => (
                            <li key={item.title}>
                                <JumpTo2Depth title={item.title} subtitle={item.subtitle} link={item.link}/>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>;
}