import React from "react";
import 'react-base-table/styles.css'
import T1431 from "../work1/data1/T1431";
import T1432 from "../work1/data1/T1432";
import T1433 from "../work1/data1/T1433";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";


export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey);

    return serverData && (<>
        <Depth3Title title='재정비촉진지구 구역 지정현황'/>
        <T1431 code='1111' data={JSON.parse(serverData.data['1431'])}/>
        <Depth3Title title='재정비촉진지구 추진현황'/>
        <T1432 code='1112' data={JSON.parse(serverData.data['1432'])}/>
        <div className='mt-10' >&nbsp;</div>
        <Depth3Title title='재정비촉진지구 주택공급'/>
        <T1433 code='1113' data={JSON.parse(serverData.data['1433'])}/>
    </>);
}