import React, {useState} from "react";
import 'react-base-table/styles.css'
import S2321 from "../work1/data1/S2321";
import {Depth3Title} from "../Depth3View";
import G2321 from "../work1/data1/G2321";
import {useSyncData} from "../../utils/fileSync";
import {DownloadExcel} from "../../utils/TableUtils";

export default function App() {

    const [searchCondition, setSearchCondition] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';
    

    const serverData = useSyncData(work, metaKey, dataKey)

    return serverData && (<>
        <Depth3Title title='지역별 주택가격지수'/><DownloadExcel title="엑셀" dataCode={2321} />
        <S2321 data={JSON.parse(serverData.data['2321'])} onChangeSearchOption={setSearchCondition}/>
        <Depth3Title title='선택항목 시계열'/>
        <G2321 data={JSON.parse(serverData.data['2321'])} searchCondition={searchCondition}/>
    </>);
}