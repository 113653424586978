import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//s const columns = gen_table.headers

    const HEADER_SIZED = [
        {"key": "column-11", "dataKey": "column-11", "title": "구분2", "title2": "구분", title1: ' ', "width": 40, align:'left'},
        {"key": "column-1", "dataKey": "column-1", "title": "햡계", "title2": "햡계", "title1": " ", "width": 50},
        {"key": "column-2", "dataKey": "column-2", "title": "공공개발", "title2": "공공개발", "title1": "", "width": 40},
        {"key": "column-3", "dataKey": "column-3", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 60},

        {"key": "column-5", "dataKey": "column-5", "title": "준비중", "title2": "준비중", "width": 100},
        {"key": "column-6", "dataKey": "column-6", "title": "존치정비", "title2": "존치정비", "title1": "구역지정 전", "width": 160},
        {"key": "column-7", "dataKey": "column-7", "title": "존치정비", "title2": "존치정비", "title1": "구역지정 전", "width": 160},
        {"key": "column-8", "dataKey": "column-8", "title": "존치정비", "title2": "존치정비", "title1": "구역지정 전", "width": 250},
        {"key": "column-13", "dataKey": "column-13", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 60},
        {"key": "column-14", "dataKey": "column-14", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 60},
        {"key": "column-15", "dataKey": "column-15", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 60},

        {"key": "column-21", "dataKey": "column-21", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 200},
        {"key": "column-22", "dataKey": "column-22", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 100},
        ]

    const LEFTMOST_HEADER_COUNT = 1
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [-1]
    const NOT_NUMBER = [1, 8]

    const width_table = 40 + 50 + 40 + 60 + 100 + 160 + 160 + 250 + 60 * 3 + 200 + 100
    const height_table = 400

    return (<>
        <AutoSizer width={width_table} height={height_table}  code={code} >
            <div className="grid grid-rows-3 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '40px'}} className="row-span-3  word-spacing ">순번 </div>
                <div style={{width: '150px'}} className="row-span-1 col-span-2  word-spacing  ">준공(사용검사,승인)년.월 </div>
                <div style={{width: '40px'}} className="row-span-2  word-spacing  ">연도 </div>
                <div style={{width: '110px'}} className="row-span-2  word-spacing  ">월 </div>

                <div style={{width: '100px'}} className="row-span-3  word-spacing  ">자치구 </div>
                <div style={{width: '160px'}} className="row-span-3  word-spacing  ">주소 </div>

                <div style={{width: '160px'}} className="row-span-3  word-spacing ">사업명 </div>
                <div style={{width: '250px'}} className="row-span-3  word-spacing ">단지명 </div>
                <div style={{width: '180px'}} className="row-span-1 col-span-3  word-spacing  ">공급 세대수</div>
                <div style={{width: '60px'}} className="row-span-2  word-spacing  ">총계 </div>
                <div style={{width: '60px'}} className="row-span-2  word-spacing  ">분양 </div>
                <div style={{width: '60px'}} className="row-span-2  word-spacing  ">임대 </div>
                <div style={{width: '200px'}} className="row-span-3  word-spacing ">세부사업유형 </div>
                <div style={{width: '100px'}} className="row-span-3  word-spacing ">시행단계 </div>
            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows, NOT_NUMBER)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;

