/*eslint-disable*/
import React from "react";
import {WelcomeDiv} from "./components/WelcomeDiv";
import Depth1List from "./components/Depth1List";

function DeviceArrowIcon(props) {
    return (
        <svg fill="#969696" width="64px" height="64px" viewBox="0 0 32 32" id="icon"
             stroke="#969696">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <defs>
                    <style>{` .cls-1 {fill: none;}`}</style>
                </defs>
                <title>data-table</title>
                <rect x="8" y="18" width="4" height="2"></rect>
                <rect x="14" y="18" width="4" height="2"></rect>
                <rect x="8" y="14" width="4" height="2"></rect>
                <rect x="14" y="22" width="4" height="2"></rect>
                <rect x="20" y="14" width="4" height="2"></rect>
                <rect x="20" y="22" width="4" height="2"></rect>
                <path
                    d="M27,3H5A2.0025,2.0025,0,0,0,3,5V27a2.0025,2.0025,0,0,0,2,2H27a2.0025,2.0025,0,0,0,2-2V5A2.0025,2.0025,0,0,0,27,3Zm0,2,0,4H5V5ZM5,27V11H27l0,16Z"></path>
                <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width="32"
                      height="32"></rect>
            </g>
        </svg>
    )
}


function MainMenu(props) {
    return <button className="
                                                accordion-button
                                                collapsed
                                                relative
                                                flex
                                                items-center
                                                w-full
                                                py-4
                                                px-5
                                                text-base text-gray-800 text-left
                                                bg-white
                                                border-0
                                                rounded-none
                                                transition
                                                focus:outline-none
                              " type="button" data-bs-toggle="collapse" data-bs-target={"#" + props.collapsefor}
                   aria-expanded="true"
                   aria-controls={props.collapsefor}>
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
                <div className="flex items-center">
                    <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{props.title}</h3>
                        <p className="text-sm text-gray-500">
                            {props.subtitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </button>;
}

export default function Home() {

    return (
        <>
            <WelcomeDiv/>
            <Depth1List/>
        </>
    );
}
