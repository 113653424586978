import {useEffect, useState} from 'react'
import {Switch} from '@headlessui/react'
import {useRecoilState} from "recoil";
import {localSettingState, serverSettingState} from "../../utils/recoilstate";
import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function ToogleButton() {

    const [localSetting, setLocalSetting] = useRecoilState(localSettingState);

    function setEnabled(e) {

        setLocalSetting((p) => ({...p, preview: e}))

    }

    return (
        <Switch
            checked={localSetting.preview}
            onChange={setEnabled}
            className={classNames(
                localSetting.preview ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={classNames(
                    localSetting.preview ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
            />
        </Switch>
    )
}

export default function Example() {
    const [serverSetting, setServerSetting] = useRecoilState(serverSettingState);

    const handleSave = async () => {
        try {
            const response = await fetch('https://api.seoulhousinginfo.com/admin/settings', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ui_footer_notice: serverSetting.ui_footer_notice }),
            });
            // Handle the response if needed

        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    const handleChange = (event) => {
        setServerSetting((p) => ({...p, ui_footer_notice: event.target.value}));
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className={"float-right"}>
                        <button onClick={handleSave} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            저장
                        </button>
                    </div>
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="divide-y divide-gray-300">
                            <thead>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            <tr className="divide-x divide-gray-200">
                                <td className="py-4 pl-4 pr-4 text-sm font-medium sm:pl-0">
                                    미리보기
                                    <div className='text-xs text-gray-300'>
                                        미리보기 파일이 있을 경우 공개 전 조회 가능 (페이지 새로고침 시 자동으로 풀림)
                                    </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-0">
                                    <ToogleButton />
                                </td>
                            </tr>
                            <tr className="divide-x divide-gray-200">
                                <td className="py-4 pl-4 pr-4 text-sm font-medium sm:pl-0">
                                    푸터 문구
                                    <div className='text-xs text-gray-300'>
                                        표시하고 싶은 내용
                                    </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-0">
                                    <textarea type="text" value={serverSetting.ui_footer_notice} onChange={handleChange} rows={4} cols={50} >

                                    </textarea>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
