import React from "react";
import 'react-base-table/styles.css'
import T1441 from "../work1/data1/T1441";
import T1442 from "../work1/data1/T1442";
import T1443 from "../work1/data1/T1443";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey);

    return serverData && (<>
        <Depth3Title title='소규모정비사업 지정현황'/>
        <T1441 code='1441' data={JSON.parse(serverData.data['1441'])}/>
        <Depth3Title title='소규모정비사업 추진현황'/>
        <T1442 code='1442' data={JSON.parse(serverData.data['1442'])}/>
        <Depth3Title title='소규모정비사업 주택공급계획'/>
        <T1443 code='1443' data={JSON.parse(serverData.data['1443'])}/>
    </>);
}