import React, {useEffect, useState} from "react";
import 'react-base-table/styles.css'
import {EyeIcon} from "@heroicons/react/20/solid";
import {Switch} from '@headlessui/react'
import {classNames} from "@headlessui/react/dist/utils/class-names";

const formatDate = date => {
    if (date === undefined)
        return ''
    const d = new Date(date);
    return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
};

export default function App() {
    const [passwordType, setPasswordType] = useState('password');
    const [dataFromServer, setDataFromServer] = useState([]);
    const [memberServer, setMemberServer] = useState([]);
    const [members, setMembers] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.seoulhousinginfo.com/members',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            // const response = await fetch('https://21ye4kblgg.execute-api.ap-northeast-2.amazonaws.com/prod/member');
            const data = await response.json();
            setDataFromServer(JSON.parse(data.body));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const deleteUser = async (id, name) => {
        console.log('anyway called')
        if (window.confirm(`정말로 ${id} (${name})님 를 삭제하시겠습니까?`)) {
            try {
                const response = await fetch(`https://api.seoulhousinginfo.com/members`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id: id })
                });

                if (response.ok) {
                    console.log(`User with id ${id} deleted successfully.`);
                    // Optionally, you can call fetchData() here to refresh the data after deletion
                    fetchData();
                } else {
                    console.error(`Error deleting user with id ${id}:`, response.statusText);
                }
            } catch (error) {
                console.error(`Error deleting user with id ${id}:`, error);
            }
        }
    };

    useEffect(() => {

        fetchData();
    }, []);

    useEffect(() => {
        const memberServer = dataFromServer.map(item => {
            const attributes = {};
            item.Attributes.forEach(attr => {
                attributes[attr.Name] = attr.Value;
            });

            return {
                uuid: attributes.sub,
                name: attributes.name || "",
                email: attributes.email,
                joined: formatDate(item.UserCreateDate),
                lastaccess: formatDate(attributes['custom:last_accessed']),
                id: item.Username,
                groups: item.Groups
            };
        });

        setMemberServer(memberServer)
    }, [dataFromServer]);


    function togglePassword() {
        setPasswordType(prevType => prevType === 'password' ? 'text' : 'password');
    }

    const [filter, setFilter] = useState('');

    useEffect(() => {
        const members = memberServer.filter(member => {
            const lowerCaseFilter = filter.toLowerCase();
            return member.name.toLowerCase().includes(lowerCaseFilter) ||
                member.id.toLowerCase().includes(lowerCaseFilter) ||
                member.email.toLowerCase().includes(lowerCaseFilter)
                ;
        });

        setMembers(members)

    }, [memberServer,filter]);


    const handleClearFilter = () => {
        setFilter('');
    }


    function getInitialState() {
        return {
            id: "",
            name: "",
            email: "",
            password: "",
        };
    }

    const [formData, setFormData] = useState(getInitialState());


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (Object.values(formData).some((val) => val === "")) {
            alert("모든 항목을 채워주세요");
            return;
        }

        try {
            console.log(formData)
            const response = await fetch("https://api.seoulhousinginfo.com/members", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result);
                setFormData(getInitialState())
                fetchData()

            } else {
                console.error(`HTTP error! status: ${response.status}`);
                const responseBody = await response.text();
                console.error(`Response body: ${responseBody}.decode()`);
                alert(JSON.parse(responseBody)['error'])
            }
        } catch (e) {
            console.error(e);
        }
    };

    const [emailValid, setEmailValid] = useState(true);
    const [formValid, setFormValid] = useState(false);

    const handleEmailChange = (value) => {
        const email = value;
        setEmailValid(validateEmail(email));
        console.log(emailValid)
    };

    const validateEmail = (email) => {
        // Basic email validation regex
        const regex = /^\S+@\S+\.\S+$/;
        return regex.test(email);
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'email') {
            handleEmailChange(e.target.value);
        }

        if (Object.values(formData).some((val) => val === "")) {
            setFormValid(false)
        } else {
            emailValid && setFormValid(true)
        }

    };

    const handleSwitch = (member, groupName) => async (event) => {
        const updatedGroups = [...member.groups];

        if(!updatedGroups.includes(groupName)) {
            updatedGroups.push(groupName)
        } else {
            updatedGroups.pop(groupName)
        }

        try {
            const response = await fetch(`https://api.seoulhousinginfo.com/members`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...member, groups: updatedGroups }),
            });
            if (response.ok) {
                fetchData();
            } else {
                console.error(`HTTP error! status: ${response.status}`);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (<>

        <h1 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">회원 목록</h1>

        <div className="px-2 m:px-6">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">작은 화면에서는 우측으로 스크롤 하세요</p>
        </div>
        <div className="px-2 sm:px-6 lg:px-8">
            <div className="mt-2 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                        <div className='float-right'>
                            <button onClick={fetchData}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                </svg>
                            </button>
                        </div>
                        <div className='mb-3'>
                            <input className="p-2 px-2 py-1 border-b border-b-1 border-gray-900 bg-blue-50" type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="검색"/>
                            {filter && (
                                <button className="ml-2 text-red-600 font-bold" onClick={handleClearFilter}>
                                    X
                                </button>
                            )}
                        </div>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        ID
                                    </th>
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        이름
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        이메일
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        데이터 조회
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        데이터 관리
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        조작
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        생성일
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        마지막 로그인
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {members.map((member) => (<tr key={member.uuid}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                        {member.id}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                                        <div className="flex items-center">
                                            <div className="">
                                                <div className="font-medium text-gray-900">{member.name}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="flex items-center">
                                            <div className="text-gray-900">{member.email}</div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Switch
                                            checked={member.groups.includes('member')  }
                                            onChange={handleSwitch(member, 'member')}
                                            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    (member.groups.includes('member') ) ? 'bg-indigo-600' : 'bg-gray-100',
                                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                                )}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    (member.groups.includes('member') ) ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>

                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Switch
                                            checked={member.groups.includes('admin')}
                                            onChange={handleSwitch(member, 'admin')}
                                            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    member.groups.includes('admin') ? 'bg-red-600' : 'bg-gray-100',
                                                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                                )}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    member.groups.includes('admin') ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm space-x-2 text-gray-500">
                                        { /*
                                        <button type="button" onClick={() => setEdit(true)}>
                                        <span
                                            className="inline-flex rounded-full bg-blue-100 px-3 py-2 text-xs font-semibold leading-5 text-blue-800">
                                                수정
                                            </span>
                                        </button>
                                        */ }
                                        {(!member.groups.includes('admin') && !member.groups.includes('member')) &&
                                            <button type="button" onClick={() => deleteUser(member.id, member.name)}>
                                        <span
                                            className="inline-flex round ed-full bg-red-100 px-3 py-2 text-xs font-semibold leading-5 text-red-800">
                                                삭제
                                            </span>
                                            </button>
                                        }
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.joined}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {member.lastaccess} </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h1 className="mt-5 text-3xl font-extrabold tracking-tight text-slate-900">회원 추가</h1>

        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">회원을 추가 하세요</p>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">ID</dt>
                        <dd className="mt-1 text-sm sm:col-span-2 text-gray-900 sm:mt-0 space-x-3">
                            <input name='id' onChange={handleInputChange} className='bg-blue-50 w-full p-2' type='text' value={formData.id}/>
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">이름</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-3">
                            <input name='name' onChange={handleInputChange}  className='bg-blue-50 w-full p-2' type='text' value={formData.name}/>
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">이메일</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-3">
                            <input name='email' onChange={handleInputChange}  className='bg-blue-50 w-full p-2' type='text' value={formData.email}/>
                            {formData.email.length > 0 && emailValid && <span className="text-green-500">유효한 이메일입니다</span>}
                            {formData.email.length > 0 && !emailValid && <span className="text-red-500">유효하지 않은 이메일입니다</span>}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 flex "><span className='w-20'>패스워드</span>
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0 space-x-3">
                            <input name='password' onChange={handleInputChange}  className='bg-blue-50 p-2' type={passwordType} autoComplete="new-password" value={formData.password} />
                            <span>8자리 이상, 최소 1개의 숫자, 특수문자, 대문자, 소문자</span>
                            <button type="button" className='w-full flex inline-block' onClick={togglePassword}><EyeIcon className='w-5'/>입력값 보기</button>
                            <button
                                className={`mt-3 bg-gray-800 px-4 p-2 ml-3 text-xs font-semibold leading-5 text-white ${!formValid && 'opacity-20 cursor-not-allowed'}`}
                                type="submit"
                                disabled={!formValid}
                            >
                                신규 추가
                            </button>
                        </dd>
                    </div>
                </dl>
            </div>
            </form>
        </div>
    </>);
}