import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//s const columns = gen_table.headers

    const HEADER_SIZED = [{
        "key": "column-0",
        "dataKey": "column-0",
        "title": "구분",
        "title2": "구분",
        "width": 100,
        "rowspan": {"0": 2, "2": 2, "4": 2, "6": 2, "8": 2, "10": 2, "12": 2, "14": 2, "16": 2}
    }, {"key": "column-1", "dataKey": "column-1", "title": "구분", "title2": "구분", title1: ' ', "width": 100},
        {
        "key": "column-2", "dataKey": "column-2", "title": "합계", "title2": "합계", title1: ' ', "width": 70
    }, {
        "key": "column-3", "dataKey": "column-3", "title": "공공", "title2": "공공", "title1": "", "width": 70
    }, {
        "key": "column-4", "dataKey": "column-4", "title": "민간/신통", "title2": "민간/신통", "title1": "", "width": 70
    }, {"key": "column-5", "dataKey": "column-5", "title": "민간/일반", "title2": "민간/일반", title1: '재건축', "width": 70}, {
        "key": "column-6", "dataKey": "column-6", "title": "공공", "title2": "공공", "title1": "", "width": 70
    }, {
        "key": "column-7", "dataKey": "column-7", "title": "민간/신통", "title2": "민간/신통", "title1": "", "width": 70
    }, {"key": "column-8", "dataKey": "column-8", "title": "민간/일반", "title2": "민간/일반", title1: '도시정비형재개발', "width": 70}, {
        "key": "column-9", "dataKey": "column-9", "title": "공공", "title2": "공공", "title1": "", "width": 70
    }, {
        "key": "column-10", "dataKey": "column-10", "title": "민간/신통", "title2": "민간/신통", "title1": "", "width": 70
    }, {
        "key": "column-11", "dataKey": "column-11", "title": "민간/일반", "title2": "민간/일반", "title1": "주택정비형재개발", "width": 70
    }]


    const LEFTMOST_HEADER_COUNT = 2
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [-1]

    const width_table = 900
    const height_table = 450

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-4 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '200px'}} className="row-span-4 col-span-2 flex word-spacing items-center justify-center">구분</div>
                <div style={{width: '70px'}} className="row-span-4 flex word-spacing items-center justify-center">합계</div>
                <div style={{width: '210px'}} className="col-span-3 row-span-2 flex word-spacing items-center justify-center">재건축</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공</div>
                <div style={{width: '140px'}} className="col-span-2 flex word-spacing items-center justify-center">민간</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">신통</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">일반</div>
                <div style={{width: '420px'}} className="col-span-6 flex word-spacing items-center justify-center">재개발</div>
                <div style={{width: '210px'}} className="col-span-3 flex word-spacing items-center justify-center">도시정비형</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공</div>
                <div style={{width: '140px'}} className="col-span-2 flex word-spacing items-center justify-center">민간</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">신통</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">일반</div>
                <div style={{width: '210px'}} className="col-span-3 flex word-spacing items-center justify-center">주택정비형</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">공공</div>
                <div style={{width: '140px'}} className="col-span-2 flex word-spacing items-center justify-center">민간</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">신통</div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">일반</div>
            </div>

            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;


