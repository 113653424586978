import React from "react";
import 'react-base-table/styles.css'
import T1131 from "../work1/data1/T1131";
import {Depth3Title} from "../Depth3View";
import G1131 from "../work1/data1/G1131";
import {useSyncData} from "../../utils/fileSync";

export default function P113() {
    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';
    
    const serverData =useSyncData(work, metaKey, dataKey)

    return serverData && (<>
        <Depth3Title title='추진구역현황 (구역지정이후)'/>
        <G1131 data={JSON.parse(serverData.data['1131'])}/>
        <T1131 code='1131' data={JSON.parse(serverData.data['1131'])}/>
    </>);
}