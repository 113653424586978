import React, {useEffect} from "react";
import 'react-base-table/styles.css'
import T1112 from "../work1/data1/T1112";
import T1114 from "../work1/data1/T1114";
import T1111 from "../work1/data1/T1111";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";
import T1113 from "../work1/data1/T1113";
import {useRecoilState} from "recoil";
import {settingState} from "../../App";
import G1111 from "../work1/data1/G1111";
import G1131 from "../work1/data1/G1131";
import G1113 from "../work1/data1/G1113";

export default function P111() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';
    
    const serverData = useSyncData(work, metaKey, dataKey)

    return (<>
        {/* 1112 1113 은 예정과 준공인데 그래프는 예정,준공을 합쳐서 그래프를 보여주고, 표는 각기 보여준다.  */}
        {serverData &&
            (
                <>
                    <Depth3Title title='정비사업 총괄현황'/>
                    <G1111 data={JSON.parse(serverData.data['1114'])}/>
                    <T1111 code='1111' data={JSON.parse(serverData.data['1111'])}/>
                    <T1114 code='1114' data={JSON.parse(serverData.data['1114'])}/> {/* 원래 1113이었는데 표 위치가 바뀜 */}
                    <Depth3Title title='사업예정구역 현황'/>
                    <T1112 code='1112' data={JSON.parse(serverData.data['1112'])}/>
                    {/*<Depth3Title title='사업추진구역 현황'/>*/}
                    {/*<G1113 data={JSON.parse(serverData.data['1113'])}/>*/}
                    {/*<T1113 code='1113' data={JSON.parse(serverData.data['1113'])}/>*/}
                </>)
        }
    </>);
}