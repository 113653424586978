import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//s const columns = gen_table.headers

    const HEADER_SIZED = [
        {
            "key": "column-0",
            "dataKey": "column-0",
            "title": "구분",
            "title2": "구분",
            title1: ' ',
            "width": 130,
            "rowspan": {1: 3, 4: 3}
        },
        {"key": "column-11", "dataKey": "column-11", "title": "구분2", "title2": "구분", title1: ' ', "width": 100,},
        {"key": "column-1", "dataKey": "column-1", "title": "햡계", "title2": "햡계", "title1": " ", "width": 70},
        {"key": "column-2", "dataKey": "column-2", "title": "공공개발", "title2": "공공개발", "title1": "", "width": 70},
        {"key": "column-3", "dataKey": "column-3", "title": "신통기획", "title2": "신통기획", "title1": "", "width": 70},
        {
            "key": "column-4",
            "dataKey": "column-4",
            "title": "민간일반\n(안전진단)",
            "title2": "민간일반\n(안전진단)",
            "title1": "",
            "width": 70
        },
        {"key": "column-5", "dataKey": "column-5", "title": "준비중", "title2": "준비중", "width": 70},
        {"key": "column-6", "dataKey": "column-6", "title": "존치정비", "title2": "존치정비", "title1": "구역지정 전", "width": 70},
        {"key": "column-10", "dataKey": "column-10", "title": "일반", "title2": "일반", "title1": "사업추진", "width": 70}]

    const LEFTMOST_HEADER_COUNT = 2
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [3, 6]

    const width_table = 720
    const height_table = 200

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-3 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '230px'}}
                     className="row-span-3 flex word-spacing items-center justify-center">사업유형
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[2].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[3].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[4].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[5].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[6].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[7].title}
                </div>
                <div style={{width: '70px'}} className="row-span-3 flex word-spacing items-center justify-center">{data.headers[8]?.title}
                </div>
            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;
