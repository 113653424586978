/**
 * Syncs data from the server and updates local storage if necessary.
 *
 * @param {string} work - The work parameter for the API endpoint.
 * @param {string} status - The status parameter for the API endpoint.
 * @param {string} meta_key - The key to store the metadata in local storage.
 * @param {string} data_key - The key to store the data in local storage.
 */
import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {localSettingState} from "./recoilstate";

const metaCache = {}
export const syncMetaData = async (work, is_preview, meta_key, data_key) => {
    try {
        // Fetch the data from the server
        const meta_at_localstroage = localStorage.getItem(meta_key+is_preview);
        const currentTimestamp = Math.round(Date.now() / 1000);

        if (metaCache[meta_key+is_preview]) {
            return;
        }

        let status_text = '전시'
        if(is_preview) {
            status_text = '미리보기'
        }

        const response = await fetch(`https://api.seoulhousinginfo.com/admin/work/${work}/status/${status_text}/meta`);
        const serverData = await response.json();

        metaCache[meta_key+is_preview] = serverData

        if (meta_at_localstroage) {
            const storedMetaData = JSON.parse(meta_at_localstroage);

            if (storedMetaData.upload_ts !== serverData.upload_ts) {
                // Meta data has changed, update the local storage data
                console.log('로컬 데이터가 서버 데이터와 다르므로 데이터를 로딩');
            } else {
                console.log('로컬 데이터가 서버 데이터와 같음');
                return
            }
        }

        const storingData = {...serverData, local_saved_ts: currentTimestamp};
        localStorage.setItem(meta_key+is_preview, JSON.stringify(storingData));
        await downloadAndPutDataToLocalStorage(data_key, storingData);
    } catch (error) {
        console.error('Error fetching meta from the server:', error);
    }
};


/**
 * Downloads data from the server and stores it in local storage.
 *
 * @param {string} data_key - The key to store the data in local storage.
 * @param {object} serverData - The server data containing the upload timestamp.
 */
const downloadAndPutDataToLocalStorage = async (data_key, serverData) => {
    const dataKey = serverData.upload_ts

    try {
        // Fetch the data from the server
        const response = await fetch(`https://api.seoulhousinginfo.com/admin/workfile/data/${dataKey}/json`);
        const serverData = await response.json();
        localStorage.setItem(data_key, serverData);

    } catch (error) {
        console.error("Error fetching data from the server:", error);
    }
};

const dataCache = {}; // Cache for storing fetched data

export const useSyncData = (work, metaKey, dataKey) => {
    const [allData, setAllData] = useState(null);
    const [localSetting, setLocalSetting] = useRecoilState(localSettingState);

    useEffect(() => {
        console.log('sync data for', work, localSetting.preview, metaKey, dataKey)

        // Check if data is already cached
        if (dataCache[dataKey + localSetting.preview]) {
            console.log('already exist in JS memory')
            setAllData(dataCache[dataKey + localSetting.preview]);
            return;
        }

        // Check if metadata is already cached
        console.log('check meta data')
        syncMetaData(work, localSetting.preview, metaKey, dataKey)
            .then(() => {
                console.log('start to download file')
                const storedData = JSON.parse(localStorage.getItem(dataKey));
                if (storedData) {
                    // Cache the fetched data
                    console.log('downloaded')
                    dataCache[dataKey] = storedData;
                    setAllData(storedData);
                }
            })
            .catch(error => {
                console.error("Error syncing data:", error);
            });
    }, [work, localSetting.preview, metaKey, dataKey]);

    return allData;
};
