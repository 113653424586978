import {useHistory} from "react-router-dom";
import {SVGBack} from "../svg/ForHeader";

export function numberWithCommas(x) {
    try {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch(e) {
        return x
    }
}

export function dashIfZero(x) {
    return x === 0 || x === '0' || x === '' ? '-' : x;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function BackButton() {
    const history = useHistory();

    const handleClick = () => {
        if (history.length > 1) {
            // Navigate to the previous page
            console.log('history back')
            history.goBack();
        } else {
            // Get the current URL
            const currentUrl = window.location.href;

            // Extract the parent URL
            const parentUrl = currentUrl.split('/').slice(0, -2).join('/');

            console.log('go parent')
            window.location.href = parentUrl;
        }
    };

    return <button onClick={handleClick} className="w-10">
        <SVGBack/>
    </button>;
}