import React, {useEffect, useRef, useState} from "react";
import axios from "axios";

function FileUploader({onFileSelectSuccess}) {
    const fileInput = useRef(null)
    const handleFileInput = (e) => {
        const file = e.target.files[0];
        console.log(file)
        onFileSelectSuccess(file)
    }

    return (
        <div className="file-uploader">
            업로드할 파일 선택 : <input type="file" onChange={handleFileInput}/>
        </div>
    )
}


const App = () => {

    const [name, setName] = useState("");
    const [path, setPath] = useState("");
    const [memo, setMemo] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const [uploadUrl, setUploadUrl] = useState('');
    const [uploadFields, setUploadFields] = useState('');

    function submitForm() {
        const formData = new FormData();

        console.log(uploadFields)
        // add all the other fields

        // const uploadFields_custom = {"key": "testfilename", "x-amz-algorithm": "AWS4-HMAC-SHA256", "x-amz-credential": "AKIARC2OR5HTRUGA6EW6/20230301/ap-northeast-2/s3/aws4_request", "x-amz-date": "20230301T054200Z", "policy": "eyJleHBpcmF0aW9uIjogIjIwMjMtMDMtMDFUMDY6NDI6MDBaIiwgImNvbmRpdGlvbnMiOiBbeyJidWNrZXQiOiAic2hwLXJlcG9ydC1maWxlcyJ9LCB7ImtleSI6ICJ0ZXN0ZmlsZW5hbWUifSwgeyJ4LWFtei1hbGdvcml0aG0iOiAiQVdTNC1ITUFDLVNIQTI1NiJ9LCB7IngtYW16LWNyZWRlbnRpYWwiOiAiQUtJQVJDMk9SNUhUUlVHQTZFVzYvMjAyMzAzMDEvYXAtbm9ydGhlYXN0LTIvczMvYXdzNF9yZXF1ZXN0In0sIHsieC1hbXotZGF0ZSI6ICIyMDIzMDMwMVQwNTQyMDBaIn1dfQ==", "x-amz-signature": "18b05ee2d1a8042e684e8ddfdbeb8f5103dff5b775eca13cc7b89c4b071c47ac"}

        // auto by lambda
        const uploadFields_custom = uploadFields

        Object.entries(uploadFields_custom).forEach(([key, val]) => {
            // if (['key', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'policy', 'x-amz-security-token', 'x-amz-signature'].includes(key))
                formData.append( key, val);
            // else {
            //     // formData.append('x-amz-meta-' + key, val);
            // }

        });

        formData.append("file", selectedFile);
        formData.append('x-amz-meta-shp-info', 'xxx')
        // formData.append("key", selectedFile.name);


        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
            , validateStatus: function (status) {
                return status == 200 || status === 204;
            }
        }
        axios
            .post(uploadUrl, formData, axiosConfig)
            .then((res) => {
                console.log("File Upload success");
            })
            .catch(
                (err) => {
                    console.log('got error?')
                }
            );
    }

    useEffect(() => {

        console.log("file was changed", selectedFile)

        if (!selectedFile)
            return

        const meta_info = {
            'object-key': path,
            'original-filename': selectedFile.name,
            'uploader-name': name,
            'work-name': '추진업무현황',
            'memo': memo,
            'timeout': 600
        }
        const params = new URLSearchParams(meta_info).toString();

        function getPresignedUrl() {
            axios.get('https://api.seoulhousinginfo.com/upload/presigned?' + params)
                .then(res => {
                    setUploadUrl(res.data.url)
                    setUploadFields(res.data.fields)
                    console.log(res.data.fields)
                })
                .catch(error => {
                    console.log(error);
                });
        }

        getPresignedUrl()

    }, [selectedFile]);


    return (
        <div className="App">
            사용자 이름 : <input value={name} onInput={e => setName(e.target.value)}/>
            파일 위치 : <input value={path} onInput={e => setPath(e.target.value)}/>
            메모 : <input value={memo} onInput={e => setMemo(e.target.value)}/>
            <FileUploader
                onFileSelectSuccess={(file) => setSelectedFile(file)}
                onFileSelectError={({error}) => alert(error)}
            />
            <button onClick={submitForm}>업로드</button>
        </div>
    );
};

export default App