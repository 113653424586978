export function SVGBack() {
    return (
        <svg width='100%' viewBox="0 0 24 24" fill="none" >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M8 12L16 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
                <path d="M11 9L8.08704 11.913V11.913C8.03897 11.961 8.03897 12.039 8.08704 12.087V12.087L11 15"
                      stroke="#ffffff" strokeidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </g>
        </svg>
    )
}