import React, {useEffect, useState} from "react";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import 'react-base-table/styles.css';


function MonthRangeSelector({data, onRangeChange}) {
    // 지금부터 최대 10년 이전의 슬라이더를 자동으로 그려준다.

    let endingMonth = new Date();
    let startingMonth = new Date(endingMonth.getFullYear() - 10, endingMonth.getMonth(), 1);

    // start ~ end 를 data를 기준으로 변경
    if (data) {
        const firstValue = data[0];
        const lastValue = data[data.length - 1]

        startingMonth = new Date(firstValue[0], firstValue[1] - 1, 1);
        endingMonth = new Date(lastValue[0], lastValue[1] - 1, 1);

    }

    const differenceInMonths =
        endingMonth.getFullYear() * 12 +
        endingMonth.getMonth() - (startingMonth.getFullYear() * 12 + startingMonth.getMonth());

    const min = 0
    const max = differenceInMonths;

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(differenceInMonths);


    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}/${month}`;
    }

    useEffect(() => {
        const minDate = formatDate(new Date(startingMonth.getFullYear(), startingMonth.getMonth() + minValue));
        const maxDate = formatDate(new Date(startingMonth.getFullYear(), startingMonth.getMonth() + maxValue));

        onRangeChange(minDate, maxDate);
    }, [minValue, maxValue]);


    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];


    return (<div>
        <InputRange
            minValue={min}
            maxValue={max}
            value={{ min: minValue, max: maxValue }}
            onChange={(value) => {
                setMinValue(value.min);
                setMaxValue(value.max);
            }}
            formatLabel={(value) => {
                const year = startingMonth.getFullYear() + Math.floor(value / 12);
                const month = months[(startingMonth.getMonth() + value) % 12];
                return `${year}/${month}`;
            }}
        />
    </div>);
}


function App({data, onChangeSearchOption}) {
    const [searchCondition, setSearchCondition] = useState({
        시작월: '2021/01',
        끝월: '2021/02',
        유형: '매매'
    });

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setSearchCondition(prevCondition => ({
            ...prevCondition,
            검색어: selectedOptions,
        }));
        console.log(selectedOptions)

    }, [selectedOptions])

    function handleMonthRangeChange(minDate, maxDate) {
        setSearchCondition(prevCondition => ({
            ...prevCondition,
            시작월: minDate,
            끝월: maxDate,
        }));
    }

    useEffect(() => {

        onChangeSearchOption(searchCondition)

    }, [searchCondition]);

    function handleRadioChange(event) {
        const {name, value} = event.target;
        setSearchCondition(prevCondition => ({
            ...prevCondition,
            [name]: value,
        }));
    }

    return (

        <>
            <div className='p-3 border rounded-lg border-gray-200 mb-4 space-y-3 text-xs'>
                <div className="flex gap-x-4 items-center mt-3">
                    <div className="flex w-32">
                        유형
                    </div>
                    <div className="radio-parent flex w-64 grow items-center ">
                        <input type="radio" id="option11" name="유형" value="매매" className="peer"
                               checked={searchCondition.유형 === '매매'}
                               onChange={handleRadioChange}
                               required/>
                        <label htmlFor="option11"
                               className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                매매
                            </div>
                        </label>
                    </div>
                    <div className="radio-parent flex w-64 grow items-center ">
                        <input type="radio" id="option12" name="유형" value="전세"
                               checked={searchCondition.유형 === '전세'}
                               onChange={handleRadioChange}
                               className=" peer"/>
                        <label htmlFor="option12"
                               className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                전세
                            </div>
                        </label>
                    </div>
                </div>
                <div className="py-7 px-9">
                    <MonthRangeSelector data={data} onRangeChange={handleMonthRangeChange}/>
                </div>
            </div>

        </>)
}

export default App;
