import React from "react";
import 'react-base-table/styles.css'
import T1411 from "../work1/data1/T1411";
import T1412 from "../work1/data1/T1412";
import T1413 from "../work1/data1/T1413";
import {Depth3Title} from "../Depth3View";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';
    
    const serverData = useSyncData(work, metaKey, dataKey);

    return serverData && (<>
        <Depth3Title title='재개발 정비구역 지정현황'/>
        <T1411 code='1411'  data={JSON.parse(serverData.data['1411'])}/>
        <Depth3Title title='재개발 정비사업 추진현황'/>
        <T1412 code='1412' data={JSON.parse(serverData.data['1412'])}/>
        <Depth3Title title='재개발 정비사업 주택공급'/>
        <T1413 code='1413' data={JSON.parse(serverData.data['1413'])}/>
    </>);
}