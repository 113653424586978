import React, {useEffect, useState} from "react";
import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomizedYAxisTickRight, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";
import {filterDataByCondition2} from "../../../utils/TableUtils";

function App({data, searchCondition}) {

    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        const fixedrows = data.rows.map(([year, month, 주담, 최고, 최저]) => {
            return {
                year: year, month: month,
                '주담대금리': (parseFloat(주담.replace('%', '')) / 100).toFixed(2),
                '(신한)최고금리': (parseFloat(최고.replace('%', '')) / 100).toFixed(2),
                '(신한)최저금리': (parseFloat(최저.replace('%', '')) / 100).toFixed(2),
            };
        });


        if (searchCondition.시작월) {
            setFilteredData(filterDataByCondition2(fixedrows, searchCondition))
        }

    }, [data, searchCondition]);

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -20, right: -40
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(%)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:20, fontSize: 12}}
                    />
                    <YAxis yAxisId="right" orientation="right" tick={<CustomizedYAxisTickRight screenWidth={screenWidth}/>} stroke="#004D92"/>
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="chenter" content={<CustomLegend/>}/>

                    <Line yAxisId='left' type="linear" strokeWidth={2} dataKey="주담대금리" stroke="red" unit='%'/>
                    <Line yAxisId='left' type="linear" strokeWidth={2} dataKey="(신한)최고금리" stroke="blue" unit='%'/>
                    <Line yAxisId='left' type="linear" strokeWidth={2} dataKey="(신한)최저금리" stroke="green" unit='%'/>

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
