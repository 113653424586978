import React, {useEffect, useState} from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomizedYAxisTickRight, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";
import {filterDataByCondition2} from "../../../utils/TableUtils";

function App({data, searchCondition}) {

    // Set the max and min "주담대 비중" values in state
    const [max주담대비중Value, setMax주담대비중Value] = useState(0);
    const [min주담대비중Value, setMin주담대비중Value] = useState(0);

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fixedrows = data.rows.map(([year, month, 주택담보, 기타대출, 비중]) => {
            return {
                year: year, month: month,
                '서울 주택담보대출': 주택담보 / 1,
                '서울 기타대출': 기타대출 / 1,
                '주담대 비중': (parseFloat(비중.replace('%', ''))).toFixed(1),
            };
        });

        // Extract the "주담대 비중" values from the converted data
        const 주담대비중Values = fixedrows.map((item) => parseFloat(item['주담대 비중']));

        // Calculate the min and max "주담대 비중" values
        setMax주담대비중Value( Math.max(...주담대비중Values))
        setMin주담대비중Value( Math.min(...주담대비중Values))

        if (searchCondition.시작월) {
            setFilteredData(filterDataByCondition2(fixedrows, searchCondition))
        }


    }, [data, searchCondition]);

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -20, right: -20
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick devide={1000} screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(조 원)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:20, fontSize: 12}}
                    />
                    <YAxis yAxisId="right" orientation="right" tick={<CustomizedYAxisTickRight screenWidth={screenWidth}/>} stroke="#004D92"
                           label={{  value: '(%)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:-10, fontSize: 12}}
                           domain={[Math.ceil(min주담대비중Value / 10) * 10 - 10, Math.ceil(max주담대비중Value / 10) * 10 + 0]}
                    />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="chenter" content={<CustomLegend/>}/>
                    <Bar dataKey='서울 주택담보대출' stackId='a' yAxisId='left' barSize={10} fill="#82ca9d" unit='(십억)' />
                    <Bar dataKey='서울 기타대출' stackId='a' yAxisId='left' barSize={10} fill="#ed7d31" unit='(십억)'/>
                    <Line yAxisId='right' type="linear" strokeWidth={2} dataKey="주담대 비중" stroke="blue" unit='(%)'/>

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
