import React, {useState} from "react";
import {Depth3Title} from "../Depth3View";
import G2156 from "../work1/data1/G2156";
import T2156 from "../work1/data1/T2156";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const [searchCondition,] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';
    

    const allData = useSyncData(work, metaKey, dataKey);

    return (<>

        {allData && (<>

            <Depth3Title title='인구순이동'/>
            <G2156 data={JSON.parse(allData.data['2156'])} searchCondition={searchCondition}/>
            <T2156 code='2156' data={JSON.parse(allData.data['2156'])} searchCondition={searchCondition}/>
        </>)}

    </>);
}