export function WelcomeDiv() {
    return (
        <div className="overflow-hidden py-8 sm:py-8 lg:pb-8 xl:pb-8 pt-8 px-5 bg-rose-700">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
                <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:px-6 xl:col-span-6 ">
                    <h1 className="text-4xl font-medium tracking-tight text-white">
                        주택정책지원센터 <br/>주택시장분석 모바일리포트
                    </h1>
                    <p className="mt-6 text-lg text-gray-50">
                        주택정책지원센터 주택시장분석팀에서
                        제공하는 정비사업 현황 및 주택시장동향 관련 자료를 확인할 수 있습니다.
                    </p>
                </div>
            </div>
        </div>
    )
}