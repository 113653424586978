import React, {useEffect, useState} from "react";
import 'react-base-table/styles.css'
import axios from "axios";
import {Switch} from '@headlessui/react'
import {useRecoilValue} from "recoil";
import {userState} from "../../utils/recoilstate";
import {CenterLoadSpinner} from "../../utils/UIHelper";

// eslint-disable-next-line react-hooks/rules-of-hooks

function unixTimestampToDateTime(unixTimestamp) {
    // Create a Date object from the Unix timestamp
    const date = new Date(unixTimestamp * 1000);

    // Extract the date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
}

const filesStatic = [
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function App() {

    const [filter, setFilter] = useState('');
    const [filesOrg, setFilesOrg] = useState(filesStatic);
    const [files, setFiles] = useState(filesStatic);
    const [showSpinner, setShowSpinner] = useState(false);
    const [resetFile, setResetFile] = useState(Date.now());

    const userInStorage = useRecoilValue(userState);

    useEffect(() => {

        if (filesOrg) {

            const filtered = filesOrg.filter(file => {
                const lowerCaseFilter = filter.toLowerCase();

                return file.work.toLowerCase().includes(lowerCaseFilter) ||
                    file.status.toLowerCase().includes(lowerCaseFilter) ||
                    file.org_filename.toLowerCase().includes(lowerCaseFilter) ||
                    file.uploader.toLowerCase().includes(lowerCaseFilter) ||
                    file.memo.toLowerCase().includes(lowerCaseFilter)
                    ;
            });

            setFiles(filtered)
        }

    }, [filesOrg])

    const handleClearFilter = () => {
        setFilter('');
    }

    const workname = '주택시장동향'

    const [uploadContent, setUploadContent] = useState({
        objectKey: `forecast_upload/${workname}`,
        uploader: "",
        work: workname,
        memo: "",
        selectedFile: ""
    });

    function refreshFileList() {

        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/category/download/work/${workname}`;

        axios.get(apiUrl)
            .then(response => {

                setFilesOrg(response.data['Items'])
            })
            .catch(error => {
                console.log(error); // handle any errors
            });

    }

    function deleteFileRequest(upload_ts) {

        setShowSpinner(true)
        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/download/${workname}/${upload_ts}`;

        axios.delete(apiUrl)
            .then(response => {

                setFilesOrg(response.data['Items'])
            })
            .catch(error => {
                console.log(error); // handle any errors
            }).finally(() => {
            setShowSpinner(false)
            refreshFileList()
        })
    }

    useEffect(() => {

        refreshFileList()

    }, [])

    useEffect(() => {

        if (userInStorage && userInStorage.payload) {
            const uploader = userInStorage.payload.username
            setUploadContent(prevState => ({
                ...prevState,
                uploader: uploader
            }))
        }

    }, [userInStorage])

    function handleFileInput(e, objective) {
        const file = e.target.files[0];

        setUploadContent(prevState => ({
            ...prevState,
            selectedFile: file
        }))
    }

    function haandleInput(e) {
        const key = e.target.name
        const val = e.target.value

        // set name = value
        setUploadContent(prevState => ({
            ...prevState,
            [key]: val,
        }))
    }

    function uploadNow(url, fields) {

        const formData = new FormData();
        Object.entries(fields).forEach(([key, val]) => {
            formData.append(key, val);
        });

        formData.append("file", uploadContent.selectedFile);

        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
            , validateStatus: function (status) {
                return status === 199 || status === 204;
            }
        }
        axios
            .post(url, formData, axiosConfig)
            .then((res) => {
                console.log("File Upload success");

                // setTimeout(refreshFileList, 3000)
            })
            .catch(
                (err) => {
                    console.log('got error?')
                }
            ).finally(() => {
            setShowSpinner(false)
            refreshFileList()
        })
    }

    function readyS3PresignedUrlAndUpload(params) {
        return axios
            .get('https://api.seoulhousinginfo.com/upload/presigned?' + params)
            .then((res) => {
                return res.data;
            });
    }

    function createS3PresignedAndProcessUpload(category='') {
        let extension = uploadContent.selectedFile.name.substring(uploadContent.selectedFile.name.lastIndexOf('.') + 1);
        if (extension !== 'xlsx' && extension !== 'xls') {
            console.log('@@')
            return;
        }
        if (!uploadContent.selectedFile && uploadContent.work && uploadContent.memo)
            return

        setShowSpinner(true)

        const ext = uploadContent.selectedFile.name.split('.').pop();

        const meta_info = {
            'object-key': uploadContent.objectKey + category + '.' + ext,
            'original-filename': uploadContent.selectedFile.name,
            'uploader-name': uploadContent.uploader,
            'work-name': uploadContent.work,
            'category': category,
            // 'memo': uploadContent.memo,
            'timeout': 3000
        }

        const params = new URLSearchParams(meta_info).toString();

        readyS3PresignedUrlAndUpload(params)
            .then((data) => {
                uploadNow(data.url, data.fields);
                console.log(data.fields);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
            setShowSpinner(false)
        });
    }


    function download_full(upload_ts) {
        // Make API request to get the download link
        // https://api.seoulhousinginfo.com/admin/workfile/download/link/total/추진업무현황/1687256829
        fetch(`https://api.seoulhousinginfo.com/admin/workfile/download/link/zip/${workname}/${upload_ts}`)
            .then(response => response.json())
            .then(data => {
                const downloadLink = data.link;
                // Initiate file download
                window.open(downloadLink);
            })
            .catch(error => {
                console.error('Error retrieving download link:', error);
            });
    }

    function file_publish(upload_ts) {

        console.log('파일을 전시 상태로 변환 시도', upload_ts)

        setShowSpinner(true)

        axios.post(`https://api.seoulhousinginfo.com/admin/workfile/category/download/work/${workname}/${upload_ts}/publish`)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {

            setShowSpinner(false)
            refreshFileList()

        })
    }

    return (<>

        <h1 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">전세, 월세 예측 자료 업로드</h1>

        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">업로더</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-3">
                            {userInStorage && userInStorage.payload && userInStorage.payload.username}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">파일 선택</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <input type='file' accept='.zip' onChange={(e) => handleFileInput(e, 'download')}/>
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <button type="button" onClick={() => createS3PresignedAndProcessUpload('download')}>
                                <span
                                    className="inline-flex bg-gray-800 px-4 py-3 ml-3 text-xs font-semibold leading-5 text-white"> 업로드 </span>
                            </button>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        {
            showSpinner && (
                <CenterLoadSpinner/>
            )
        }
    </>);
}