import {SyncLoader} from "react-spinners";

export function CenterLoadSpinner() {
    return <>
        <div
            id="overlay"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999,
            }}
        />
        <div
            id="rotating-ui"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 10000,
            }}
        >
            <SyncLoader color="#d63636"/>
        </div>
    </>;
}