import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({code, data, }) {

    const HEADER_SIZED = [{"key": "column-0", "dataKey": "column-0", "title": "구분", "title2": "구분", "title1": "구분", "width": 120, "rowspan": {}}, {
        "key": "column-1", "dataKey": "column-1", "title": "구분", "title2": "구분", "title1": "구분", "width": 70
    },
        {"key": "column-2", "dataKey": "column-2", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
        {"key": "column-3", "dataKey": "column-3", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
        {"key": "column-4", "dataKey": "column-4", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
        {"key": "column-5", "dataKey": "column-5", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
        {"key": "column-6", "dataKey": "column-6", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
        {"key": "column-7", "dataKey": "column-7", "title": "매매 매물량", "title2": "매매 매물량", "title1": "매매 매물량", "width": 70},
     ] /* 제목보다 값은 짧게 가야 스크롤 영역 표시 가능 */

    const LEFTMOST_HEADER_COUNT = 1
    const ROW_TO_BE_MERGE = [-1]
    const ROW_SUB_SUM = [-1]


    /* 검색월에 의한 필터링 영역 {{{ */
    const rows = data['rows']


    const fixedrows = rows.map(row => {
        const newRow = [...row];
        return newRow;
    });


    const width_table = 610
    const height_table = 150
    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-2 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '120px', height: '66px'}} className="row-span-2 flex word-spacing items-center justify-center">년도</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">계</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">10대</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">20대</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">30대</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">40대</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">50대</div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">60대 이상</div>
            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, fixedrows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;
