import {Auth} from "aws-amplify";

export function SignOut() {
    let signOut = async () => {
        // await Auth.signOut();
        console.log("Sign out succesfully")
        localStorage.removeItem('userinfo')
        localStorage.removeItem('access_token')
        document.cookie = 'cognito=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.replace('/work');

    }
    return signOut;
}