import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

    const HEADER_SIZED = [
        { "key": "column-0", "dataKey": "column-0", "title": "", "title2": "", "width": 80, "rowspan":  {2: 3, 5:2, 8:2, 10:2} },
        {"key": "column-1", "dataKey": "column-1", "title": "구분", "title2": "구분", "width": 80},
        { "key": "column-2", "dataKey": "column-2", "title": "추진구역", "title2": "추진구역", "width": 70 },
        {"key": "column-3", "dataKey": "column-3", "title": "준공구역", "title2": "준공구역", "width": 70},
        { "key": "column-4", "dataKey": "column-4", "title": "합계x", "title2": "합계x", "width": 70 },
        {"key": "column-5", "dataKey": "column-5", "title": "해제구역", "title2": "해제구역", "width": 70},
        {"key": "column-6", "dataKey": "column-6", "title": "해제구역", "title2": "해제구역", "width": 70}
    ]

const LEFTMOST_HEADER_COUNT = 2
const ROW_TO_BE_MERGE = [0, 1, 7, 12, 13, 14, 15]
const ROW_SUB_SUM = [1, 7, 12]

    const width_table = 510
    const height_table = 452

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-2 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '160px', height: '66px'}} className="row-span-2 flex word-spacing items-center justify-center">사업유형 </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">구역수 </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">기존세대수 </div>
                <div style={{width: '210px'}} className="row-span-1 col-span-3 flex word-spacing items-center justify-center">공급세대수 </div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">공급세대수 </div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">분양 </div>
                <div style={{width: '70px'}} className="row-span-1 flex word-spacing items-center justify-center">임대 </div>

            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;
