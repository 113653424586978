import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {
    CustomizedLegend,
    CustomizedXAxisTick,
    CustomizedYAxisTick,
    formatTooltipValue,
    toNum
} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data, data2}) {

    const headers = data.headers.slice(2,7) // headers with title
    const headers2 = data2.headers.slice(2,7) // headers with title

    const datas = [data.rows[1], data.rows[2], data.rows[4],  data.rows[5]] // 모아주택제외
    const datas2 = [data2.rows[1], data2.rows[2], data2.rows[4],  data2.rows[5]] // 모아주택제외

    // make proper json for graph
    const graph_data = datas.map(([v01,v_name,v1,v2,v3,v4,v5]) => ({
        name: v_name, [headers[0].title] :  toNum(v1), [headers[1].title]:  toNum(v2), [headers[2].title]:  toNum(v3), [headers[3].title]:  toNum(v4), [headers[4].title]:  toNum(v5),
    }));

    const graph_data2 = datas2.map(([v01,v_name,v1,v2,v3,v4,v5]) => ({
        name: v_name, [headers2[0].title] :  toNum(v1), [headers2[1].title]:  toNum(v2), [headers2[2].title]:  toNum(v3), [headers2[3].title]:  toNum(v4), [headers2[4].title]:  toNum(v5),
    }));

    const colors = [{color:'#4f81bd'}, {color:'#c0504d'}, {color:'#f4a67a'}, {color:'#9bbb59'}]

    const mixed = graph_data.map((v, i) => {
        return ({...v, ...graph_data2[i]})
    })

    const [screenWidth,] = useRecoilState(screenWidthState);

    const pivotedData = mixed.reduce((result, item) => {
        Object.keys(item).forEach(key => {
            if (key !== 'name') {
                const newObj = result.find(obj => obj.status === key);
                if (newObj) {
                    newObj[item.name] = item[key];
                } else {
                    result.push({status: key, [item.name]: item[key]});
                }
            }
        });
        return result;
    }, []);

    return (<>
        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={5 / 3}>
                <ComposedChart
                    data={pivotedData}
                    margin={{
                        top: 20, right: 0, bottom: 40, left: -0,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey="status" angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={0}/>
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="gray"
                           label={{value: '', angle: 0, position: 'insideTopLeft', dy: -30, dx: 20, fontSize: 12}}
                    />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomizedLegend screenWidth={screenWidth}/>}/>

                    <Bar dataKey="재개발" yAxisId='left' stackId="a"  fill="#4f81bd"/>
                    <Bar dataKey="재건축" yAxisId='left'  stackId="a"  fill="#c0504d"/>
                    <Bar dataKey="가로주택" yAxisId='left'  stackId="a"  fill="#f4a67a"/>
                    <Bar dataKey="소규모재건축" yAxisId='left'  stackId="a"  fill="#9bbb59"/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
