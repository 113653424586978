import React, {useEffect, useState} from "react";
import 'react-base-table/styles.css'
import axios from "axios";
import {Switch} from '@headlessui/react'
import {useRecoilValue} from "recoil";
import {userState} from "../../utils/recoilstate";
import {CenterLoadSpinner} from "../../utils/UIHelper";

// eslint-disable-next-line react-hooks/rules-of-hooks

function unixTimestampToDateTime(unixTimestamp) {
    // Create a Date object from the Unix timestamp
    const date = new Date(unixTimestamp * 1000);

    // Extract the date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
}

const filesStatic = []

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function App() {

    const [filter, setFilter] = useState('');
    const [filesOrg, setFilesOrg] = useState(filesStatic);
    const [files, setFiles] = useState(filesStatic);
    const [showSpinner, setShowSpinner] = useState(false);
    const [resetFile, setResetFile] = useState(Date.now());

    const userInStorage = useRecoilValue(userState);

    useEffect(() => {

        if (filesOrg) {

            const filtered = filesOrg.filter(file => {
                const lowerCaseFilter = filter.toLowerCase();

                return file.work.toLowerCase().includes(lowerCaseFilter) ||
                    file.status.toLowerCase().includes(lowerCaseFilter) ||
                    file.org_filename.toLowerCase().includes(lowerCaseFilter) ||
                    file.uploader.toLowerCase().includes(lowerCaseFilter) ||
                    file.memo.toLowerCase().includes(lowerCaseFilter)
                    ;
            });

            setFiles(filtered)
        }

    }, [filesOrg])

    const handleClearFilter = () => {
        setFilter('');
    }

    const workname = '추진업무현황'

    const [uploadContent, setUploadContent] = useState({
        objectKey: `upload/${workname}`,
        uploader: "",
        work: workname,
        memo: "",
        selectedFile: ""
    });

    function refreshFileList() {

        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/category/download/work/${workname}`;

        axios.get(apiUrl)
            .then(response => {

                setFilesOrg(response.data['Items'])
            })
            .catch(error => {
                console.log(error); // handle any errors
            });

    }

    function deleteFileRequest(upload_ts) {

        setShowSpinner(true)
        const apiUrl = `https://api.seoulhousinginfo.com/admin/workfile/download/${workname}/${upload_ts}`;

        axios.delete(apiUrl)
            .then(response => {

                setFilesOrg(response.data['Items'])
            })
            .catch(error => {
                console.log(error); // handle any errors
            }).finally(() => {
            setShowSpinner(false)
            refreshFileList()
        })
    }

    useEffect(() => {

        refreshFileList()

    }, [])

    useEffect(() => {

        if (userInStorage && userInStorage.payload) {
            const uploader = userInStorage.payload.username
            setUploadContent(prevState => ({
                ...prevState,
                uploader: uploader
            }))
        }

    }, [userInStorage])

    function handleFileInput(e, objective) {
        const file = e.target.files[0];

        setUploadContent(prevState => ({
            ...prevState,
            selectedFile: file
        }))
    }

    function haandleInput(e) {
        const key = e.target.name
        const val = e.target.value

        // set name = value
        setUploadContent(prevState => ({
            ...prevState,
            [key]: val,
        }))
    }

    function uploadNow(url, fields) {

        const formData = new FormData();
        Object.entries(fields).forEach(([key, val]) => {
            formData.append(key, val);
        });

        formData.append("file", uploadContent.selectedFile);

        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
            , validateStatus: function (status) {
                return status === 199 || status === 204;
            }
        }
        axios
            .post(url, formData, axiosConfig)
            .then((res) => {
                console.log("File Upload success");

                // setTimeout(refreshFileList, 3000)
            })
            .catch(
                (err) => {
                    console.log('got error?')
                }
            ).finally(() => {
            setShowSpinner(false)
            refreshFileList()
        })
    }

    function readyS3PresignedUrlAndUpload(params) {
        return axios
            .get('https://api.seoulhousinginfo.com/upload/presigned?' + params)
            .then((res) => {
                return res.data;
            });
    }

    function createS3PresignedAndProcessUpload(category = '') {

        if (!uploadContent.selectedFile && uploadContent.work && uploadContent.memo)
            return

        setShowSpinner(true)

        const ext = uploadContent.selectedFile.name.split('.').pop();

        const meta_info = {
            'object-key': uploadContent.objectKey + category + '.' + ext,
            'original-filename': uploadContent.selectedFile.name,
            'uploader-name': uploadContent.uploader,
            'work-name': uploadContent.work,
            'category': category,
            'memo': uploadContent.memo,
            'timeout': 600
        }

        const params = new URLSearchParams(meta_info).toString();

        readyS3PresignedUrlAndUpload(params)
            .then((data) => {
                uploadNow(data.url, data.fields);
                console.log(data.fields);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
            setShowSpinner(false)
        });
    }

    function file_delete(upload_ts) {

        deleteFileRequest(upload_ts)

    }

    function download_full(upload_ts) {
        // Make API request to get the download link
        // https://api.seoulhousinginfo.com/admin/workfile/download/link/total/추진업무현황/1687256829
        fetch(`https://api.seoulhousinginfo.com/admin/workfile/download/link/zip/${workname}/${upload_ts}`)
            .then(response => response.json())
            .then(data => {
                const downloadLink = data.link;
                // Initiate file download
                window.open(downloadLink);
            })
            .catch(error => {
                console.error('Error retrieving download link:', error);
            });
    }

    function file_publish(upload_ts) {

        console.log('파일을 전시 상태로 변환 시도', upload_ts)

        setShowSpinner(true)

        axios.post(`https://api.seoulhousinginfo.com/admin/workfile/category/download/work/${workname}/${upload_ts}/publish`)
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {

            setShowSpinner(false)
            refreshFileList()

        })
    }

    return (<>

        <h1 className="mt-3 text-3xl font-extrabold tracking-tight text-slate-900">정비사업추진현황 다운로드 지원 파일</h1>

        <div className="px-2 m:px-6">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">작은 화면에서는 우측으로 스크롤 하세요. 파일을 미리 보기 하고 전시하세요</p>
        </div>
        <div className="px-2 sm:px-6 lg:px-8">
            <div className="mt-2 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                        <div className='mb-3'>
                            <span>
                                <input className="p-2 px-2 py-1 border-b border-b-1 border-gray-900 bg-blue-50"
                                       type="text"
                                       value={filter} onChange={e => setFilter(e.target.value)} placeholder="검색"/>
                                {filter && (
                                    <button className="ml-2 text-red-600 font-bold" onClick={handleClearFilter}>
                                        X
                                    </button>
                                )}
                            </span>
                            <span className='bg-gray-100 p-2 ml-3'>
                                <button onClick={refreshFileList}>목록 새로고침</button>
                            </span>
                        </div>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        상태
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div>파일 이름</div>
                                        <div className='text-xs'>(클릭 다운로드)</div>
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        업로드 시점
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        올린사람
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        조작
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        fileid
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {files.map((file) => (<tr key={file.upload_ts}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="text-gray-900">
                                            {file.status === '미리보기' &&
                                                <span
                                                    className="inline-flex rounded-full bg-green-100 px-3 py-2 text-xs font-semibold leading-5 text-green-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '업로드' &&
                                                <span
                                                    className="inline-flex rounded-full bg-gray-100 px-3 py-2 text-xs font-semibold leading-5 text-gray-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '대기' &&
                                                <span
                                                    className="inline-flex rounded-full bg-yellow-100 px-3 py-2 text-xs font-semibold leading-5 text-yellow-800">
                                                {file.status}
                                            </span>
                                            }
                                            {file.status === '전시' &&
                                                <span
                                                    className="inline-flex rounded-full bg-blue-100 px-3 py-2 text-xs font-semibold leading-5 text-blue-800">
                                                {file.status}
                                            </span>
                                            }
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div className="flex items-center">
                                            <button type="button" onClick={() => download_full(file.upload_ts)}>
                                                <div className="text-gray-900">{file.org_filename}</div>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{unixTimestampToDateTime(file.upload_ts)}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div>{file.uploader}</div>
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <>
                                            <button type="button" onClick={() => file_publish(file.upload_ts)}>
                                            <span
                                                className="inline-flex rounded-full bg-yellow-100 px-3 py-2 text-xs font-semibold leading-5 text-yellow-800">
                                                전시
                                            </span>
                                            </button>
                                            <button type="button" onClick={() => file_delete(file.upload_ts)}>
                                            <span
                                                className="inline-flex rounded-full bg-red-100 px-3 py-2 text-xs font-semibold leading-5 text-red-800">
                                                삭제
                                            </span>
                                            </button>
                                        </>
                                        {/* 전시 중인 파일은 삭제 할 수 없다. */}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <div>
                                            {file.upload_ts}
                                        </div>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:flex my-3 sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">상태</h1>
                    <table className='text-xs'>
                        <tr>
                            <td className='w-24'>
                                <span
                                    className="inline-flex rounded-full bg-gray-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-gray-800"> 업로드 </span>
                            </td>
                            <td>
                                업로드된 파일, 전시 버튼을 눌러 공개 할 수 있음
                            </td>
                        </tr>
                        <tr>
                            <td>

                                <span
                                    className="inline-flex rounded-full bg-blue-100 px-3 py-2 mr-2 text-xs font-semibold leading-5 text-blue-800"> 전시 </span>
                            </td>
                            <td>
                                사용자가 엑셀 다운로드 누를 때 다운로드 되는 파일
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>

        <h1 className="mt-5 text-3xl font-extrabold tracking-tight text-slate-900">정비사업추진현황 다운로드 지원 파일 업로드</h1>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">업로더</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-3">
                            {userInStorage && userInStorage.payload && userInStorage.payload.username}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">파일 선택</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <input type='file' accept='.zip' onChange={(e) => handleFileInput(e, 'download')}/>
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <button type="button" onClick={() => createS3PresignedAndProcessUpload('download')}>
                                <span
                                    className="inline-flex bg-gray-800 px-4 py-3 ml-3 text-xs font-semibold leading-5 text-white"> 업로드 </span>
                            </button>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        {
            showSpinner && (
                <CenterLoadSpinner/>
            )
        }
    </>);
}