import React from "react";
import {useRecoilValue} from "recoil";
import {serverSettingState} from "../utils/recoilstate";


export default function Footer() {
  const serverSetting = useRecoilValue(serverSettingState);

  return (
    <>

      <footer className="relative bg-[#be123c] pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-[#be123c] fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h5 className="text-lg mt-0 mb-2 text-white">
                {serverSetting.ui_footer_notice && serverSetting.ui_footer_notice.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                ))}
              </h5>
            </div>
            <hr className="my-6 border-blueGray-300" />
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} 서울특별시 주택정책지원센터 {" "}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
