import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {calculateXAxisInterval, CustomizedXAxisTick, CustomizedYAxisTick, CustomLegend, formatTooltipValue} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data, searchCondition}) {

    const convertedData = data.rows.map(([year, 계, y10, y20, y30, y40, y50, y60]) => {
        return {
            year: year,
            '계': 계 / 1,
            '10대': y10 / 1,
            '20대': y20 / 1,
            '30대': y30 / 1,
            '40대': y40 / 1,
            '50대': y50 / 1,
            '60대 이상': y60 / 1,
        };
    });

    const filteredData = convertedData


    const [screenWidth,] = useRecoilState(screenWidthState);
    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -20, right: 10
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end" interval={calculateXAxisInterval(filteredData)} />
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick devide={1000} screenWidth={screenWidth}/>} stroke="gray"
                           label={{  value: '(천 명)', angle: 0, position: 'insideTopLeft', dy: -30 , dx:20, fontSize: 12}}
                    />
                    <Tooltip formatter={formatTooltipValue} />
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey="계" yAxisId='left' barSize={10} fill="#ff0000"/>
                    <Bar dataKey="10대" yAxisId='left' barSize={10} fill="#5b9bd5"/>
                    <Bar dataKey="20대" yAxisId='left' barSize={10} fill="#ed7d31"/>
                    <Bar dataKey="30대" yAxisId='left' barSize={10} fill="#ffc000"/>
                    <Bar dataKey="40대" yAxisId='left' barSize={10} fill="#4472c4"/>
                    <Bar dataKey="50대" yAxisId='left' barSize={10} fill="#70ad47"/>
                    <Bar dataKey="60대 이상" yAxisId='left' barSize={10} fill="#a5a5a5"/>


                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
