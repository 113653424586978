import React from "react";
import 'react-base-table/styles.css'
import T1321 from "../work1/data1/T1321";
import {Depth3Title} from "../Depth3View";
import G1321 from "../work1/data1/G1321";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey)


    return serverData && (<>
        <Depth3Title title='과거5년 정비사업 공급물량'/>
        <T1321 code='1321' data={JSON.parse(serverData.data['1321'])} />
        <Depth3Title title='과거5년 향후5년(예상) 정비사업 공급물량'/>
        <G1321 data={JSON.parse(serverData.data['1321'])} data2={JSON.parse(serverData.data['1331'])}  />
    </>);
}