import React from "react";
import 'react-base-table/styles.css'
import {Depth3Title} from "../Depth3View";
import T1121 from "../work1/data1/T1121";
import T1122 from "../work1/data1/T1122";
import T1123 from "../work1/data1/T1123";
import {useSyncData} from "../../utils/fileSync";
import Document from "../work1/data1/Document";

export default function P111() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';
    

    const serverData = useSyncData(work, metaKey, dataKey)


    return serverData && (<>
        {/* 1112 1113 은 예정과 준공인데 그래프는 예정,준공을 합쳐서 그래프를 보여주고, 표는 각기 보여준다.  */}
        <Depth3Title title='신속통합기획 현황'/>
        <T1121 code='1121' data={JSON.parse(serverData.data['1121'])}/>
        <Depth3Title title='공공재개발 재건축 현황'/>
        <T1122 code='1122' data={JSON.parse(serverData.data['1122'])}/>
        <Depth3Title title='모아타운 현황'/>
        <Document document_code={"1123"} document_code_sub={"pc"} />
    </>);
}