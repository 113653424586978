import React, {useEffect, useRef, useState} from "react";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';

function App({data, onChangeSearchOption}) {

    const startingMonthRef = useRef(new Date());
    const endingMonthRef = useRef(new Date());

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(data.length - 1);

    useEffect(() => {
        // start ~ end 를 data를 기준으로 변경
        const firstValue = data[0];
        const lastValue = data[data.length - 1]

        startingMonthRef.current = new Date(firstValue[0], firstValue[1] - 1, 1);
        endingMonthRef.current = new Date(lastValue[0], lastValue[1] - 1, 1);

    }, [data])

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}/${month}`;
    }

    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    useEffect(() => {
        const startingMonth = startingMonthRef.current;

        const minDate = formatDate(new Date(startingMonth.getFullYear(), startingMonth.getMonth() + minValue));
        const maxDate = formatDate(new Date(startingMonth.getFullYear(), startingMonth.getMonth() + maxValue));

        onChangeSearchOption({시작월: minDate, 끝월: maxDate})

    }, [minValue, maxValue, onChangeSearchOption])

    return (
        <div className="py-7 px-9">
            <Tooltip trigger='hover' placement="top"  overlay={<span>{minValue}</span>} >
                <InputRange
                    minValue={0}
                    maxValue={endingMonthRef.current.getFullYear() * 12 + endingMonthRef.current.getMonth() - (startingMonthRef.current.getFullYear() * 12 + startingMonthRef.current.getMonth())}
                    value={{min: minValue, max: maxValue}}
                    onChange={(value) => {
                        setMinValue(value.min);
                        setMaxValue(value.max);
                    }}
                    formatLabel={(value) => {
                        const year = startingMonthRef.current.getFullYear() + Math.floor(value / 12);
                        const month = months[(startingMonthRef.current.getMonth() + value) % 12];
                        return `${year}/${month}`;
                    }}
                >
                </InputRange>
            </Tooltip>
        </div>);
}

export default App;
