import React, {  useEffect, useState } from 'react';
import { Alert, Popover , notification, Col, Row , Layout, Typography, Select, Table,Radio, Space, DatePicker, ConfigProvider    } from 'antd';
import { cloneDeep} from "lodash";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ko_KR.js';
import 'dayjs/locale/ko' 
import { Bar, Column } from '@ant-design/plots';
import axios from "axios";
import {seoulGu, bubjungDong} from '../utils/guDong.js'
import aptData from './data/apt.json'
const { RangePicker } = DatePicker;

// components
// views
dayjs.locale('ko');
export default function ForeCast_Apt(props) {
    const [list, setList] = useState({
        '구': [],
      });
      const [tableList, setTableList] = useState({
        '전체': [],
        '월별': [],
      });
      const [getbubjeoung, setGetBubjeoung] = useState([]);
      const [filteredInfo, setFilteredInfo] = useState({
        gu: null,
      });
      const [showNoti, contextHolder] = notification.useNotification();
      const [columns, setColumns] = useState([]);
      const [monthlyColumns, setMonthlyColumns] = useState([]);

      useEffect(() => {
        if (!filteredInfo.startPredictionTime) {
          const startPredictionTime = dayjs().format('YYYYMM');
          const endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
          getTableColumn(startPredictionTime,endPredictionTime)
          getJunWallTable(startPredictionTime,endPredictionTime)
          changeOption([startPredictionTime, endPredictionTime], 'time')
        } else {
          requireData(filteredInfo);
        }
      },[filteredInfo])
    
      const content = (
        <div>
          <p>건축연한이 파악되지 않는 임차 예측물량</p>
        </div>
      );
      const requireData = (data) => {
        if (!data.gu || !data.startPredictionTime || !data.endPredictionTime) {
          return;
        }
        const headers = {
          'Access-Control-Allow-Origin': '*',
        }
        const url = `https://api.seoulhousinginfo.com/forecast/seoul-dangi-data?gu=${data.gu}&startPredictionTime=${data.startPredictionTime}&endPredictionTime=${data.endPredictionTime}`
        axios.get(url,{},{headers})
        .then(res => {
          getMonthlyTable(res.data);
        })
        .catch(error => {
            console.log(error);
        })
        return 
      }
      //데이터를 테이블에 맞게 변환
      const getMonthlyTable = (res) => {
        //그래프
        const obj = {};
        obj['구'] = [];
        //전체,월별 테이블
        const objTable = {};
        objTable['전체'] = [];
        objTable['월별'] = [];
        const totalData = cloneDeep(res?.total_type_data);
        const junData = cloneDeep(res?.dangi_detail_data['전세']);
        const monthlyData = cloneDeep(res?.dangi_detail_data['월세']);
        objTable['전체'] = Object.keys(totalData).map((key, index) => {
          return {
            key: index,
            name: key,
            '전세': Number(totalData[key]['전세']),
            '월세': Number(totalData[key]['월세']),
            '소계': Number(totalData[key]['전세'] + totalData[key]['월세']),
          };
        });
        objTable['전체'].sort((a, b) => a['key'] - b['key']);

        //값이 있는지 여부 확인
        const junKeys = Object.keys(junData);
        const monthlyKeys = Object.keys(monthlyData);
        const listKey = junKeys?.length > 0 ? junKeys : monthlyKeys;
        const dangiKey = junKeys?.length > 0 ? junData : monthlyData;

        if (listKey?.length > 0) {
            const findKey = listKey[0];
            const dangiList = Object.keys(dangiKey[findKey]);
            //초기화
            dangiList.sort((a, b) => a - b);
            for (let i = 0; i < dangiList?.length; i++) {
              const jw = {
                'code': dangiList[i],
                key: dangiList[i],
                '월세소계': 0,
                '전세소계': 0,
                '계': 0,
              }

              const monthLength = dayjs(filteredInfo.endPredictionTime).diff(filteredInfo.startPredictionTime, 'month')
              for(let j = 0; j < monthLength + 1; j++){
                //월
                const key = listKey[j];
                const wallseData = !monthlyData[key] || monthlyData[key][dangiList[i]] == 0 ? 0 : monthlyData[key][dangiList[i]]
                const junseData = !junData[key] || junData[key][dangiList[i]] == 0  ? 0 : junData[key][dangiList[i]]
                const junSeWall = '전세' + dayjs(listKey[j]).format('MM월');
                const wallSewall = '월세' + dayjs(listKey[j]).format('MM월');
                if (key) {
                  jw[junSeWall] = !junseData || Number(junseData) == 0 ? 0 : Number(junseData);
                  jw[wallSewall] = !wallseData || Number(wallseData) == 0 ? 0 : Number(wallseData);
                  jw['전세소계'] = jw['전세소계'] + Number(junseData);
                  jw['월세소계'] = jw['월세소계'] + Number(wallseData);
                  jw['계'] = jw['계'] + Number(junseData) + Number(wallseData);
               }
                const junSeChart = {
                  'code': dangiList[i],
                  '거래수': Number(junseData),
                  // '단지': res[i]['단지'],
                  '전/월세': '전세'
                }
                const wallSeChart = {
                  'code': dangiList[i],
                  '거래수': Number(wallseData),
                  // '단지': res[i]['단지'],
                  '전/월세': '월세'
                }
                obj['구'].push(junSeChart)
                obj['구'].push(wallSeChart)
              }
              objTable['월별'].push(jw)
            }
        }
        setTableList(cloneDeep(objTable))
        setList(cloneDeep(obj))
      }
      //6개월 초과선택시 알림
      const openNotificationWithIcon = (type, des) => {
        showNoti[type]({
          message: type === 'success' ? '업로드에 성공하였습니다.' : '선택에 실패하였습니다.',
          description:
          type === 'success' ? des + '업로드 완료' : des,
          placement: 'top',
        });
      };
      const changeOption = (data, dataIndex) => {
        const res = cloneDeep(filteredInfo);
        if (data !== '0' && data !== '') {
          if (dataIndex === 'gu') {
              res.gu = data;
              res.dong = null;
              if (res.startPredictionTime === undefined ||res.startPredictionTime === null){
                res.startPredictionTime = dayjs().format('YYYYMM');
                res.endPredictionTime = dayjs().add(5,'month').format('YYYYMM');
                getTableColumn(res.startPredictionTime,res.endPredictionTime)
                getJunWallTable(res.startPredictionTime,res.endPredictionTime)
              }
              setGetBubjeoung(bubjungDong[data])
          } else if (dataIndex === 'time') {
              res.startPredictionTime = data[0];
              res.endPredictionTime = data[1];
          }
        }
        setFilteredInfo(cloneDeep(res))
      };
      const config = (res) => {
        return {
          data: list[res],
          isStack: true,
          xField: '거래수',
          yField: 'code',
          yAxis: {
            label: {
              formatter: (text) => {
                return text.length > 10 ? text.slice(0, 8) + '...' : text;
              },
              style: {
                fontSize: 13
              }
            }
          },
          seriesField: '전/월세',
          label: {
            position: 'middle',
            content: (item) => {
              if (item['거래수'] === 0) {  // '거래수' 필드가 0인지 확인
                  return '-';
              }
              return !isNaN(item['거래수']) ? item['거래수'].toLocaleString() : item['거래수'];
            },
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
          legend: {
            title: {
              text: '단위: 호',
              spacing: 8,
            },
            itemName: {
              style: {
                fontSize: 13  // Adjust the font size as needed
              }
            }
          }
        }
      };
      const getJunWallTable = (start, end) => {
        const endMonth = dayjs(end).diff(dayjs(start), 'month')
        const jun = [];
        const wall = [];
        const column = [    
          {
            title: '구분',
            dataIndex: 'code',
            key: 'code',
            onCell: (text, record) => {
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              {
                  return text === '확인불가' ? 
                  <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                  :
                    <Space style={{fontSize: 14}}>{text}</Space>
                  
              }
            }
          },
        ]
        for (let i = 0; i < endMonth + 1; i++) {
          const month = dayjs(start).add(i,'month').format('MM월');
          jun.push(
            {
              title: month,
              align: "center",
              dataIndex: '전세' + month.toString(),
              key: '전세' + month.toString(),
              onCell: (text, record) => {
                const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
                return {
                  ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                  className: 'junse-class-in-td',
                };
              },
              render: (text) => {
                const t = text == 0 || text == undefined || text == null? '-' : text
                return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
              },              },
          )
          wall.push(
            {
              title: month,
              dataIndex: ('월세' + month).toString(),
              key:  ('월세' + month).toString(),
              align: "center",
              onCell: (text, record) => {
                const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
                return {
                  ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                  className: 'junse-class-in-td',
                };
              },
              render: (text) => {
                const t = text == 0 || text == undefined || text == null? '-' : text
                return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
              },              },
          )
        }
        column.push(
          {
            title: '전세',
            children: jun,
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {justifyContent:'center', alignItems:'center', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },            },
        )
        column.push(
          {
            title: '전세소계',
            dataIndex: '전세소계',
            key: '전세소계',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: '#FFF4F0', borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },            },
        )
        column.push(
          {
            title: '월세',
            children: wall,
            key: '월세',
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },            
          },
        )
        column.push(
          {
            title: '월세소계',
            dataIndex: '월세소계',
            key: '월세소계',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: '#FFF4F0', borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 || text == undefined || text == null? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },            },
        )
        column.push(
          {
            title: '계',
            dataIndex: '계',
            key: '계',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {justifyContent:'center', alignItems:'center', background: '#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 ? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },  
          },
        )
        setMonthlyColumns(column)
      }
      // 각 아파트이름으로 column 생성 
      const getTableColumn = (start, end) => {
        const column = [    
          {
            title: '구분',
            dataIndex: 'name',
            key: 'key',
            onCell: (text, record) => {
              // const checkCase = record === 0 ? '#FFEAE6' : '#FFFFFF';
              return {
                ['style']: {backgroundColor:'#FFEAE6', borderColor: '#D4D4D6'},
                className: 'example-class-in-td',
              };
            },
            render: (text) => {
              {
                  return text === '확인불가' ? 
                  <Popover content={content} ><Space style={{fontSize: 14}}>{text}</Space></Popover>
                  :
                    <Space style={{fontSize: 14}}>{text}</Space>
                  
              }
            }
          },
        ]
        column.push(
          {
            title: '전세',
            dataIndex: '전세',
            key: '전세',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 ? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },
          },
        )
        column.push(
          {
            title: '월세',
            dataIndex: '월세',
            key: '월세',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 0 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: checkCase, borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 ? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },
          },
        )
        column.push(
          {
            title: '계',
            dataIndex: '소계',
            key: '소계',
            align: "center",
            onCell: (text, record) => {
              const checkCase = record === 25 ? '#FFF4F0' : '#FFFFFF';
              return {
                ['style']: {backgroundColor: '#FFF4F0', borderColor: '#D4D4D6'},
                className: 'junse-class-in-td',
              };
            },
            render: (text) => {
              const t = text == 0 ? '-' : text
              return <Space style={{fontSize: 14}}>{t.toLocaleString()}</Space>
            },          
          },
          )
        setColumns(column)
      }
      const CustomHeaderCell = ({ style, ...props }) => (
        <th {...props} style={{ ...style,borderRadius:0,  backgroundColor: '#FFEAE6', borderColor: '#D4D4D6', textAlign:'center', alignItems: 'center' }} />
      );
      
      const TableComponents = {
        header: {
          cell: CustomHeaderCell,
        },
      };
    return (
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'red',
            },
          }}
        >
            {contextHolder}
            <Row style={{alignItems:'center', flex: 1, paddingTop: 10, paddingBottom: 10, marginBottom: 10, paddingLeft: 10, position: 'sticky',top: 0, backgroundColor:'#ffccc7', zIndex:2}}>
                <Col>
                    자치구:  
                    <Select
                    style={{minWidth: 100, marginLeft: 5, marginRight: 5}}
                    onChange={(value) => changeOption(value, 'gu')}
                    options={seoulGu}
                    defaultValue={'선택'}
                    />
                </Col>
                <Col>
                    기간: 
                    <RangePicker
                    defaultValue={[dayjs(),]}
                    disabledDate={(current) => dayjs(current).isBefore(dayjs('20230701'))}
                    style={{ marginLeft: 5, marginRight: 5, textAlign:'center',justifyContent:'center', alignItems:'center'}} locale={locale} picker="month" onChange={(value) =>{ 
                    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
                    dayjs.extend(isSameOrAfter)
                    const check = dayjs(value[1]).subtract(5,'month');
                    const check2 = dayjs(dayjs(value[0])).isSameOrAfter(check, 'month')
                    const dateList = [dayjs(value[0]).format('YYYYMM'),dayjs(value[1]).format('YYYYMM')]
                    if(check2 === true){
                        getTableColumn(dayjs(value[0]),dayjs(value[1]));
                        getJunWallTable(dayjs(value[0]),dayjs(value[1]));
                        changeOption(dateList, 'time')
                        } else{
                        openNotificationWithIcon('error', '최대 6개월까지 선택 가능합니다.')
                        } 
                    }
                    }
                    />
                </Col>
              </Row>
              <Layout style={{backgroundColor:'#FFFFFF', marginRight: 10,}}>
                {/* {tableList['전체']?.length > 0 ? <Row style={{}}>
                  </Row> : <Layout style={{flex: 1,minHeight: 500, backgroundColor: '#FFFFFF',  }} /> } */}
                  
                  {/* {tableList['전체']?.length > 0 ?<Col xs={24} lg={16}> */}
                  {tableList['전체']?.length > 0 ?<Col>
                  <Col 
                  style={{
                    
                    display: 'flex',          
                    flex: 1,
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    borderTopLeftRadius: 25,
                    backgroundColor:'#DF7988',
                    minWidth:350,
                    maxHeight: 200,
                    padding: 20 }}>
                    <Typography.Title style={{color: "#FFFFFF"}} level={1}>
                        대규모 아파트 단지별
                        <Typography.Title level={3} style={{color: "#FFFFFF"}}>
                        1,000세대 이상 아파트 단지 예측물량
                    </Typography.Title>
                    </Typography.Title>
                    <Typography.Title style={{textAlign:'right', color: "#FFFFFF"}} level={5}>
                      단위: 호
                    </Typography.Title>
                  </Col>
                      <Table components={TableComponents}  style={{borderWidth:1,borderColor: '#00000'}} bordered={true} columns={columns} dataSource={[...tableList['전체']]} pagination={false}   />
                  </Col> : <Layout style={{flex: 1,minHeight: 500, backgroundColor: '#FFFFFF',  }} /> }
                {tableList['월별']?.length > 0 && <Col style={{marginTop: 20}}>
                  <Table components={TableComponents} style={{borderWidth:1,borderColor: '#00000'}} tableLayout={'auto'} bordered={true} columns={monthlyColumns} dataSource={[...tableList['월별']]} pagination={false}   />
                </Col>}
                {list['구']?.length > 0 && <Col style={{flex: 1, marginTop: 25}}>
                    <Bar style={{flex: 1,height: list['구'].length > 0 ? list['구'].length * 5 : 200, marginLeft: 30, minWidth: 550}}{...config('구')} />
                </Col>}
            </Layout>

        </ConfigProvider>
    );
}