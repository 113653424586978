import React from "react";
import 'react-base-table/styles.css'
import BaseTable from "react-base-table";
import {applyFixedColumns, AutoSizer, makeProperData, rowRenderer} from "../../../utils/TableUtils";


function App({data, code}) {

//s const columns = gen_table.headers

    const HEADER_SIZED = [{
        "key": "column-0",
        "dataKey": "column-0",
        "title": "구분",
        "title2": "구분",
        "title1": "구분",
        "width": 120,
        "rowspan": {
            "0": 2,
            "2": 2,
            "4": 6,
            "10": 4,
            "14": 2,
            16: 4,
            20: 4,
            "24": 2,
            "26": 2,
            "28": 2,
            30:2
        },
        "rowspan2": {
            "4": 2,
            "6": 2,
            "8": 2,
            "10": 2,
            "12": 2,
            "14": 2,
            "16": 2,
            "18": 2,
            "20": 2,
            "22": 2,
        }
    }, {
        "key": "column-1", "dataKey": "column-1", "title": "구분", "title2": "구분", "title1": "구분", "width": 130
    }, {
        "key": "column-2", "dataKey": "column-2", "title": "합x계", "title2": "합계", "title1": "합계", "width": 80
    }, {
        "key": "column-3", "dataKey": "column-3", "title": "합계", "title2": "구역지정", "title1": "정비구역지정", "width": 70
    }, {
        "key": "column-4", "dataKey": "column-4", "title": "추진위원회", "title2": "추진위", "title1": "추진위원회", "width": 70
    }, {
        "key": "column-5", "dataKey": "column-5", "title": "조합설립인가", "title2": "조합설립", "title1": "조합설립인가", "width": 70
    }, {
        "key": "column-6", "dataKey": "column-6", "title": "건축심의", "title2": "건축심의", "title1": "건축심의", "width": 70
    }, {
        "key": "column-7",
        "dataKey": "column-7",
        "title": "사업시행계획인가",
        "title2": "사업시행",
        "title1": "사업시행",
        "width": 70
    }, {
        "key": "column-8",
        "dataKey": "column-8",
        "title": "관리처분계획인가",
        "title2": "관리처분",
        "title1": "관리처분계획인가",
        "width": 70
    },
        {"key": "column-9", "dataKey": "column-9", "title": "착공", "title2": "착공", "title1": "착공", "width": 70},
        {"key": "column-10", "dataKey": "column-10", "title": "착공", "title2": "착공", "title1": "착공", "width": 70}
    ]

    const LEFTMOST_HEADER_COUNT = 3
    const ROW_TO_BE_MERGE = [0, 2,14,24,26,28,30]
    const ROW_SUB_SUM = [2, 3, 14, 15, 24, 25]

    const width_table = 890
    const height_table = 897

    return (<>
        <AutoSizer width={width_table} height={height_table} code={code} >
            <div className="grid grid-rows-2 grid-flow-col text-xs font-bold text-center table-column-headers">
                <div style={{width: '330px', height: '66px'}}
                     className="row-span-2 flex word-spacing items-center justify-center">사업유형
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">합계
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">구역지정
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">추진위
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">조합설립
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">건축심의
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">사업시행
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">관리처분
                </div>
                <div style={{width: '70px'}} className="row-span-2 flex word-spacing items-center justify-center">착공
                </div>

            </div>
            <BaseTable
                fixed={true}
                columns={applyFixedColumns(HEADER_SIZED, LEFTMOST_HEADER_COUNT)} /* 열고정에 보일 데이터 */
                data={makeProperData(HEADER_SIZED, data.rows)}
                rowHeight={28} /* 그냥 최적의 길이로 조정한 것이다. */
                headerRenderer={null} /* header renderer 는 사용하지 않는다 직접 그린다. 복잡도 계산이 너무 어려움 */
                headerHeight={[0 /* 첫번째 헤더 */, 0 /* 2단계 헤더 */ /* 마지막 헤더 50 이면 보이고, 0이면 안보임 */]} /* 헤더 렌더러를 안 써서 버린다. */
                rowRenderer={rowRenderer(LEFTMOST_HEADER_COUNT, ROW_TO_BE_MERGE, ROW_SUB_SUM)}
                /* 크기를 줄이면, 전체적으로 표가 압축된다. 한번에 보이려면 테이블 결과를 보고 수기로 조정해야 한다. */
                width={width_table}
                height={height_table}
            />
        </AutoSizer>
    </>);
}

export default App;
