import React, {useEffect, useState} from "react";
import 'react-base-table/styles.css'
import {CenterLoadSpinner} from "../../utils/UIHelper";

export default function App() {

    const [showSpinner, setShowSpinner] = useState(false);

    function sleep(ms) {
        const wakeUpTime = Date.now() + ms;
        while (Date.now() < wakeUpTime) {}
    }

    const spin = () => {
        console.log('spin called');
        setShowSpinner(true);

        setTimeout(() => {
            setShowSpinner(false);
        }, 2000);
    };

    useEffect(() => {

        console.log('spinner status ', showSpinner)

    }, [showSpinner])

    return (<>


        <button onClick={spin}>
            open
        </button>
        {
            showSpinner && (
                <CenterLoadSpinner/>
            )
        }
    </>)
        ;
}