import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {
    CustomizedXAxisTick,
    CustomizedYAxisTick,
    CustomizedYAxisTickRight,
    CustomLegend,
    formatTooltipValue
} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";

function App({data}) {

    const [screenWidth,] = useRecoilState(screenWidthState);

    const ignore_left = 4; // 구분/구분/구분/행합계
    const titles = data.headers.slice(ignore_left);
    const rows = data.rows.slice(0, 2); // 합계

    const compact_data = titles.map((title, index) => ({
        name: title.title,
        '구역수': parseInt(rows[0][index + ignore_left]),
        '공급세대': parseInt(rows[1][index + ignore_left]) / 1000
    }));

    return (<>
        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={5 / 3}>
                <ComposedChart
                    data={compact_data}
                    margin={{
                        bottom: 40,
                        left: -20,
                        right: -20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey="name" angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>}
                           textAnchor="end" interval={0}/>
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>}
                           stroke="#FD4857"/>
                    <YAxis yAxisId="right" orientation="right"
                           tick={<CustomizedYAxisTickRight screenWidth={screenWidth}/>} stroke="#004D92"/>
                    <Tooltip formatter={formatTooltipValue}/>
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Bar dataKey="구역수" yAxisId='left' barSize={20} fill="#FD4857" unit=" 구역" />
                    <Line yAxisId='right' type="linear" strokeWidth={2} dataKey="공급세대" stroke="#004D92" unit=" 천 세대"/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
