import React, {useState} from "react";
import {Depth3Title} from "../Depth3View";
import G2157 from "../work1/data1/G2157";
import T2157 from "../work1/data1/T2157";
import {useSyncData} from "../../utils/fileSync";

export default function App() {

    const [searchCondition,] = useState({})

    const metaKey = 'workdata2.meta';
    const dataKey = 'workdata2.data';
    const work = '주택시장동향';
    

    const allData = useSyncData(work, metaKey, dataKey);

    return (<>

        {allData && (<>

            <Depth3Title title='가구동향'/>
            <G2157 data={JSON.parse(allData.data['2157'])} searchCondition={searchCondition}/>
            <T2157 code='2157' data={JSON.parse(allData.data['2157'])} searchCondition={searchCondition}/>
        </>)}

    </>);
}