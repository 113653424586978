import React from "react";
import 'react-base-table/styles.css'
import {Depth3Title} from "../Depth3View";
import T1341 from "../work1/data1/T1341";
import T1342 from "../work1/data1/T1342";
import {useSyncData} from "../../utils/fileSync";


export default function App() {

    const metaKey = 'workdata1.meta';
    const dataKey = 'workdata1.data';
    const work = '추진업무현황';

    const serverData = useSyncData(work, metaKey, dataKey)


    /*
    let disp_year = 23
    try{
        let start_year = JSON.parse(serverData.data['1341']).rows[1][1]
        disp_year = start_year % 100
        console.log('processed year by data')
    } catch (error) {
        let currentDate = new Date();
        let yearLastTwoDigits = currentDate.getFullYear() % 100;
        let result = yearLastTwoDigits;
        disp_year = parseInt(result)
        console.log('current year')
        console.log(error)
    }
     */

    return serverData && (<>
        <Depth3Title title='자치구별 정비사업공급물량(향후2년)'/>
        <T1341 code='1341' data={JSON.parse(serverData.data['1341'])}/>
        <Depth3Title title='자치구별 준공목록(1년 후)'/>
        <T1342 code='1342' data={JSON.parse(serverData.data['1342'])}/>
        <Depth3Title title='자치구별 준공목록(2년 후)'/>
        <T1342 code='1343' data={JSON.parse(serverData.data['1343'])}/>
    </>);
}