import React, {useCallback, useEffect, useRef, useState} from "react";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {createGlobalStyle} from "styled-components";
import Select from 'react-select'
import 'react-base-table/styles.css';
import * as PropTypes from "prop-types";
import S21X from "./S21X";


const Checkbox = ({children, ...props}) => (<label style={{marginRight: '1em'}}>
    <input type="checkbox" {...props} />
    {children}
</label>);

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|iemobile/i.test(userAgent);
}

function TurnOffKeyboard(props) {
    return <div
        style={{
            color: "hsl(0, 0%, 40%)", display: "inline-block", fontSize: 12, fontStyle: "italic", marginTop: "1em",
        }}
    >
        <Checkbox
            checked={props.checked}
            onChange={props.onChange}
        >
            키보드 꺼짐 (키보드를 사용해 검색하려면 체크를 해제하세요)
        </Checkbox>
    </div>;
}

function RecalcIndex(props) {
    return <div
        style={{
            color: "hsl(0, 0%, 40%)", display: "inline-block", fontSize: 12, fontStyle: "italic", marginTop: "1em",
        }}
    >
        <Checkbox
            checked={props.checked}
            onChange={props.onChange}
        >
            기간 시작 시점을 지수 100으로 변환
        </Checkbox>
    </div>;
}

function SelectBox({options, value, onChange, isDisabled}) {

    const [chooseOnly, setChooseOnly] = useState(isMobileDevice() ? true : false);

    return (<><Select
        options={options}
        value={value}
        isSearchable={!chooseOnly}
        menuPlacement="auto"
        menuPosition="fixed"
        isDisabled={isDisabled}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onChange={onChange}
        isMulti placeholder="선택하거나 검색하세요"/>

        {isMobileDevice() && <TurnOffKeyboard checked={chooseOnly} onChange={() => setChooseOnly((state) => !state)}/>}
    </>);
}

function App({data, onChangeSearchOption}) {
    const [searchSlideCondition, setSearchSlideCondition] = useState({})
    const [searchCondition, setSearchCondition] = useState({
        유형: [], 주제: [], 항목: [], 시작월: '2021/01', 끝월: '2021/02', 검색어: '', 상세검색: [], 상세검색상세목록: [], 인덱스100: true
    });

    const [uniqueOptions, setUniqueOptions] = useState([])
    const [titles, setTitles] = useState([])

    useEffect(() => {

        handleMonthRangeChange(searchSlideCondition.시작월, searchSlideCondition.끝월)

    }, [searchSlideCondition])

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [isDisabledSelectBox, setisDisabledSelectBox] = useState(false);

    function getUniqueValues(arr) {
        const result = [];

        for (const item of arr) {
            const tokens = item.split('_');

            for (let i = 0; i < tokens.length; i++) {
                if (!result[i]) {
                    result[i] = [];
                }

                if (!result[i].includes(tokens[i])) {
                    result[i].push(tokens[i]);
                }
            }
        }

        return result;
    }

    useEffect(() => {

        let headers = data.headers.map(row => row.title).slice(2) // 구분은 제외하고 제목 리스트업
        let uniqueOptions = getUniqueValues(headers)

        setUniqueOptions(uniqueOptions)
        setTitles(headers)

    }, [data])

    useEffect(() => {

        if (searchCondition.유형.length > 0 && searchCondition.주제.length > 0 && searchCondition.항목.length > 0) {
            const lastcandidate = []

            searchCondition.유형.forEach(o1 => {
                searchCondition.주제.forEach(o2 => {
                    searchCondition.항목.forEach(o3 => {
                        uniqueOptions[3].forEach(o4 => {
                            const item = o1 + '_' + o2 + '_' + o3 + '_' + o4

                            if (titles.includes(item) && !lastcandidate.some(obj => obj.value === o4)) {
                                lastcandidate.push({value: o4, label: o4})
                            }
                        })
                    })
                })
            })

            console.log('옵션4 가능항목', lastcandidate)
            setSelectOptions(lastcandidate)

            if (lastcandidate.length) {
                setisDisabledSelectBox(false)
            }
        } else {
            setSelectedOptions([])
            setisDisabledSelectBox(true)
        }

        let choosenHeaders = []
        searchCondition.유형.forEach((o1) => {
            searchCondition.주제.forEach((o2) => {
                const item = `${o1}_${o2}`
                if (titles.includes(item)) {
                    choosenHeaders.push(item)
                }

                searchCondition.항목.forEach((o3) => {
                    const item = `${o1}_${o2}_${o3}`
                    if (titles.includes(item)) {
                        choosenHeaders.push(item)
                    }

                    searchCondition.상세검색.forEach((o4) => {
                        const item = `${o1}_${o2}_${o3}_${o4}`
                        if (titles.includes(item)) {
                            choosenHeaders.push(item)
                        }
                    })
                })
            })
        });
        console.log('체크박스 변경시의 헤더', choosenHeaders)

        onChangeSearchOption({...searchCondition, 상세검색상세목록: choosenHeaders})

    }, [searchCondition])

    const handleOnSelectedChange = (newSelectedOptions) => {
        // Sort the selected options alphabetically by their label
        // 상세 검색을 했을 경우, 선택지들이 정렬되며, 선택지로 정해진다.
        const sortedOptions = newSelectedOptions.slice().sort((a, b) => a.label.localeCompare(b.label));
        setSelectedOptions(sortedOptions);

        const options = sortedOptions.map(v => v.value)

        const choosenHeaders = []
        searchCondition.유형.forEach((o1) => {
            searchCondition.주제.forEach((o2) => {
                searchCondition.항목.forEach((o3) => {
                    options.forEach((o4) => {
                        const item = o1 + '_' + o2 + '_' + o3 + '_' + o4

                        if (titles.includes(item)) {
                            choosenHeaders.push(item)
                        }
                    })
                })
            })
        });

        console.log('상세목록 변경시의 헤더', choosenHeaders)
        setSearchCondition(prevCondition => ({
            ...prevCondition, 상세검색상세목록: choosenHeaders, 상세검색: options
        }));

    };

    function handleMonthRangeChange(minDate, maxDate) {
        setSearchCondition(prevCondition => ({
            ...prevCondition, 시작월: minDate, 끝월: maxDate,
        }));
    }

    function handleRadioChange(event) {
        const {name, value} = event.target;
        setSearchCondition(prevCondition => ({
            ...prevCondition, [name]: value,
        }));
    }

    function handleCheckboxChange(event) {
        const {name, value, checked, id} = event.target;

        setSearchCondition((prevCondition) => ({
            ...prevCondition, [name]: checked ? [...prevCondition[name], value] : prevCondition[name].filter((val) => val !== value),
        }));
    }

    return (


        <>
            <div className='p-3 border rounded-lg border-gray-200 mb-4 space-y-3 text-xs'>
                <div className="flex gap-x-4 items-center mt-3">
                    <div className="flex w-16">
                        거래유형
                    </div>
                    <div className="flex w-64 grow items-center space-x-3">
                        <div className='inline-flex items-center w-full'>
                            <input type="checkbox" id="option11" name="유형" value="매매" className="peer"
                                   checked={searchCondition.유형.includes('매매')}
                                   onChange={handleCheckboxChange}
                                   required/>
                            <label htmlFor="option11"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    매매
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option12" name="유형" value="전세"
                                   checked={searchCondition.유형.includes('전세')}
                                   onChange={handleCheckboxChange}
                                   className=" peer"/>
                            <label htmlFor="option12"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    전세
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex gap-x-4 items-center">
                    <div className="flex w-16">
                        주택유형
                    </div>
                    <div className="flex w-64 grow items-center space-x-3">
                        <div className='inline-flex items-center w-full'>
                            <input type="checkbox" id="option21" name="주제" value="아파트" class="peer"
                                   checked={searchCondition.주제.includes('아파트')}
                                   onChange={handleCheckboxChange}
                                   required/>
                            <label for="option21"
                                   class="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div class="block">
                                    아파트
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option22" name="주제" value="연립다세대"
                                   checked={searchCondition.주제.includes('연립다세대')}
                                   onChange={handleCheckboxChange}
                                   className="peer"/>
                            <label htmlFor="option22"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    연립다세대
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex gap-x-4 items-center mt-3">
                    <div className="flex w-16">
                        지역구분
                    </div>
                    <div className="flex w-64 grow items-center space-x-3">
                        <div className='inline-flex items-center w-full'>
                            <input type="checkbox" id="option31" name="항목" value="서울시" class="peer"
                                   checked={searchCondition.항목.includes('서울시')}
                                   onChange={handleCheckboxChange}
                                   required
                                   disabled/>
                            <label htmlFor="option31"
                                   class="inline-flex items-center justify-between w-full mr-3 ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div class="block">
                                    서울시
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option32" name="항목" value="면적별"
                                   checked={searchCondition.항목.includes('면적별')}
                                   onChange={handleCheckboxChange}
                                   className=" peer"/>
                            <label htmlFor="option32"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    면적별
                                </div>
                            </label>
                        </div>
                        <div className='inline-flex items-center w-full'>

                            <input type="checkbox" id="option33" name="항목" value="연한별"
                                   checked={searchCondition.항목.includes('연한별')}
                                   onChange={handleCheckboxChange}
                                   className=" peer"/>
                            <label htmlFor="option33"
                                   className="inline-flex items-center justify-between w-full ml-3 px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                                <div className="block">
                                    연한별
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="">
                    검색기간
                </div>
                <S21X data={data.rows} onChangeSearchOption={setSearchSlideCondition}/>
                <div className="">
                    상세조건
                </div>
                <div className="">
                    <SelectBox options={selectOptions} value={selectedOptions} onChange={handleOnSelectedChange} isDisabled={isDisabledSelectBox}/>
                </div>

            </div>

        </>)
}

export default App;
