import React, {useEffect, useState} from "react";
import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {
    calculateXAxisInterval,
    CustomizedXAxisTick,
    CustomizedYAxisTick,
    CustomLegend,
    formatTooltipValue
} from "../../../utils/chartutil";
import {useRecoilState} from "recoil";
import {screenWidthState} from "../../../App";
import {filterDataByCondition2} from "../../../utils/TableUtils";


function App({data, searchCondition}) {

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fixedrows = data.rows.map(([c1, c2, c3, c4]) => {
            try {
                return {
                    year: c1, month: c2,
                    '한국': (parseFloat(c3.replace('%', '')) / 100).toFixed(2),
                    '미국': (parseFloat(c4.replace('%', '')) / 100).toFixed(2),
                };
            } catch (e) {
                console.log(e)
            }
        });

        if (searchCondition.시작월) {
            setFilteredData(filterDataByCondition2(fixedrows, searchCondition))
        }

    }, [data, searchCondition]);

    const [screenWidth,] = useRecoilState(screenWidthState);

    return (<>

        <div className='chart-container mx-auto '>
            <ResponsiveContainer width='100%' aspect={4 / 3}>
                <ComposedChart
                    data={filteredData}
                    margin={{
                        bottom: 40, left: -20, right: 10
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5"/>
                    <XAxis dataKey={(entry) => `${entry.year}/${entry.month}`} angle={-45} tick={<CustomizedXAxisTick screenWidth={screenWidth}/>} textAnchor="end"
                           interval={calculateXAxisInterval(filteredData)}/>
                    <YAxis yAxisId="left" orientation="left" tick={<CustomizedYAxisTick screenWidth={screenWidth}/>} stroke="gray"
                           label={{value: '(%)', angle: 0, position: 'insideTopLeft', dy: -30, dx: 20, fontSize: 12}}
                    />
                    <Tooltip formatter={formatTooltipValue}/>
                    <Legend verticalAlign="top" align="center" content={<CustomLegend/>}/>
                    <Line yAxisId='left' type="step" strokeWidth={2} dataKey="한국" stroke="blue" />
                    <Line yAxisId='left' type="step" strokeWidth={2} dataKey="미국" stroke="green"/>

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    </>);
}

export default App;
